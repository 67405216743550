import React, { useContext, useState } from "react";
import "./cashoutbymonthchart.scss";
import { Grid, Stack, Typography, Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import { GET_CASH_IN_AND_OUT_CHART } from "../../../Schema/Dashboard";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import {
  currencyFormat,
  getKhmerMonth,
} from "../../../Function/GetKhmerNumber";

export default function CashOutByType({ editData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const installment = editData?.total_installments_amount;
  const loan = editData?.total_bussiness_loan_amount;
  const pawn = editData?.total_pawn_amount;

  const state = {
    series: [
      {
        name: t("installment"),
        data: [installment],
      },
      {
        name: t("loan"),
        data: [loan],
      },
      {
        name: t("pawn"),
        data: [pawn],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        fontFamily: "Siemreap",
        fontSize: "12px",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          // columnWidth: "100%",
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        formatter: function (val) {
          // return currencyFormat(val);
          return val === 0 ? "" : currencyFormat(val);
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        // categories: dayOfMonth,
        categories: [t("installment"), t("loan"), t("pawn")],
      },
      // yaxis: {
      //   // max: overallHighest + 100,
      //   title: {
      //     text: "($)",
      //   },
      //   labels: {
      //     formatter: function (val) {
      //       return val?.toFixed(0);
      //     },
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " $";
          },
          style: {
            fontFamily: "Siemreap",
            fontSize: "12px",
          },
        },
      },
    },
  };

  return (
    <Box>
      <Stack className="cash-out-by-month-chart">
        <Stack direction="row" justifyContent="center">
          <Typography className="title">បញ្ចេញកម្ចីសរុបតាមប្រភេទ</Typography>
        </Stack>
        <Box sx={{ width: "360px !important" }}>
          <div id="chart">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="bar"
              height={300}
            />
          </div>
        </Box>
      </Stack>
    </Box>
  );
}
