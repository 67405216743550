import React, { useState, useEffect } from "react";
import Groups2Icon from "@mui/icons-material/Groups2";

import { useQuery } from "@apollo/client";

// import { AuthContext } from "../../context/AuthContext";
// import { translateLauguage } from "../../Function/Translate";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { useNavigate } from "react-router-dom";

import { currencyFormatWithZero } from "../../../Function/GetKhmerNumber";

import { Grid, Stack, Box, Avatar, Typography } from "@mui/material";

import "./loancard.scss";

import flower from "../../../Assets/icons-01.png";
import pod from "../../../Assets/khorm-white.png";
import charpey from "../../../Assets/charpey-icon.png";

import { GET_TOTAL_ACTIVE_CUSTOMER } from "../../../Schema/Dashboard";
import { GET_TOTAL_EQUITY } from "../../../Schema/Dashboard";
import { GET_TOTAL_CASH_OUT } from "../../../Schema/Dashboard";
import { GET_TOTAL_CASH_IN } from "../../../Schema/Dashboard";
import { GET_CURRENT_TOTAL_EQUITY } from "../../../Schema/Dashboard";
import { GET_CASH_OUT_TODAY } from "../../../Schema/Dashboard";
import { GET_CASH_IN_TODAY } from "../../../Schema/Dashboard";

// Icon
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaMoneyBill1Wave } from "react-icons/fa6";

import SavingsIcon from "@mui/icons-material/Savings";
import { GiReceiveMoney } from "react-icons/gi";
import { GiPayMoney } from "react-icons/gi";

import moment from "moment";

export default function CardDasboard({ dateSelected, type }) {
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  // console.log("type", type);

  const navigate = useNavigate();

  const [totalActiveCustomer, setTotalActiveCustomer] = useState();
  const [totalEquity, setTotalEquity] = useState();
  const [totalCashOut, setTotalCashOut] = useState();
  const [totalCashIn, setTotalCashIn] = useState();
  const [totalCurrentEquity, setTotalCurrentEquity] = useState();
  const [totalCashOutToday, setTotalCashOutToday] = useState();
  const [totalCashInToday, setTotalCashInToday] = useState();

  const { refetch } = useQuery(GET_TOTAL_ACTIVE_CUSTOMER, {
    variables: {
      year: moment(dateSelected)?.format("YYYY"),
      loanType: type === "All" ? "All" : type,
    },
    onCompleted: ({ getTotalActiveCustomer }) => {
      setTotalActiveCustomer(getTotalActiveCustomer);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [dateSelected, type]);

  const { refetch: refetchEquity } = useQuery(GET_TOTAL_EQUITY, {
    variables: {
      year: moment(dateSelected)?.format("YYYY"),
    },
    onCompleted: ({ getTotalEquity }) => {
      setTotalEquity(getTotalEquity);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    refetchEquity();
  }, [dateSelected]);

  const { refetch: refetchCashOut } = useQuery(GET_TOTAL_CASH_OUT, {
    variables: {
      year: moment(dateSelected)?.format("YYYY"),
      loanType: type === "All" ? "All" : type,
    },
    onCompleted: ({ getTotalCashOut }) => {
      setTotalCashOut(getTotalCashOut);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    refetchCashOut();
  }, [dateSelected, type]);

  const { refetch: refetchCashIn } = useQuery(GET_TOTAL_CASH_IN, {
    variables: {
      year: moment(dateSelected)?.format("YYYY"),
      loanType: type === "All" ? "All" : type,
    },
    onCompleted: ({ getTotalCashIn }) => {
      setTotalCashIn(getTotalCashIn);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    refetchCashIn();
  }, [dateSelected, type]);

  const { data: currentEquity } = useQuery(GET_CURRENT_TOTAL_EQUITY, {
    onCompleted: ({ getCurrentEquity }) => {
      setTotalCurrentEquity(getCurrentEquity);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  const { refetch: refetchCashOutToday } = useQuery(GET_CASH_OUT_TODAY, {
    variables: {
      loanType: type === "All" ? "All" : type,
    },
    onCompleted: ({ getCashOutToday }) => {
      setTotalCashOutToday(getCashOutToday);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });
  useEffect(() => {
    refetchCashOutToday();
  }, [type]);

  const { refetch: refetchCashInToday } = useQuery(GET_CASH_IN_TODAY, {
    variables: {
      loanType: type === "All" ? "All" : type,
    },
    onCompleted: ({ getCashInToday }) => {
      setTotalCashInToday(getCashInToday);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });
  useEffect(() => {
    refetchCashInToday();
  }, [type]);

  return (
    <Box className="card-loan-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Stack
            direction={`row`}
            justifyContent={`space-between`}
            alignItems={`center`}
          >
            <Stack direction={`row`} spacing={1.5} alignItems={`center`}>
              <Stack justifyContent={`center`} alignItems={`center`}>
                <img
                  src={flower}
                  alt="flower"
                  style={{ width: "36px", height: "36px" }}
                />
              </Stack>
              <Typography
                className={language === "en" ? "loan-title" : "loan-title-kh"}
              >
                {t("total-summary")}
              </Typography>
            </Stack>
            <Typography
              className={language === "en" ? "customer-txt" : "customer-txt-kh"}
            >
              {" "}
              {totalActiveCustomer} {t("customers")}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-total-principal"
            spacing={0.5}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/equity")}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography
                className={language === "en" ? "title-txt" : "title-txt-kh"}
              >
                {t("total-equity")}
              </Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/equity")}
              >
                {/* <PointOfSaleIcon className="ics-in" /> */}
                <img
                  src={pod}
                  alt="pod"
                  style={{ width: "22px", height: "22px" }}
                />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography
                className={language === "en" ? "sub-txt" : "sub-txt-kh"}
              >
                {t("total-in-cash")}
              </Typography>
              <Typography className="amount-txt">
                {" "}
                {currencyFormatWithZero(totalEquity)}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-out"
            spacing={0.5}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              window.localStorage.setItem("reportType", "cash-out-report");
              window.localStorage.setItem(
                "reportYear",
                moment(dateSelected)?.format("YYYY")
              );
              navigate("/report");
            }}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography
                className={language === "en" ? "title-txt" : "title-txt-kh"}
              >
                {t("total-cash-out")}
              </Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  window.localStorage.setItem("reportType", "cash-out-report");
                  window.localStorage.setItem(
                    "reportYear",
                    moment(dateSelected)?.format("YYYY")
                  );
                  navigate("/report");
                }}
              >
                <FaMoneyBillWave className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography
                className={language === "en" ? "sub-txt" : "sub-txt-kh"}
              >
                {t("total-in-cash")}
              </Typography>
              <Typography className="amount-txt">
                {currencyFormatWithZero(totalCashOut)}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-in"
            spacing={0.5}
            onClick={() => {
              window.localStorage.setItem("reportType", "cash-in-report");
              window?.localStorage?.setItem("reportTypeStatus", type);
              window.localStorage.setItem(
                "reportYear",
                moment(dateSelected)?.format("YYYY")
              );
              navigate("/report");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography
                className={language === "en" ? "title-txt" : "title-txt-kh"}
              >
                {t("total-cash-in")}
              </Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
                onClick={() => {
                  window?.localStorage?.setItem("reportTypeStatus", type);
                  window.localStorage.setItem("reportType", "cash-in-report");
                  window.localStorage.setItem(
                    "reportYear",
                    moment(dateSelected)?.format("YYYY")
                  );
                  navigate("/report");
                }}
                sx={{ cursor: "pointer" }}
              >
                <FaMoneyBill1Wave className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`row`} alignItems={`center`} width={`100%`}>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Stack direction={`column`}>
                    <Typography
                      className={language === "en" ? "sub-txt" : "sub-txt-kh"}
                    >
                      {t("principal")}
                    </Typography>
                    <Typography className="amount-txt">
                      {currencyFormatWithZero(totalCashIn?.principal)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack direction={`column`}>
                    <Typography
                      className={language === "en" ? "sub-txt" : "sub-txt-kh"}
                    >
                      {t("interest")}
                    </Typography>
                    <Typography className="amount-txt">
                      {currencyFormatWithZero(totalCashIn?.interest)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack direction={`column`}>
                    <Typography
                      className={language === "en" ? "sub-txt" : "sub-txt-kh"}
                    >
                      {t("penalty")}
                    </Typography>
                    <Typography className="amount-txt">
                      {currencyFormatWithZero(totalCashIn?.penalty)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-current-total"
            spacing={0.5}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography
                className={language === "en" ? "title-txt" : "title-txt-kh"}
              >
                {t("current-total-equity")}
              </Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
              >
                {/* <SavingsIcon className="ics-in" /> */}
                <img
                  src={charpey}
                  alt="charpey"
                  style={{ width: "24px", height: "24px" }}
                />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography
                className={language === "en" ? "sub-txt" : "sub-txt-kh"}
              >
                {t("total-in-cash")}
              </Typography>
              <Typography className="amount-txt">
                {currencyFormatWithZero(totalCurrentEquity)}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3.5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-out-td"
            spacing={0.5}
            onClick={() => {
              window.localStorage.setItem("reportType", "cash-out-by-day");
              window.localStorage.setItem("dateReport", moment());
              navigate("/report");
            }}
            sx={{ cursor: "pointer" }}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography
                className={language === "en" ? "title-txt" : "title-txt-kh"}
              >
                {t("total-cash-out-today")}
              </Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
                onClick={() => {
                  window.localStorage.setItem("reportType", "cash-out-by-day");
                  window.localStorage.setItem("dateReport", moment());
                  navigate("/report");
                }}
                sx={{ cursor: "pointer" }}
              >
                <GiReceiveMoney className="ics-in" />
              </Stack>
            </Stack>
            <Stack direction={`column`}>
              <Typography
                className={language === "en" ? "sub-txt" : "sub-txt-kh"}
              >
                {t("total-in-cash")}
              </Typography>
              <Typography className="amount-txt">
                {currencyFormatWithZero(totalCashOutToday)}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={5}>
          <Stack
            direction={`column`}
            className="card-box-total-cash-in-td"
            spacing={0.5}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              window.localStorage.setItem(
                "reportType",
                "cash-in-report-by-day"
              );
              window.localStorage.setItem("dateReport", moment());
              navigate("/report");
            }}
          >
            <Stack
              direction={`row`}
              spacing={1}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography
                className={language === "en" ? "title-txt" : "title-txt-kh"}
              >
                {t("total-cash-in-today")}
              </Typography>
              <Stack
                justifyContent={`center`}
                alignItems={`center`}
                className="icon-box-in"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  window.localStorage.setItem(
                    "reportType",
                    "cash-in-report-by-day"
                  );
                  window.localStorage.setItem("dateReport", moment());
                  navigate("/report");
                }}
              >
                <GiPayMoney className="ics-in" />
              </Stack>
            </Stack>
            <Stack
              direction={`row`}
              // justifyContent={`space-between`}
              alignItems={`center`}
              width={`100%`}
            >
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Stack direction={`column`}>
                    <Typography
                      className={language === "en" ? "sub-txt" : "sub-txt-kh"}
                    >
                      {t("principal")}
                    </Typography>
                    <Typography className="amount-txt">
                      {currencyFormatWithZero(totalCashInToday?.principal)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack direction={`column`}>
                    <Typography
                      className={language === "en" ? "sub-txt" : "sub-txt-kh"}
                    >
                      {t("interest")}
                    </Typography>
                    <Typography className="amount-txt">
                      {currencyFormatWithZero(totalCashInToday?.interest)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack direction={`column`}>
                    <Typography
                      className={language === "en" ? "sub-txt" : "sub-txt-kh"}
                    >
                      {t("penalty")}
                    </Typography>
                    <Typography className="amount-txt">
                      {currencyFormatWithZero(totalCashInToday?.penalty)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
