import React, { useEffect } from "react";
import { Stack, Button, IconButton } from "@mui/material";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import GoglobalStoreage from "goglobalstoragenpm";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

export default function FileUploadButton({
  e,
  setDocumentArray,
  documentArray,
  editData,
  handleRemoveDocument,
}) {
  const { language, setAlert } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  let goglobalStoreage = new GoglobalStoreage();
  useEffect(() => {
    goglobalStoreage.createClient(
      "65f900d7f1cf8bddbfe5cd3d",
      "eoIyOFQi6SXDQkyt0WhtAZyMh0bwZNGkM6j6fvkzJ7T"
    );
  }, []);

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const extension = file.type.split("/");
    if (!file) return;
    const newDate = moment(new Date()).format("MMdYYYY");
    let newName = `${uuidv4()}${newDate}}`;
    var newFile = new File([file], `${newName}.${extension[1]}`, {
      type: file.type,
    });
    goglobalStoreage.upload("loan_management", "loanImage", newFile, "");
    return `${process.env.REACT_APP_IMAGE_SERVER}fileName:${newName}.${extension[1]}${process.env.REACT_APP_IMAGE_URL}`;
  };

  const handleUploadDocument = (key, value) => {
    const updateDocumentArray = documentArray?.map((e) => {
      if (e.key === key) e.document = handleUploadImage(value);
      return e;
    });

    setTimeout(() => {
      setDocumentArray(updateDocumentArray);
    }, [150]);
  };

  return (
    <Button key={e?.key} sx={{ position: "relative", padding: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        alignSelf="flex-start"
        sx={{
          width: 150,
          height: 195,
          borderRadius: 1,
          position: "relative",
          border: "1px solid #545454",
          backgroundColor: "transparent",
        }}
      >
        <IconButton
          onClick={() => handleRemoveDocument(e?.key)}
          sx={{
            top: 0,
            right: 0,
            zIndex: 3,
            padding: 0.5,
            position: "absolute",
          }}
        >
          {/* <CircularWithValueLabel /> */}
          <DeleteOutlinedIcon fontSize="medium" sx={{ color: "red" }} />
        </IconButton>

        <input
          onChange={(event) => handleUploadDocument(e?.key, event)}
          type="file"
          style={{
            zIndex: 2,
            opacity: 0,
            width: "100%",
            height: 170,
            cursor: "pointer",
            position: "absolute",
          }}
        />

        <AddIcon
          fontSize="medium"
          sx={{
            zIndex: 2,
            color: "#545454",
            position: "absolute",
          }}
        />
      </Stack>
    </Button>
  );
}
