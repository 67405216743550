import React, { useRef, useState, useContext, useEffect } from "react";
import "./report.scss";
import {
  Stack,
  Box,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useReactToPrint } from "react-to-print";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//components
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import CustomerByDay from "../Component/Report/CustomerByDayReport";
import DailyCustomerPaymentReport from "../Component/Report/DailyCustomerPaymentReport";
import LateCustomerReport from "../Component/Report/LateCustomerReport";
import DifficaltCustomerReport from "../Component/Report/DifficultCustomerReport";
import MonthlyCustomerReport from "../Component/Report/MonthlyCustomerReport";
import NewCustomerReport from "../Component/Report/NewCustomerReport";
import RestOfCustomerReport from "../Component/Report/RestOfCustomerReport";
import CashOutReport from "../Component/Report/CashOutReport";
import CashInDetailReport from "../Component/Report/CashInDetailReport";
import CashOutReportByDay from "../Component/Report/CashOutReportByDay";
import CashInReportByDay from "../Component/Report/CashInReportByDay";
import CashOutReportByMonth from "../Component/Report/CashOutReportByMonth/CashOutReportByMonth";
import CashInReportByMonth from "../Component/Report/CashInReportByMonth/CashInReportByMonth"

export default function Report() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);
  const [typeReport, setTypeReport] = React.useState("dailycustomer");
  const [companyType, setCompanyType] = React.useState("go-credit");
  const [dateSelected, setDateSelected] = useState(moment());

  const [loanType, setLoanType] = useState("All");
  const [checkPrint, setCheckPrint] = useState(false);

  console.log("checkPrint", checkPrint);

  const typeData = window.localStorage.getItem("reportTypeStatus");

  useEffect(() => {
    setLoanType(typeData);
  }, [typeData]);

  const handleChange = (event) => {
    setTypeReport(event.target.value);
  };
  const handleCompany = (event) => {
    setCompanyType(event.target.value);
  };
  const handleLoanType = (event) => {
    setLoanType(event.target.value);
  };

  const data = window.localStorage.getItem("reportType");
  const dateData = window.localStorage.getItem("dateReport");
  const year = window.localStorage.getItem("reportYear");
  useEffect(() => {
    setTypeReport(data);
    setDateSelected(dateData);
    if (data === "cash-out-report") setDateSelected(year);
    if (data === "cash-in-report") setDateSelected(year);
  }, [data, dateData]);

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handalePrintDoc = () => {
    handalePrint();
    setTimeout(() => {
      setCheckPrint(false);
    }, 12000);
  };

  return (
    <div className="setup-report">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} xl={2}>
          <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
            <Box className="slash" />
            <Stack direction="column" justifyContent="center">
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                {t("report")}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={9} xl={10}>
          <Stack direction="row" spacing={2} justifyContent="right">
            <Box className="box-marker" sx={{ width: "260px" }}>
              {noneUserAccesse(["CO"]) ? (
                <FormControl fullWidth className="text-field">
                  <Select
                    value={typeReport}
                    size="small"
                    onChange={handleChange}
                  >
                    <MenuItem value={"dailycustomer"}>
                      {t("daily-customer")}
                    </MenuItem>
                    <MenuItem value={"customerbyday"}>
                      {t("customer-byday")}
                    </MenuItem>
                    <MenuItem value={"latecustomer"}>
                      {t("late-customer")}
                    </MenuItem>
                    <MenuItem value={"difficult"}>
                      {t("difficult-customer")}
                    </MenuItem>
                    <MenuItem value={"newcustomer"}>
                      {t("new-customer")}
                    </MenuItem>
                    <MenuItem value={"restcustomer"}>
                      {t("rest-customer")}
                    </MenuItem>
                    <MenuItem value={"cash-out-by-day"}>
                      {t("cash_out_report_by_day")}
                    </MenuItem>
                    <MenuItem value={"cash-out-by-month"}>
                      {t("cash_out_report_by_month")}
                    </MenuItem>
                    <MenuItem value={"cash-out-report"}>
                      {t("cash_out_report")}
                    </MenuItem>
                    <MenuItem value={"cash-in-report-by-day"}>
                      {t("cash_in_report_by_day")}
                    </MenuItem>
                    <MenuItem value={"cash-in-report-by-month"}>
                      {t("cash_in_report_by_month")}
                    </MenuItem>
                    <MenuItem value={"cash-in-report"}>
                      {t("cash_in_report")}
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <Stack direction="column" className="stack-controll">
                  <FormControl fullWidth className="text-field">
                    <Select
                      value={typeReport}
                      size="small"
                      onChange={handleChange}
                    >
                      <MenuItem value={"dailycustomer"}>
                        {t("daily-customer")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </Box>

            <Box
              className="box-marker"
              sx={{
                display: typeReport === "customerbyday" ? "block" : "none",
                width: "160px",
              }}
            >
              <FormControl fullWidth className="text-field">
                <Select
                  value={companyType}
                  size="small"
                  onChange={handleCompany}
                >
                  <MenuItem value={"go-credit"}>Go Credit</MenuItem>
                  <MenuItem value={"kano-tech"}>Kano Tech</MenuItem>
                  <MenuItem value={"techno-khmer"}>Techno Khmer</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box className="box-marker" sx={{ width: "200px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={
                    typeReport === "newcustomer" ||
                    typeReport === "cash-out-by-month" ||
                    typeReport === "cash-in-report-by-month" ||
                    typeReport === "restcustomer"
                      ? ["month", "year"]
                      : typeReport === "cash-out-report" ||
                        typeReport === "cash-in-report"
                      ? ["year"]
                      : ["year", "month", "day"]
                  }
                  value={dateSelected}
                  onChange={(e) => setDateSelected(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="text-field"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box
              className="box-marker"
              sx={{
                display: typeReport === "cash-in-report" ? "block" : "none",
                width: "160px",
              }}
            >
              <FormControl fullWidth className="text-field">
                <Select value={loanType} size="small" onChange={handleLoanType}>
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Loan"}>Loan</MenuItem>
                  <MenuItem value={"Pawn"}>Pawn</MenuItem>
                  <MenuItem value={"Installments"}>Installments</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Stack direction="column" justifyContent="center" spacing={2}>
              <Button
                className={language === "kh" ? "btn-prinKh" : "btn-prinEn"}
                onClick={handalePrint}
                // onClick={() => {
                //   setCheckPrint(true);
                //   handalePrintDoc();
                // }}
                startIcon={<LocalPrintshopOutlinedIcon className="icon-add" />}
              >
                {t("print")}
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Box className="container">
        {noneUserAccesse(["Teller"]) ? (
          <div ref={componentRef}>
            {typeReport === "customerbyday" ? (
              <CustomerByDay
                dateSelected={dateSelected}
                companyType={companyType}
              />
            ) : typeReport === "dailycustomer" ? (
              <DailyCustomerPaymentReport dateSelected={dateSelected} />
            ) : typeReport === "latecustomer" ? (
              <LateCustomerReport dateSelected={dateSelected} />
            ) : typeReport === "difficult" ? (
              <DifficaltCustomerReport dateSelected={dateSelected} />
            ) : typeReport === "newcustomer" ? (
              <NewCustomerReport dateSelected={dateSelected} />
            ) : typeReport === "cash-out-by-day" ? (
              <CashOutReportByDay dateSelected={dateSelected} />
            ) : typeReport === "cash-out-report" ? (
              <CashOutReport dateSelected={dateSelected} />
            ) : typeReport === "cash-out-by-month" ? (
              <CashOutReportByMonth dateSelected={dateSelected} />
            ) : typeReport === "cash-in-report-by-day" ? (
              <CashInReportByDay dateSelected={dateSelected} />
            ) : typeReport === "cash-in-report-by-month" ? (
              <CashInReportByMonth dateSelected={dateSelected} />
            ) : typeReport === "cash-in-report" ? (
              <CashInDetailReport
                dateSelected={dateSelected}
                loanType={loanType}
              />
            ) : (
              <RestOfCustomerReport dateSelected={dateSelected} />
            )}
          </div>
        ) : (
          <div ref={componentRef}>
            <DailyCustomerPaymentReport dateSelected={dateSelected} />
          </div>
        )}
      </Box>
    </div>
  );
}

{
  /* <MonthlyCustomerReport dateSelected={dateSelected} /> */
}
