import React, { useState, useEffect, useContext } from "react";
import "./addinstallment.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  IconButton,
  FormHelperText,
  Switch,
  Divider,
} from "@mui/material";
import * as Yup from "yup";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { useFormik, Form, FormikProvider } from "formik";
import { AuthContext } from "../../../context/AuthContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GET_LOANPLAN_BY_ID } from "../../../Schema/Loanplan";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { translateLauguage } from "../../../Function/Translate";
import { CREATE_INSTALLMENT } from "../../../Schema/Installment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  SelectCustomer,
  SelectLoanType,
  SelectLoanPlan,
} from "../../../Function/OwnComponents";

export default function AddInstallment({
  open,
  handleClose,
  setRefetch,
  typeOfLoan,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);
  let userLoginData = JSON.parse(window.localStorage.getItem("userLogin"));

  //calculate function
  const [checkTotal, setCheckTotal] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [loanPlanByIdData, setLoanPlanByIdData] = useState({});
  //select function
  const [customerVal, setCustomerVal] = useState({ id: "", title: "" });
  const [loanTypeVal, setLoanTypeVal] = useState({ id: "", title: "" });
  const [loanPlanVal, setLoanPlanVal] = useState({ id: "", title: "" });
  const [guarantorVal, setGuarantorVal] = useState({ id: "", title: "" });
  const [coBorrowerVal, setCoBorrowerVal] = useState({ id: "", title: "" });

  const [createInstallment] = useMutation(CREATE_INSTALLMENT, {
    onCompleted: ({ createInstallment }) => {
      if (createInstallment?.isSuccess) {
        setAlert(true, "success", createInstallment?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", createInstallment?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  //fromik
  const AddLoanList = Yup.object().shape({
    loan_id: Yup.string(),
    loan_date: Yup.date(),
    release_date: Yup.date(),
    is_first_month: Yup.string(),
    first_month_date: Yup.date(),
    co_phone_number: Yup.string(),
    loan_guarantor_id: Yup.string(),
    borrower_connection: Yup.string(),
    borrower_together_id: Yup.string(),
    borrower_connection_together: Yup.string(),
    product: Yup.string().required("require!"),
    purpose: Yup.string().required("require!"),
    loan_plan: Yup.string().required("require!"),
    loan_amount: Yup.string().required("require!"),
    customer_id: Yup.string().required("require!"),
    company_name: Yup.string().required("require!"),
    loan_amount_letters: Yup.string().required("require!"),
  });

  const formik = useFormik({
    initialValues: {
      loan_id: "",
      purpose: "",
      product: "",
      loan_plan: "",
      loan_amount: 0,
      customer_id: "",
      loan_date: moment(),
      co_phone_number: "",
      is_first_month: false,
      loan_guarantor_id: "",
      release_date: moment(),
      borrower_connection: "",
      loan_amount_letters: "",
      borrower_together_id: "",
      company_name: "Go-Credit",
      first_month_date: moment(),
      borrower_connection_together: "",
    },

    validationSchema: AddLoanList,
    onSubmit: async (values) => {
      setLoading(true);
      let newInstallmentValues = {
        loan_id: values?.loan_id,
        loan_plan: values?.loan_plan,
        loan_amount: values?.loan_amount,
        customer_id: values?.customer_id,
        loan_amount_letters: values?.loan_amount_letters,
        is_first_month: values?.is_first_month,
        first_month_date:
          values?.is_first_month === true ? values?.first_month_date : "",
        loan_date: values?.loan_date,
        purpose: values?.purpose,
        payment_method: "Fixed",
        type_of_loan: typeOfLoan,
        borrower_together: {
          borrower_together_id:
            coBorrowerVal?.id === "" || coBorrowerVal?.id === undefined
              ? null
              : coBorrowerVal?.id,
          borrower_connection: values?.borrower_connection_together,
          note: "",
        },
        loan_guarantor: {
          loan_guarantor_id:
            guarantorVal?.id === "" || guarantorVal?.id === undefined
              ? null
              : guarantorVal?.id,
          borrower_connection: values?.borrower_connection,
          note: "",
        },
        installments: {
          co_id: userLoginData?._id,
          co_phone_number: values?.co_phone_number,
          company_name: values?.company_name,
          product: values?.product,
          release_date: values?.release_date,
        },
      };

      // console.log("newInstallmentValues::=>", newInstallmentValues);

      createInstallment({
        variables: {
          input: {
            ...newInstallmentValues,
          },
        },
      });
    },
  });

  const {
    errors,
    values,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (customerVal?.id !== "") {
      setFieldValue("customer_id", customerVal?.id);
    }
  }, [customerVal?.id]);

  useEffect(() => {
    if (loanTypeVal?.id !== "") {
      setFieldValue("loan_type", loanTypeVal?.id);
    }
  }, [loanTypeVal?.id]);

  useEffect(() => {
    if (loanPlanVal?.id !== "") {
      setFieldValue("loan_plan", loanPlanVal?.id);
    }
  }, [loanPlanVal?.id]);

  const { data: LoanPlanByIdData } = useQuery(GET_LOANPLAN_BY_ID, {
    variables: {
      getLoanPlanByIdId: values?.loan_plan,
    },
    onCompleted: ({ getLoanPlanById }) => {
      setLoanPlanByIdData(getLoanPlanById);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  const handleCalculate = () => {
    setTotalPayable(
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100
    );
    setCheckTotal(
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100
    );
  };

  useEffect(() => {
    if (
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100 !==
        checkTotal &&
      checkTotal !== 0 &&
      values?.loan_amount !== ""
    ) {
      setTotalPayable(
        values?.loan_amount / loanPlanByIdData?.month +
          (values?.loan_amount * loanPlanByIdData?.interest) / 100
      );
    } else if (
      values?.loan_amount / loanPlanByIdData?.month +
        (values?.loan_amount * loanPlanByIdData?.interest) / 100 ===
        checkTotal &&
      checkTotal !== 0 &&
      values?.loan_amount !== ""
    ) {
      setTotalPayable(checkTotal);
    } else {
      setTotalPayable(0);
    }
  }, [
    values?.loan_amount,
    loanPlanByIdData?.month,
    loanPlanByIdData?.interest,
  ]);

  useEffect(() => {
    if (!open) {
      setCheckTotal(0);
      setTotalPayable(0);
      setLoanPlanByIdData({});
      setCustomerVal({ id: "", title: "" });
      setLoanTypeVal({ id: "", title: "" });
      setLoanPlanVal({ id: "", title: "" });
      setGuarantorVal({ id: "", title: "" });
      setCoBorrowerVal({ id: "", title: "" });
      resetForm();
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      className="loan-list-dialog"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("create-installment")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={1.5} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("borrower")}
                </Typography>
                <SelectCustomer
                  setIdValue={setCustomerVal}
                  selectedValue={customerVal}
                />
                {!!errors.customer_id && touched.customer_id && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.customer_id}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-id")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="loan id auto generate"
                  {...getFieldProps("loan_id")}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("co-borrower")}
                </Typography>

                <SelectCustomer
                  setIdValue={setCoBorrowerVal}
                  customerId={customerVal?.id}
                  disabled={customerVal?.id === "" ? true : false}
                  selectedValue={coBorrowerVal}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("borrower-connection")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="borrower connection"
                  disabled={customerVal?.id === "" ? true : false}
                  {...getFieldProps("borrower_connection_together")}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-guarantor")}
                </Typography>
                <SelectCustomer
                  setIdValue={setGuarantorVal}
                  customerId={customerVal?.id}
                  coBorrowerId={coBorrowerVal?.id}
                  disabled={customerVal?.id === "" ? true : false}
                  selectedValue={guarantorVal}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("borrower-connection")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="borrower connection"
                  disabled={customerVal?.id === "" ? true : false}
                  {...getFieldProps("borrower_connection")}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("company-name")}
                </Typography>
                <FormControl sx={{ width: "100%" }} size="small">
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={values?.company_name}
                    onChange={(event) =>
                      setFieldValue("company_name", event.target.value)
                    }
                    error={Boolean(touched.company_name && errors.company_name)}
                  >
                    <MenuItem value="Go-Credit">Go Credit</MenuItem>
                    <MenuItem value="Kano-Tech">Kano Tech</MenuItem>
                    <MenuItem value="TechNo-Khmer">Techno Khmer</MenuItem>
                  </Select>
                </FormControl>
                {!!errors.company_name && touched.company_name && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.company_name}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("product")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="product"
                  {...getFieldProps("product")}
                  error={Boolean(touched.product && errors.product)}
                  helperText={touched.product && errors.product}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("co-phone")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="co phone number"
                  {...getFieldProps("co_phone_number")}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-date")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values?.loan_date}
                    onChange={(newValue) => {
                      setFieldValue("loan_date", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className="text-field"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("release-date")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={values?.release_date}
                    onChange={(newValue) => {
                      setFieldValue("release_date", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className="text-field"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={values?.is_first_month === true ? 4 : 8}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("is-first-month")}
                </Typography>
                <Switch
                  checked={values?.is_first_month}
                  onChange={(e) =>
                    setFieldValue("is_first_month", e?.target.checked)
                  }
                />
              </Grid>

              {values?.is_first_month === true ? (
                <Grid item xs={4}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("first-month-date")}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={values?.first_month_date}
                      onChange={(newValue) => {
                        setFieldValue("first_month_date", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          className="text-field"
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              ) : null}

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan plan")}
                </Typography>
                <SelectLoanPlan
                  setIdValue={setLoanPlanVal}
                  selectedValue={loanPlanVal}
                  typeOfPaymentPeriod={"Month"}
                />
                {!!errors.loan_plan && touched.loan_plan && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.loan_plan}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("loan-amount")}
                </Typography>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  placeholder="loan amount"
                  {...getFieldProps("loan_amount")}
                  error={Boolean(touched.loan_amount && errors.loan_amount)}
                  helperText={touched.loan_amount && errors.loan_amount}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("amount-letters")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="amount letters"
                  {...getFieldProps("loan_amount_letters")}
                  error={Boolean(
                    touched.loan_amount_letters && errors.loan_amount_letters
                  )}
                  helperText={
                    touched.loan_amount_letters && errors.loan_amount_letters
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  className={
                    language === "kh" ? "field-titleKh" : "field-titleEn"
                  }
                >
                  {t("purpose")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="purpose"
                  {...getFieldProps("purpose")}
                  error={Boolean(touched.purpose && errors.purpose)}
                  helperText={touched.purpose && errors.purpose}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className={
                    language === "kh" ? "btn-calculateKh" : "btn-calculateEn"
                  }
                  onClick={handleCalculate}
                  disabled={
                    values?.loan_plan === "" ||
                    values?.loan_amount === 0 ||
                    values?.loan_amount === ""
                      ? true
                      : false
                  }
                >
                  {t("calculate")}
                </Button>
              </Grid>

              <Grid item xs={5}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("total-payable")} :
                  </Typography>
                  <Typography className="field-title-total">
                    ${(totalPayable * loanPlanByIdData?.month).toFixed(3)}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={7}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    className={
                      language === "kh" ? "field-titleKh" : "field-titleEn"
                    }
                  >
                    {t("monthly-total-payable")} :
                  </Typography>
                  <Typography className="field-title-total">
                    ${totalPayable.toFixed(3)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Box className="box-action">
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={
                    language === "kh" ? "btn-createKh" : "btn-createEn"
                  }
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={
                    language === "kh" ? "btn-createKh" : "btn-createEn"
                  }
                  disabled={totalPayable === 0 ? true : false}
                  onClick={handleSubmit}
                >
                  {t("create")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
