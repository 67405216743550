import React, { useContext, useState } from "react";
import "./cashoutbymonthchart.scss";
import { Grid, Stack, Typography, Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import { GET_CASH_IN_AND_OUT_CHART } from "../../../Schema/Dashboard";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import {
  currencyFormat,
  getKhmerMonth,
} from "../../../Function/GetKhmerNumber";

export default function CashOutByMonthChart({ editData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  // console.log("editData", editData);

  const date = editData?.details_cash_out?.map((e) =>
    moment(e?.loan_date)?.format("D")
  );
  // const total = editData?.details_cash_out?.map((e) =>
  //   e?.amount_and_type
  //     ?.filter((e) => e?.total_amount)
  //     ?.reduce((sum, item) => sum + item.total_amount, 0)
  // );

  const total = editData?.details_cash_out?.map((e) => e?.total_amount);

  const state = {
    series: [
      {
        name: t("total"),
        data: total,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        fontFamily: "Siemreap",
        fontSize: "12px",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          // columnWidth: "100%",
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        formatter: function (val) {
          // return currencyFormat(val);
          return val === 0 ? "" : currencyFormat(val);
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        // categories: dayOfMonth,
        categories: date,
      },
      // yaxis: {
      //   // max: overallHighest + 100,
      //   title: {
      //     text: "($)",
      //   },
      //   labels: {
      //     formatter: function (val) {
      //       return val?.toFixed(0);
      //     },
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " $";
          },
          style: {
            fontFamily: "Siemreap",
            fontSize: "12px",
          },
        },
      },
    },
  };

  return (
    <Box>
      <Stack className="cash-out-by-month-chart">
        <Stack direction="row" justifyContent="center">
          <Typography className="title">បញ្ចេញកម្ចីសរុបប្រចាំថ្ងៃ</Typography>
        </Stack>
        <Box sx={{ width: "720px !important" }}>
          <div id="chart">
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="bar"
              height={300}
            />
          </div>
        </Box>
      </Stack>
    </Box>
  );
}
