import React, { useContext, useState } from "react";
import "./cashinandoutchart.scss";
import { Grid, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import { GET_CASH_IN_AND_OUT_CHART } from "../../../Schema/Dashboard";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import {
  currencyFormat,
  getKhmerMonth,
} from "../../../Function/GetKhmerNumber";

export default function CashInAndOutChart({ monthVal, dateSelected, type }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  const [cashIn, setCashIn] = useState([]);
  const [cashOut, setCashOut] = useState([]);
  const [dayOfMonth, setDayOfMonth] = useState([]);
  const [date, setDate] = useState([]);

  const { data } = useQuery(GET_CASH_IN_AND_OUT_CHART, {
    variables: {
      month: parseInt(moment(monthVal)?.format("MM")),
      year: parseInt(moment(dateSelected)?.format("YYYY")),
      loanType: type === "All" ? "" : type,
    },
    onCompleted: ({ getCashInAndOutChart }) => {
      // console.log("getCashInAndOutChart==>",getCashInAndOutChart)
      setCashIn(getCashInAndOutChart?.map((e) => e?.cash_in));
      setCashOut(getCashInAndOutChart?.map((e) => e?.cash_out));
      setDayOfMonth(getCashInAndOutChart?.map((e) => e?.day_of_month));
      setDate(
        getCashInAndOutChart?.map((e) => {
          return {
            day_of_month: e?.day_of_month,
            date: e?.date,
          };
        })
      );
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log("dayOfMonth???", dayOfMonth)
  // console.log("date???", date)

  const state = {
    series: [
      {
        name: t("cash_in"),
        data: cashIn,
      },
      {
        name: t("cash_out"),
        data: cashOut,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        fontFamily: "Siemreap",
        fontSize: "12px",
        events: {
          click: (event, chartContext, config) => {
            const { seriesIndex, dataPointIndex } = config;

            // Get series name and day
            const seriesName = state.series[seriesIndex].name;
            const day = dayOfMonth[dataPointIndex];

            if (seriesName === "Cash Out") {
              navigate("/report");
              window.localStorage.setItem("reportType", "cash-out-by-day");
              const findFullDate = date.find(
                (elem) => elem.day_of_month === day
              );
              if (findFullDate) {
                window.localStorage.setItem(
                  "dateReport",
                  moment(findFullDate.date)?.format("YYYY-MM-DD")
                );
              }
            } else {
              navigate("/report");
              window.localStorage.setItem(
                "reportType",
                "cash-in-report-by-day"
              );
              const findFullDate = date.find(
                (elem) => elem.day_of_month === day
              );
              if (findFullDate) {
                window.localStorage.setItem(
                  "dateReport",
                  moment(findFullDate.date)?.format("YYYY-MM-DD")
                );
              }
            }
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          // columnWidth: "100%",
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        formatter: function (val) {
          // return currencyFormat(val);
          return val === 0 ? "" : currencyFormat(val);
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: dayOfMonth,
        // categories: date,
      },
      yaxis: {
        // max: overallHighest + 100,
        title: {
          text: "($)",
        },
        labels: {
          formatter: function (val) {
            return val?.toFixed(0);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " $";
          },
          style: {
            fontFamily: "Siemreap",
            fontSize: "12px",
          },
        },
      },
    },
  };

  return (
    <Stack className="cash-in-and-out-chart">
      <Stack direction="row" justifyContent="center">
        <Typography className={language === "en" ? "title-en" : "title"}>
          {t("cash_in_and_cash_out")}{" "}
          {language === "en"
            ? moment(monthVal)?.format("MMMM")
            : getKhmerMonth(moment(monthVal)?.format("M"))}
        </Typography>
      </Stack>
      <Stack id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={300}
        />
      </Stack>
    </Stack>
  );
}
