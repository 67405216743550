import React, { useEffect } from "react";
import { Stack, Button } from "@mui/material";
import MediaCard from "./MediaCard";
import FileThumnail from "./FileThumnail";
import AddIcon from "@mui/icons-material/Add";
import { deleteFileAPI } from "../../../api/api";
import GoglobalStoreage from "goglobalstoragenpm";
import FileUploadButton from "./FileUploadButton";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import { useMutation as mutationDeleteImage } from "react-query";

export default function DocumentMetting({
  documentArray,
  setDocumentArray,
  editData,
  setRefetch,
}) {
  const { language, setAlert } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const newDocument = {
    key: Date.now() + Math?.random(),
    document: "",
  };

  let goglobalStoreage = new GoglobalStoreage();

  React.useEffect(() => {
    goglobalStoreage.createClient(
      "63d72f0eb50e4d904c1a70cd",
      "X3FnTtFuFVlkcccJnQn0iA5reQhoxNkpZ9t4Lc2bcrC"
    );
  }, []);
  // ================== remove image form server================
  const mutationDelete = mutationDeleteImage(deleteFileAPI);
  const removeDocumentFromServer = (document) => {
    if (document) {
      let splitSrc = document.split(":")[4];
      mutationDelete.mutate({
        storage: "goglobalit_hrms",
        folder: "hrms_images",
        file: splitSrc.split("/")[0],
      });
    }
  };

  const handleRemoveDocument = (key) => {
    const findDocumentArray = documentArray?.find((e) => e?.key === key);
    removeDocumentFromServer(findDocumentArray?.document || "");
    const updateDocumentArray = documentArray?.filter((e) => e?.key !== key);
    setDocumentArray(updateDocumentArray);
  };

  const handleChangeTitleDocument = (key, title) => {
    // console.log("key ==>", key);
    // console.log("title ==>", title);
    const dataDocumentArray = documentArray?.map((e) => {
      if (e?.key === key) e.title = title;
      return e;
    });
    setDocumentArray(dataDocumentArray);
  };

  //================================================================

  function checkExtensionFile(url) {
    const parts = url.split("/");
    const filePart = parts[parts.length - 3];
    filePart.split("/");
    const resultArray = filePart.split(".");
    return resultArray[resultArray.length - 1];
  }

  const showDocument = (e) => {
    const fileType = checkExtensionFile(e?.document);
    if (fileType === "pdf")
      return (
        <FileThumnail
          e={e}
          pdfFile={e?.document}
          handleRemoveDocument={handleRemoveDocument}
          handleChangeTitleDocument={handleChangeTitleDocument}
        />
      );
    else
      return (
        <MediaCard
          e={e}
          handleRemoveDocument={handleRemoveDocument}
          handleChangeTitleDocument={handleChangeTitleDocument}
        />
      );
  };

  return (
    <Stack direction="row" display="flex" flexWrap="wrap" sx={{ mt: 2 }}>
      {documentArray?.map((e) =>
        e?.document ? (
          showDocument(e)
        ) : (
          <FileUploadButton
            e={e}
            editData={editData}
            setRefetch={setRefetch}
            documentArray={documentArray}
            setDocumentArray={setDocumentArray}
            handleRemoveDocument={handleRemoveDocument}
          />
        )
      )}
    </Stack>
  );
}
