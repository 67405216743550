import * as React from "react";
import { AuthContext } from "../../../context/AuthContext";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import { translateLauguage } from "../../../Function/Translate";
import { Box, IconButton, Stack, TextField } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

export default function FileThumnail({
  e,
  pdfFile,
  handleRemoveDocument,
  handleChangeTitleDocument,
}) {
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [thumnail, setThumnail] = React.useState("");

  async function generateThumbnails(pdf) {
    try {
      // console.log("pdf ==>", pdf);
      let thumbnails = await generatePdfThumbnails(pdf, 200);
      setThumnail(thumbnails[0].thumbnail);
    } catch (err) {
      console.error(err, "err document");
    }
  }

  React.useEffect(() => {
    if (pdfFile) {
      generateThumbnails(pdfFile);
    }
  }, [pdfFile]);

  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <Box
        sx={{
          margin: 1,
          width: 150, 
          height: 187,
          cursor: "pointer",
          position: "relative",
          borderRadius: "12px",
          boxShadow: "0px 0.5px 3px 1px rgba(0,0,0,0.1)",
        }}
      >
        <Stack spacing={1}>
          <IconButton
            onClick={() => handleRemoveDocument(e?.key)}
            sx={{
              top: 0,
              right: 0,
              zIndex: 3,
              padding: 0.5,
              position: "absolute",
            }}
          >
            <DeleteOutlinedIcon fontSize="medium" sx={{ color: "red" }} />
          </IconButton>
          <img
            onClick={() => openLinkInNewTab(pdfFile)}
            src={thumnail}
            alt="document"
            style={{
              zIndex: 1,
              height: 187,
              width: "100%",
              borderRadius: "12px",
            }}
          />
          <TextField
            fullWidth
            size="small"
            disabled={!e?.document}
            value={e?.title}
            className="text-field-name"
            placeholder={t("document-name")}
            onChange={({ target }) =>
              handleChangeTitleDocument(e?.key, target.value)
            }
          />
        </Stack>
      </Box>
    </>
  );
}
