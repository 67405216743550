import React, { useState, useContext, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
// layouts
// import Layout from "./Layout/LayoutOld";
import Layout from "./Layout/Layout";
import LayoutNew from "./Layout/LayoutNew";
//Page
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import LoanList from "./Pages/LoanList";
import LoanPlans from "./Pages/LoanPlans";
import LoanType from "./Pages/LoanType";
import LoanlistDetails from "./Component/LoanList/LoanlistDetails/LoanlistDetails";
import Payments from "./Pages/Payments";
import Users from "./Pages/Users";
import Page404 from "./Pages/Page404";
import Customers from "./Pages/Customers";
import CustomerDetail from "./Component/Customers/CostomerDetails/CustomerDetail";
import AddCustomers from "./Component/Customers/AddCustomers/AddCustomers";
import UpdateCustomers from "./Component/Customers/UpdateCustomers/UpdateCustomers";
import Report from "./Pages/Report";
import Setting from "./Pages/Setting";
import SourceOfMoney from "./Pages/SourceOfMoney";
import { GET_USER_LOGIN } from "./Schema/Users";
import { AuthContext } from "./context/AuthContext";
import { useQuery } from "@apollo/client";

import Equity from "./Pages/Equity";

import NewDashboard from "./Pages/NewDashboard/NewDashboard";

export default function Router({ prefersDarkMode, setPrefersDarkMode }) {
  const { data, refetch } = useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      if (getUserLogin?.firsName !== null) {
        window.localStorage.setItem("userLogin", JSON.stringify(getUserLogin));
        // console.log("getUserLogin::", getUserLogin);
      }
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    async function handleRefetch() {
      await refetch();
    }
    if (data?.getUserLogin?.firsName === null) {
      handleRefetch();
    }
  }, [data?.getUserLogin]);

  // ==================================== check Route =======================================
  //   const { noneUserAccesse } = useContext(AuthContext);
  const { state } = useContext(AuthContext);

  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem("users"))
  );

  useEffect(() => {
    let userStorage = JSON.parse(window.localStorage.getItem("users"));
    if (userStorage) {
      setUser(userStorage);
      return;
    }

    if (state?.user) {
      setTimeout(() => {
        setUser(state?.user?.email);
        window.localStorage.setItem(
          "users",
          JSON.stringify(state?.user?.email)
        );
      }, 1200);
    } else {
      setUser(null);
    }
  }, [state?.user]);

  // ===================================== End check Route ==================================

  const LoginPage = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "*", element: <Login /> },
  ]);

  const Content = useRoutes([
    {
      path: "/",
      element: (
        <LayoutNew
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        // { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "report", element: <Report /> },
        { path: "equity", element: <Equity /> },
        { path: "loanlist", element: <LoanList /> },
        { path: "loantype", element: <LoanType /> },
        { path: "payments", element: <Payments /> },
        { path: "customer", element: <Customers /> },
        { path: "loanplans", element: <LoanPlans /> },
        { path: "dashboard", element: <NewDashboard /> },
        { path: "setting", element: <Setting /> },
        { path: "source-of-money", element: <SourceOfMoney /> },
        { path: "/customer/create-customer", element: <AddCustomers /> },
        { path: "/customer/update-customer", element: <UpdateCustomers /> },
        { path: "/customer/customer-details", element: <CustomerDetail /> },
        { path: "/loanRequest/loanlist-details", element: <LoanlistDetails /> },
        { path: "/loanlist/loanlist-details", element: <LoanlistDetails /> },

        // { path: "newdashboard", element: <NewDashboard /> },

        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  if (user !== undefined && user !== null) {
    return Content;
  } else {
    return LoginPage;
  }
}
