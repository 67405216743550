import React, { useContext, useState, useEffect } from "react";
import "./equityform.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { CREATE_EQUITY, UPDATE_EQUITY } from "../../Schema/Equity";
//date picker
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

import { SelectSourceOfMoney } from "../../Function/DynamicFunction";

export default function EquityForm({
  open,
  handleClose,
  setRefetch,
  dialogTitle,
  editRow,
}) {
  const { setAlert } = useContext(AuthContext);

  // console.log("editRowEquity", editRow);

  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  //hiden and show vibility
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [sourceMoney, setSourceMoney] = useState({ id: "", title: "" });

  //Create user
  const [createEquity] = useMutation(CREATE_EQUITY, {
    onCompleted: ({ createEquity }) => {
      // console.log("user", createEquity);
      if (createEquity?.isSuccess) {
        handleClose();
        setLoading(false);
        setAlert(true, "success", createEquity?.message);
        setRefetch();
        resetForm();
        setSourceMoney({ id: "", title: "" });
      } else {
        handleClose();
        setLoading(false);
        setAlert(true, "error", createEquity?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  //Create user
  const [updateEquity] = useMutation(UPDATE_EQUITY, {
    onCompleted: ({ updateEquity }) => {
      // console.log("updateEquity", updateEquity);
      if (updateEquity?.isSuccess) {
        handleClose();
        setLoading(false);
        setAlert(true, "success", updateEquity?.message);
        setRefetch();
      } else {
        console.log("error==>", updateEquity?.message);
        setAlert(true, "error", updateEquity?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
      console.log("error==>", error?.message);
    },
  });

  //fromik
  const Validation = Yup.object().shape({
    amount: Yup.string().required(t("require")),
    cash_in_type: Yup.string().required(t("require")),
    source_of_money_id: Yup.string().required(t("require")),
    date: Yup.string(),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      cash_in_type: "Equity",
      date: moment()?.format("YYYY-MM-DD"),
      description: "",
      source_of_money_id: "",
    },

    validationSchema: Validation,
    onSubmit: async (values) => {
      // console.log("date :" ,values?.date)
      // console.log("values", moment(values?.date)?.format("YYYY-MM-DD"));
      setLoading(true);

      if (dialogTitle === "Create") {
        createEquity({
          variables: {
            input: {
              ...values,
              date: moment(values?.date)?.format("YYYY-MM-DD"),
              source_of_money_id: values?.source_of_money_id,
            },
          },
        });
      } else {
        updateEquity({
          variables: {
            id: editRow?._id,
            input: {
              ...values,
              date: moment(values?.date)?.format("YYYY-MM-DD"),
              source_of_money_id: values?.source_of_money_id,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    setFieldValue("source_of_money_id", sourceMoney?.id);
  }, [sourceMoney?.id]);

  useEffect(() => {
    if (editRow) {
      setFieldValue("amount", editRow?.amount);
      setFieldValue("cash_in_type", editRow?.cash_in_type);
      setFieldValue("date", editRow?.date);
      setFieldValue("description", editRow?.description);
      setSourceMoney({
        id: editRow?.source_of_money_id?._id,
        title: editRow?.source_of_money_id?.title,
      });
    }
  }, [editRow, open]);

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;



  return (
    <Dialog open={open} className="create-user" fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {dialogTitle === "Create" ? t("create-equity") : t("update-equity")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("amount")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  onFocus={(e) => e?.target?.select()}
                  {...getFieldProps("amount")}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("source_of_money")}
                </Typography>
                <SelectSourceOfMoney
                  selectedVal={sourceMoney}
                  setSelectVal={setSourceMoney}
                />

                {!!errors.source_of_money_id && touched.source_of_money_id && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.source_of_money_id}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("cash_in_type")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    type="text"
                    {...getFieldProps("cash_in_type")}
                    error={Boolean(touched.cash_in_type && errors.cash_in_type)}
                    helperText={touched.cash_in_type && errors.cash_in_type}
                  >
                    <MenuItem value="Equity">Equity</MenuItem>
                    <MenuItem value="Liabilities">Liabilities</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("date")}
                </Typography>
   <LocalizationProvider dateAdapter={AdapterDateFns}> 
                  <DatePicker 
                    value={values?.date}
                    onChange={(newValue) => { 
                      setFieldValue("date", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        className="text-field"
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider> 
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("desc")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  placeholder="description"
                  {...getFieldProps("description")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Box className="action">
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={
                    language === "kh" ? "btn-actionKh" : "btn-actionEn"
                  }
                  fullWidth
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={
                    language === "kh" ? "btn-actionKh" : "btn-actionEn"
                  }
                  fullWidth
                  onClick={handleSubmit}
                >
                  {t("create")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
