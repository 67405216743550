import { gql } from "@apollo/client";

export const GET_LOAN_TOTAL = gql`
  query GetLoanTotalByCustomer {
    getLoanTotalByCustomer {
      total_customer
      total_amount
    }
  }
`;

export const GET_TOTAL_TYPE_LOAN = gql`
  query GetTotalTypeOfLoan {
    getTotalTypeOfLoan {
      total_customer
      total_amount
    }
  }
`;

export const GET_TOTAL_TYPE_PAWN = gql`
  query GetTotalTypeOfPawn {
    getTotalTypeOfPawn {
      total_customer
      total_amount
    }
  }
`;

export const GET_TOTAL_TYPE_INSTALLMENT = gql`
  query GetTotalTypeOfInstallments {
    getTotalTypeOfInstallments {
      total_customer
      total_amount
    }
  }
`;

export const GET_REPORT_COSTMER_CHART = gql`
  query GetChartDashboard {
    getChartDashboard {
      customerChart {
        activeData
        difficultData
        lateData
      }
      loanChart {
        lateData
        activeData
        difficultData
      }
    }
  }
`;

export const GET_REPORT_CUSTOMER_PER_YEAR = gql`
  query Query($year: Int!) {
    getReportTypeOfLoanTotalCustomerByYear(year: $year)
  }
`;

export const GET_REPORT_LOAN_PER_YEAR = gql`
  query Query($year: Int!) {
    getReportTypeOfLoanByYear(year: $year)
  }
`;

export const GET_ALL_LOAN_TYPE = gql`
  query GetAllLoanType {
    getAllLoanType {
      _id
      loan_type_name
      remark
    }
  }
`;

export const GET_TOTAL_ACTIVE_CUSTOMER = gql`
  query Query($year: String!, $loanType: String!) {
    getTotalActiveCustomer(year: $year, loan_type: $loanType)
  }
`;

export const GET_TOTAL_EQUITY = gql`
  query Query($year: String!) {
    getTotalEquity(year: $year)
  }
`;

export const GET_TOTAL_CASH_OUT = gql`
  query Query($year: String!, $loanType: String!) {
    getTotalCashOut(year: $year, loan_type: $loanType)
  }
`;

export const GET_TOTAL_CASH_IN = gql`
  query GetTotalCashIn($year: String!, $loanType: String!) {
    getTotalCashIn(year: $year, loan_type: $loanType) {
      principal
      interest
      penalty
    }
  }
`;

export const GET_CURRENT_TOTAL_EQUITY = gql`
  query Query {
    getCurrentEquity
  }
`;

export const GET_CASH_OUT_TODAY = gql`
  query Query($loanType: String!) {
    getCashOutToday(loan_type: $loanType)
  }
`;

export const GET_CASH_IN_TODAY = gql`
  query GetCashInToday($loanType: String!) {
    getCashInToday(loan_type: $loanType) {
      principal
      interest
      penalty
    }
  }
`;

export const GET_CASH_IN_AND_OUT_CHART = gql`
  query GetCashInAndOutChart($year: Int!, $month: Int!, $loanType: String!) {
    getCashInAndOutChart(year: $year, month: $month, loan_type: $loanType) {
      cash_in
      cash_out
      date
      day_of_month
    }
  }
`;

export const GET_CUSTOMER_STATICTIS_FOR_REPORT = gql`
query GetCustomerStatisticForChart($year: Int!, $loanType: String!) {
  getCustomerStatisticForChart(year: $year, loan_type: $loanType) {
    month
    active
    completed
  }
}
`;
