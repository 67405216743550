import React, { useState, useEffect, useRef } from "react";
import "./printloanapprove.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, Divider } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { getKhmerNumber } from "../../../Function/GetKhmerNumber";
import { getKhmerMonth } from "../../../Function/GetKhmerNumber";
import {
  getKhmerDay,
  getFormattedPhoneNum,
} from "../../../Function/GetKhmerNumber";
import wingdingImage from "../../../Assets/tacteing-header.PNG";
import logoCompany from "../../../Assets/logo.svg";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function PrintLoanApprove({ 
  open,
  handleClose,
  tableData,
  paymentType,
}) {
  const [khmerDateString, setKhmerDateString] = useState("");
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // console.log("tableData::", tableData);

  let totalInterestDetails = tableData?.payment_detail.reduce(function (a, b) {
    return a + b.interest;
  }, 0);

  let totalPaymentDetails = tableData?.payment_detail.reduce(function (i, j) {
    return i + j.monthly_payment;
  }, 0);

  let totalInterestDetailsAdjustment =
    tableData?.adjustment?.adjustment_payment?.reduce(function (a, b) {
      return a + b.interest;
    }, 0);

  let totalPaymentDetailsAdjustment =
    tableData?.adjustment?.adjustment_payment?.reduce(function (i, j) {
      return i + j.monthly_payment;
    }, 0);

  // console.log(
  //   paymentType === "Previous_Payment"
  //     ? totalInterestDetails.toFixed(3)
  //     : totalPaymentDetailsAdjustment.toFixed(3)
  // );

  console.log("tableData::", tableData);

  return (
    <Dialog
      open={open}
      className="print-loan-approve"
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("preview-loan-list")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <div ref={componentRef}>
          <div className="print-loan-container" style={{ marginTop: "30px" }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box className="logo-container">
                  <img src={logoCompany} className="logo-company" />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" justifyContent="center">
                  <Box>
                    <div className="summary-center-kh">
                      ព្រះរាជាណាចក្រកម្ពុជា
                    </div>
                    <div className="summary-center-kh">
                      ជាតិ សាសនា ព្រះមហាក្សត្រ
                    </div>
                    <div className="tackteng-font">
                      <img
                        alt="Image"
                        src={wingdingImage}
                        className="image-tackteng"
                      />
                    </div>
                  </Box>
                </Stack>
                <Box className="body-title-print" sx={{ marginTop: "60px" }}>
                  តារាងបង់ប្រាក់
                </Box>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>

            <Grid container>
              <Grid item xs={4}>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ឈ្មោះអតិថិជន៖</Box>
                  <Box
                    className="loan-header-item"
                    style={{ color: "#3c64f6" }}
                  >
                    {tableData?.customer_id?.first_name +
                      " " +
                      tableData?.customer_id?.last_name}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">លេខទូរស័ព្ទអតិថិជន៖</Box>
                  <Box
                    className="loan-header-item"
                    style={{ color: "#3c64f6" }}
                  >
                    {getKhmerNumber(
                      getFormattedPhoneNum(tableData?.customer_id?.phone_number)
                    )}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">
                    លេខទូរស័ព្ទអ្នករួមខ្ចី៖
                  </Box>
                  {tableData?.borrower_together?.borrower_together_id ===
                  null ? (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    ></Box>
                  ) : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {getKhmerNumber(
                        getFormattedPhoneNum(
                          tableData?.borrower_together?.borrower_together_id
                            ?.phone_number
                        )
                      )}
                    </Box>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">លេខទូរស័ព្ទអ្នកធានា៖</Box>
                  {tableData?.loan_guarantor?.loan_guarantor_id ===
                  null ? null : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {getKhmerNumber(
                        getFormattedPhoneNum(
                          tableData?.loan_guarantor?.loan_guarantor_id
                            ?.phone_number
                        )
                      )}
                    </Box>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ការផាកពិន័យ៖</Box>
                  <Box className="loan-header-item"></Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ការបង់ផ្តាច់មុន៖</Box>
                  <Box className="loan-header-item"></Box>
                </Stack>
              </Grid>
              <Grid item xs={3.8}>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">លេខឥណទាន៖</Box>
                  <Box
                    className="loan-header-item"
                    style={{ color: "#3c64f6" }}
                  >
                    {tableData?.loan_id}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ឈ្មោះក្រុមហ៊ុន៖</Box>
                  <Box
                    className="loan-header-item"
                    style={{ color: "#3c64f6" }}
                  >
                    {tableData?.customer_id?.company_name}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ថ្ងៃខ្ចីប្រាក់៖</Box>
                  {paymentType === "Previous_Payment" ? (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {`${getKhmerNumber(
                        moment(tableData?.loan_date).utc().format("DD")
                      )} ${getKhmerMonth(
                        moment(tableData?.loan_date).utc().format("M")
                      )} ${getKhmerNumber(
                        moment(tableData?.loan_date).utc().format("YYYY")
                      )}`}
                    </Box>
                  ) : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {`${getKhmerNumber(
                        moment(tableData?.adjustment?.loan_date)
                          .utc()
                          .format("DD")
                      )} ${getKhmerMonth(
                        moment(tableData?.adjustment?.loan_date)
                          .utc()
                          .format("M")
                      )} ${getKhmerNumber(
                        moment(tableData?.adjustment?.loan_date)
                          .utc()
                          .format("YYYY")
                      )}`}
                    </Box>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ថ្ងៃបង់ដំបូង៖</Box>
                  {paymentType === "Previous_Payment" ? (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.first_month_date === null
                        ? ""
                        : `${getKhmerNumber(
                            moment(tableData?.first_month_date)
                              .utc()
                              .format("DD")
                          )} ${getKhmerMonth(
                            moment(tableData?.first_month_date)
                              .utc()
                              .format("M")
                          )} ${getKhmerNumber(
                            moment(tableData?.first_month_date)
                              .utc()
                              .format("YYYY")
                          )}`}
                    </Box>
                  ) : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.adjustment?.first_month_date === null
                        ? ""
                        : `${getKhmerNumber(
                            moment(tableData?.adjustment?.first_month_date)
                              .utc()
                              .format("DD")
                          )} ${getKhmerMonth(
                            moment(tableData?.adjustment?.first_month_date)
                              .utc()
                              .format("M")
                          )} ${getKhmerNumber(
                            moment(tableData?.adjustment?.first_month_date)
                              .utc()
                              .format("YYYY")
                          )}`}
                    </Box>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ថ្ងៃបង់ចុងក្រោយ៖</Box>
                  {paymentType === "Previous_Payment" ? (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.last_payment_date === null
                        ? ""
                        : `${getKhmerNumber(
                            moment(tableData?.last_payment_date)
                              .utc()
                              .format("DD")
                          )} ${getKhmerMonth(
                            moment(tableData?.last_payment_date)
                              .utc()
                              .format("M")
                          )} ${getKhmerNumber(
                            moment(tableData?.last_payment_date)
                              .utc()
                              .format("YYYY")
                          )}`}
                    </Box>
                  ) : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.adjustment?.last_month_date === null
                        ? ""
                        : `${getKhmerNumber(
                            moment(tableData?.adjustment?.last_month_date)
                              .utc()
                              .format("DD")
                          )} ${getKhmerMonth(
                            moment(tableData?.adjustment?.last_month_date)
                              .utc()
                              .format("M")
                          )} ${getKhmerNumber(
                            moment(tableData?.adjustment?.last_month_date)
                              .utc()
                              .format("YYYY")
                          )}`}
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={4.2}>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ចំនួនទឹកប្រាក់កម្ចី៖</Box>
                  {paymentType === "Previous_Payment" ? (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      $ {tableData?.loan_amount.toFixed(3)}
                    </Box>
                  ) : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      $ {tableData?.adjustment?.amount_adjustment.toFixed(3)}
                    </Box>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">អត្រាការប្រាក់៖</Box>
                  {paymentType === "Previous_Payment" ? (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.loan_plan?.interest} %
                    </Box>
                  ) : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.adjustment?.interest_adjustment * 100} %
                    </Box>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">រយៈពេលនៃការខ្ចី៖</Box>
                  {paymentType === "Previous_Payment" ? (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.loan_plan?.month} ខែ
                    </Box>
                  ) : (
                    <Box
                      className="loan-header-item"
                      style={{ color: "#3c64f6" }}
                    >
                      {tableData?.adjustment?.duration_adjustment} ខែ
                    </Box>
                  )}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">ឈ្មោះមន្ត្រីឥណទាន៖</Box>
                  <Box className="loan-header-item"></Box>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Box className="loan-header-title">លេខទូរស័ព្ទCO៖</Box>
                  <Box className="loan-header-item"></Box>
                </Stack>
              </Grid>
            </Grid>
            {/* <Box className="loan-contact-info">
              លេខវេរលុយ៖ 010 537 694 /គណនីABA៖ Khov Soukheng កុងដុល្លារ 002 856
              713 កុងខ្មែរ 001 895 527
            </Box> */}
            <div className="loan-table-container">
              <table className="loan-table">
                <tr style={{ backgroundColor: "#eaeefa" }}>
                  <th className="no-loan">ល.រ</th>
                  <th className="date-loan">ថ្ងៃបង់ប្រាក់</th>
                  <th className="amount-loan">ប្រាក់ដើម</th>
                  <th className="interest-loan">ការប្រាក់</th>
                  <th className="totalpayment-loan">សំណងសរុប</th>
                  <th className="restofmoney-loan">ប្រាក់ចុងគ្រា</th>
                  <th className="daterecieve-loan">ថ្ងៃទទួល</th>
                  <th className="gocredit-loan">ហ្គោក្រេឌីត</th>
                  <th className="others-loan">ផ្សេងៗ</th>
                </tr>
                <tr>
                  <th
                    className="no-loan-total"
                    colSpan={2}
                    style={{ color: "red" }}
                  >
                    សរុប
                  </th>
                  <th className="date-loan-total" style={{ color: "red" }}>
                    ${" "}
                    {paymentType === "Previous_Payment"
                      ? tableData?.loan_amount.toFixed(3)
                      : tableData?.adjustment?.amount_adjustment.toFixed(3)}
                  </th>
                  <th className="amount-loan-total" style={{ color: "red" }}>
                    ${" "}
                    {paymentType === "Previous_Payment"
                      ? totalInterestDetails.toFixed(3)
                      : totalInterestDetailsAdjustment.toFixed(3)}
                  </th>
                  <th className="interest-loan-total" style={{ color: "red" }}>
                    ${" "}
                    {paymentType === "Previous_Payment"
                      ? totalPaymentDetails.toFixed(3)
                      : totalPaymentDetailsAdjustment.toFixed(3)}
                  </th>
                  <th className="totalpayment-loan"></th>
                  <th className="restofmoney-loan"></th>
                  <th className="daterecieve-loan"></th>
                  <th className="gocredit-loan"></th>
                </tr>
                {paymentType === "Previous_Payment" ? (
                  <>
                    {tableData?.payment_detail?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className="first-col">{index + 1}</td>
                          <td className="first-col">
                            {`${getKhmerDay(
                              moment(data?.payment_date).utc().format("dddd")
                            )}. ${getKhmerNumber(
                              moment(data?.payment_date).utc().format("DD")
                            )} ${getKhmerMonth(
                              moment(data?.payment_date).utc().format("M")
                            )} ${getKhmerNumber(
                              moment(data?.payment_date).utc().format("YY")
                            )}`}
                          </td>
                          <td className="first-col">
                            $ {data?.principal.toFixed(3)}
                          </td>
                          <td className="first-col">
                            $ {data?.interest?.toFixed(3)}
                          </td>
                          <td className="first-col">
                            ${" "}
                            {data?.monthly_payment === null
                              ? "$0.00"
                              : data?.monthly_payment.toFixed(3)}
                          </td>
                          <td className="first-col">
                            ${" "}
                            {data?.rest_of_money === null
                              ? "$0.00"
                              : data?.rest_of_money.toFixed(3)}
                          </td>
                          {data?.receipt_date === null ? (
                            <td className="first-col"></td>
                          ) : (
                            <td className="first-col">
                              {`${getKhmerDay(
                                moment(data?.receipt_date).utc().format("dddd")
                              )}. ${getKhmerNumber(
                                moment(data?.receipt_date).utc().format("DD")
                              )} ${getKhmerMonth(
                                moment(data?.receipt_date).utc().format("M")
                              )} ${getKhmerNumber(
                                moment(data?.receipt_date).utc().format("YY")
                              )}`}
                            </td>
                          )}
                          <td className="first-col"></td>
                          <td className="first-col"></td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {tableData?.adjustment?.adjustment_payment?.map(
                      (data, index) => {
                        return (
                          <tr key={index}>
                            <td className="first-col">{index + 1}</td>
                            <td className="first-col">
                              {`${getKhmerDay(
                                moment(data?.payment_date).utc().format("dddd")
                              )}. ${getKhmerNumber(
                                moment(data?.payment_date).utc().format("DD")
                              )} ${getKhmerMonth(
                                moment(data?.payment_date).utc().format("M")
                              )} ${getKhmerNumber(
                                moment(data?.payment_date).utc().format("YY")
                              )}`}
                            </td>
                            <td className="first-col">
                              $ {data?.principal.toFixed(3)}
                            </td>
                            <td className="first-col">
                              $ {data?.interest?.toFixed(3)}
                            </td>
                            <td className="first-col">
                              ${" "}
                              {data?.monthly_payment === null
                                ? "$0.00"
                                : data?.monthly_payment.toFixed(3)}
                            </td>
                            <td className="first-col">
                              ${" "}
                              {data?.rest_of_money === null
                                ? "$0.00"
                                : data?.rest_of_money.toFixed(3)}
                            </td>
                            {data?.receipt_date === null ? (
                              <td className="first-col"></td>
                            ) : (
                              <td className="first-col">
                                {`${getKhmerDay(
                                  moment(data?.receipt_date)
                                    .utc()
                                    .format("dddd")
                                )}. ${getKhmerNumber(
                                  moment(data?.receipt_date).utc().format("DD")
                                )} ${getKhmerMonth(
                                  moment(data?.receipt_date).utc().format("M")
                                )} ${getKhmerNumber(
                                  moment(data?.receipt_date).utc().format("YY")
                                )}`}
                              </td>
                            )}
                            <td className="first-col"></td>
                            <td className="first-col"></td>
                          </tr>
                        );
                      }
                    )}
                  </>
                )}
              </table>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
                  moment().format("DD")
                )} ខែ${getKhmerMonth(
                  moment().format("M")
                )} ឆ្នាំ${getKhmerNumber(moment().format("YYYY"))}`}</div>
                <div className="loan-footer-name">អតិថិជន</div>
                <div className="loan-footer-name" style={{ marginTop: "80px" }}>
                  {tableData?.customer_id?.first_name +
                    " " +
                    tableData?.customer_id?.last_name}
                </div>
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "10px" }}>
                <div className="loan-footer-title">{khmerDateString}</div>
                <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
                  moment().format("DD")
                )} ខែ${getKhmerMonth(
                  moment().format("M")
                )} ឆ្នាំ${getKhmerNumber(moment().format("YYYY"))}`}</div>
                <div className="loan-footer-name">អ្នកធ្វើតារាង</div>
                <div
                  className="loan-footer-name"
                  style={{ marginTop: "80px" }}
                ></div>
              </Grid>
            </Grid>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Box className="box-action">
          <Grid container>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Button className="btn-print" fullWidth onClick={handalePrint}>
                Print
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
