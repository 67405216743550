import { gql } from "@apollo/client";

export const CREATE_SOURCE_OF_MONEY = gql`
  mutation CreateSourceOfMoney($input: SourceOfMoneyInput) {
    createSourceOfMoney(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_SOURCE_OF_MONEY_PAGINATION = gql`
  query GetSourceOfMoneyWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
  ) {
    getSourceOfMoneyWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
    ) {
      data {
        _id
        description
        title
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_SOURCE_OF_MONEY = gql`
  mutation UpdateSourceOfMoney($id: ID!, $input: SourceOfMoneyInput) {
    updateSourceOfMoney(_id: $id, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_SOURCE_MONEY = gql`
  mutation DeleteSourceOfMoney($id: ID!) {
    deleteSourceOfMoney(_id: $id) {
      isSuccess
      message
    }
  }
`;
