import React, { useContext, useState, useEffect } from "react";
import "./updateusers.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useMutation } from "@apollo/client";

//Schema
import { UPDATE_USERS } from "../../../Schema/Users";
import { AuthContext } from "../../../context/AuthContext";
//upload image
import CropImageFile from "../../CropImage/CropImageFile";
import { translateLauguage } from "../../../Function/Translate";

export default function UpdateUsers({
  open,
  handleClose,
  editRow,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { setAlert } = useContext(AuthContext);

  //hiden and show vibility
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = () => setShow(!show);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [profileName, setProfileName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  // console.log("editRow::", editRow);

  useEffect(() => {
    if (editRow) {
      setProfileHook(editRow?.image_src);
      setProfileName(editRow?.image_name);
      setFieldValue("firsName", editRow?.firsName);
      setFieldValue("lastName", editRow?.lastName);
      setFieldValue("phone_number", editRow?.phone_number);
      setFieldValue("email", editRow?.email);
      setFieldValue("role", editRow?.role);
    }
  }, [editRow]);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  //Update user
  const [updateUser] = useMutation(UPDATE_USERS, {
    onCompleted: ({ updateUser }) => {
      // console.log("updateUser::", updateUser);
      if (updateUser?.isSuccess) {
        setLoading(false);
        setAlert(true, "success", updateUser?.message);
        handleClose();
        setRefetch();
      } else {
        console.log(updateUser);
        handleClose();
        setLoading(false);
        setAlert(true, "error", updateUser?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });
  //end

  //fromik
  const AddLoanPlan = Yup.object().shape({
    firsName: Yup.string().required("require!"),
    lastName: Yup.string().required("require!"),
    phone_number: Yup.string(),
    email: Yup.string().email("Invalid email!").required("required email!"),
    role: Yup.string().required("choose"),
  });

  const formik = useFormik({
    initialValues: {
      firsName: "",
      lastName: "",
      phone_number: "",
      email: "",
      role: "Admin",
    },

    validationSchema: AddLoanPlan,
    onSubmit: async (values) => {
      // console.log(values, "value");
      setLoading(true);
      if (imageFile) {
        updateUser({
          variables: {
            userId: editRow?._id,
            input: {
              ...values,
              image_src: profileHook,
              image_name: "customer profile",
            },
          },
        });
      } else {
        updateUser({
          variables: {
            userId: editRow?._id,
            input: {
              ...values,
              image_src: profileHook,
              image_name: profileName,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  // console.log("imageFile::", imageFile);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="update-user">
        <DialogTitle>
          <Stack direction="row">
            <Typography
              className={
                language === "kh" ? "dialog-titleKh" : "dialog-titleEn"
              }
            >
              {t("update") + " " + t("users")}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <ClearIcon className="close-icon" />
            </IconButton>
          </Stack>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12} className="grid-profile">
                  {!openCrop ? (
                    <Box>
                      <Stack
                        className="profile"
                        direction="row"
                        justifycontent="center"
                      >
                        <IconButton component="label">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <Avatar
                            className="avater-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editRow?.image_src === ""
                                ? null
                                : editRow?.image_src
                            }
                          />
                          <input type="file" hidden />
                        </IconButton>
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ mt: 1 }}
                      >
                        <Typography
                          className={language === "kh" ? "text-Kh" : "text-En"}
                        >
                          {t("profile")}
                        </Typography>
                      </Stack>
                    </Box>
                  ) : (
                    <CropImageFile
                      setImageFile={setImageFile}
                      photoURL={photoURL}
                      setOpenCrop={setOpenCrop}
                      setPhotoURL={setPhotoURL}
                      setUploadHook={setProfileHook}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography className={language === "kh" ? "subKh" : "subEn"}>
                    {t("firstname")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    placeholder="firsName"
                    {...getFieldProps("firsName")}
                    error={Boolean(touched.firsName && errors.firsName)}
                    helperText={touched.firsName && errors.firsName}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography className={language === "kh" ? "subKh" : "subEn"}>
                    {t("lastname")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    placeholder="lastName"
                    {...getFieldProps("lastName")}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography className={language === "kh" ? "subKh" : "subEn"}>
                    {t("role")}
                  </Typography>

                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      type="text"
                      {...getFieldProps("role")}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Manager">Manager</MenuItem>
                      <MenuItem value="CO">CO</MenuItem>
                      <MenuItem value="Teller">Teller</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <Typography className={language === "kh" ? "subKh" : "subEn"}>
                    {t("phone")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="phone"
                    {...getFieldProps("phone_number")}
                    error={Boolean(touched.phone_number && errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className={language === "kh" ? "subKh" : "subEn"}>
                    Email
                  </Typography>
                  <Stack>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>

        <DialogActions>
          <Box className="action">
            <Grid container>
              <Grid item xs={12}>
                {loading ? (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    className={
                      language === "kh" ? "btn-actionKh" : "btn-actionEn"
                    }
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {t("update")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
