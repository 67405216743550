import React from "react";
import { Box, Card, Stack, Tooltip, CardMedia } from "@mui/material";
import { AuthContext } from "../../../../context/AuthContext";
import { translateLauguage } from "../../../../Function/Translate";

export default function MediaCard({ e }) {
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };
  const LongTextComponent = ({ text }) => {
    return (
      <Tooltip title={text}>
        <div
          style={{
            width: "100%",
            height: "3em", // Ensures the height aligns with the truncated lines
            overflow: "hidden", // Ensures content beyond the height is clipped
            display: "-webkit-box", // Enables the use of -webkit-line-clamp
            WebkitBoxOrient: "vertical", // Defines the box orientation
            WebkitLineClamp: 2, // Limits to 2 lines
            lineHeight: "1.5em", // Ensures proper line spacing
            textOverflow: "ellipsis", // Adds the ellipsis for overflowed text
            whiteSpace: "normal", // Ensures text wrapping
            color: "gray",
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          {text}
        </div>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        width: 220,
        height: 260,
        padding: 1,
        marginBottom: 7,
      }}
    >
      <Stack spacing={1}>
        <Card sx={{ width: "100%", position: "relative" }}>
          <CardMedia
            image={e?.document}
            title={e?.document}
            sx={{
              height: 260,
              cursor: "pointer",
            }}
            onClick={() => openLinkInNewTab(e?.document)}
          />
        </Card>

        <LongTextComponent text={e?.title} />
      </Stack>
    </Box>
  );
}
