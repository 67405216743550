import React, { useState, useEffect } from "react";
import { Grid, Stack, Box, Avatar, Typography, TextField } from "@mui/material";

import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

import { useQuery } from "@apollo/client";

import { GET_ALL_LOAN_TYPE } from "../../Schema/Dashboard";

import LoanCard from "../../Component/NewDashboard/LoanCard/LoanCard";
import PawnCard from "../../Component/NewDashboard/PawnCard/PawnCard";
import Installment from "../../Component/NewDashboard/Installment/Installment";
import { SelectLoanTypeHasAll } from "../../Function/OwnComponents";

import "./dashboardnew.scss";
import CashInAndOutChart from "../../Component/NewDashboard/CashInAndOutChart/CashInAndOutChart";
import CustomerStatisticForChart from "../../Component/NewDashboard/CustomerStatisticForChart/CustomerStatisticForChart";

//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

// select
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function NewDashboard() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [dateSelected, setDateSelected] = useState(moment());
  const [selectedValue, setSelectedValue] = useState({ id: "", title: "" });
  const [monthVal, setMonthVal] = useState();

  const [type, setType] = React.useState("All");

  const handleTypeLoan = (event) => {
    setType(event.target.value);
  };

  return (
    <div className="dashboard-new">
      <Stack
        direction="row"
        spacing={2}
        sx={{ height: "30px" }}
        alignItems={`center`}
      >
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("dashboard")}
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
      </Stack>

      <Grid container spacing={2} className="container">
        <Grid item xs={12} sm={8} md={6} lg={4} xl={2.4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year"]}
              value={dateSelected}
              onChange={(e) => setDateSelected(e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="text-field"
                  size="small"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={2.4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["month"]}
              value={monthVal}
              onChange={(e) => setMonthVal(e)}
              inputFormat="MMMM"
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="text-field"
                  size="small"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={4} xl={2.4}>
          <FormControl fullWidth className="text-field">
            <Select size="small" value={type} onChange={handleTypeLoan}>
              <MenuItem value={"All"}>{t("all")}</MenuItem>
              <MenuItem value={"Loan"}>{t("loan")}</MenuItem>
              <MenuItem value={"Pawn"}>{t("pawn")}</MenuItem>
              <MenuItem value={"Installments"}>{t("installment")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <LoanCard selectedValue={selectedValue} dateSelected={dateSelected} type={type}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CashInAndOutChart monthVal={monthVal} dateSelected={dateSelected} type={type}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <CustomerStatisticForChart dateSelected={dateSelected} type={type}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
      </Grid>
    </div>
  );
}
