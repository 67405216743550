import React, { useContext, useState } from "react";
import "./addusers.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Grid,
  Stack,
  Box,
  Avatar,
  TextField,
  Divider,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { CREATE_USERS } from "../../../Schema/Users";
import { AuthContext } from "../../../context/AuthContext";
//upload image
import CropImageFile from "../../CropImage/CropImageFile";

export default function AddUsers({
  open,
  handleClose,
  setRefetch,
  t,
  language,
}) {
  const { setAlert } = useContext(AuthContext);

  //hiden and show vibility
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = () => setShow(!show);

  // upload Image
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImageFile(imageFile);
      setPhotoURL(URL.createObjectURL(imageFile));
      setOpenCrop(true);
    }
  };

  //Create user
  const [createUser, { data, error }] = useMutation(CREATE_USERS, {
    onCompleted: ({ createUser }) => {
      // console.log("user", createUser);
      if (createUser?.isSuccess) {
        handleClose();
        setLoading(false);
        setAlert(true, "success", createUser?.message);
        setRefetch();
      } else {
        console.log(createUser);
        handleClose();
        setLoading(false);
        setAlert(true, "error", createUser?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
      setRefetch();
    },
  });
  //end
  const phoneRegex = RegExp(
    /^(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  );
  //fromik
  const AddLoanPlan = Yup.object().shape({
    firsName: Yup.string().required("require!"),
    lastName: Yup.string().required("require!"),
    phone_number: Yup.string(),
    email: Yup.string().email("Invalid email!").required("required email!"),
    role: Yup.string().required("choose"),
    password: Yup.string()
      .required("required password!")
      .min(6, "input more than 6 character!"),
  });

  const formik = useFormik({
    initialValues: {
      firsName: "",
      lastName: "",
      phone_number: "",
      email: "",
      role: "Admin",
      password: "",
    },

    validationSchema: AddLoanPlan,
    onSubmit: async (values) => {
      setLoading(true);
      // console.log("values:::", values);
      if (imageFile) {
        createUser({
          variables: {
            input: {
              ...values,
              image_src: profileHook,
              image_name: "customer profile",
            },
          },
        });
      } else {
        createUser({
          variables: {
            input: {
              ...values,
              image_src: "",
              image_name: "",
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <Dialog open={open} className="create-user" fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("add-user")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} className="grid-profile">
                {!openCrop ? (
                  <Box>
                    <Stack
                      className="profile"
                      direction="row"
                      justifycontent="center"
                    >
                      <IconButton component="label">
                        <TextField
                          type="file"
                          id="image"
                          sx={{ display: "none" }}
                          onChange={handleUploadImage}
                        />
                        <Avatar
                          className="avater-image"
                          src={
                            imageFile ? URL.createObjectURL(imageFile) : null
                          }
                        />
                        <input type="file" hidden />
                      </IconButton>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ mt: 1 }}
                    >
                      <Typography
                        className={language === "kh" ? "text-Kh" : "text-En"}
                      >
                        {t("profile")}
                      </Typography>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    setImageFile={setImageFile}
                    photoURL={photoURL}
                    setOpenCrop={setOpenCrop}
                    setPhotoURL={setPhotoURL}
                    setUploadHook={setProfileHook}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("firstname")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  placeholder="firsName"
                  {...getFieldProps("firsName")}
                  error={Boolean(touched.firsName && errors.firsName)}
                  helperText={touched.firsName && errors.firsName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("lastname")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  placeholder="lastName"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("role")}
                </Typography>

                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    type="text"
                    {...getFieldProps("role")}
                    // onChange={(event) => {
                    //   setFieldValue(event.target.value)
                    // }}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                    <MenuItem value="CO">CO</MenuItem>
                    <MenuItem value="Teller">Teller</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("phone")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="phone"
                  {...getFieldProps("phone_number")}
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("email")}
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    size="small"
                    type="email"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("password")}
                </Typography>
                <TextField
                  fullWidth
                  type={show ? "text" : "password"}
                  size="small"
                  placeholder="password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon className="icon" />
                        ) : (
                          <VisibilityOffIcon className="icon" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Box className="action">
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={
                    language === "kh" ? "btn-actionKh" : "btn-actionEn"
                  }
                  fullWidth
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={
                    language === "kh" ? "btn-actionKh" : "btn-actionEn"
                  }
                  fullWidth
                  onClick={handleSubmit}
                >
                  {t("create")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
