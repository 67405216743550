import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./loanapprovedetails.scss";
import {
  Stack,
  Box,
  Button,
  Grid,
  Typography,
  Link,
  Badge,
  IconButton,
} from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

import ReplyAllIcon from "@mui/icons-material/ReplyAll";
//components
import LoanApproveDetailsList from "./LoanApproveDetailsList";
import LoanRequestDetails from "../LoanRequestDetails/LoanRequestDetails";

export default function LoanApproveDetails({ tableData, setRefetch }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pageType, setPageType] = useState("loan-list-page");

  // console.log("tableData::", tableData)
  const dataType = window.localStorage.getItem("menuType");

  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  useEffect(() => {
    if (tableData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);

  return (
    <div className="loan-approve-details">
      <Grid container>
        <Grid item xs={12} md={6} sx={{ marginTop: "10px" }}>
          <Stack direction="row" spacing={2} alignItems="center">
            {dataType === "loan-req" ? (
              <IconButton onClick={() => navigate("/loanlist")}>
                <ReplyAllIcon style={{ color: "#38b14a" }} />
              </IconButton>
            ) : (
              <IconButton onClick={() => navigate("/payments")}>
                <ReplyAllIcon style={{ color: "#38b14a" }} />
              </IconButton>
            )}

            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {dataType === "loan-req" ? (
                <Typography
                  className={language === "kh" ? "titleKh" : "titleEn"}
                >
                  <Link
                    className="link-topage"
                    onClick={() => navigate("/loanlist")}
                  >
                    {t("req-loan")}
                  </Link>
                </Typography>
              ) : dataType === "loan-list" ? (
                <Typography
                  className={language === "kh" ? "titleKh" : "titleEn"}
                >
                  <Link
                    className="link-topage"
                    onClick={() => navigate("/payments")}
                  >
                    {t("loan list")}
                  </Link>
                </Typography>
              ) : (
                "Undefined"
              )}

              <Typography className="titleKh">/</Typography>
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                {t("loan approve")}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginTop: "10px" }}>
          <Grid item container spacing={3}>
            <Grid item xs={6}>
              <Button
                onClick={() => setPageType("loan-list-page")}
                sx={{
                  bgcolor: pageType === "loan-list-page" ? "#0F81C2" : "#fff",
                  color: pageType === "loan-list-page" ? "#fff" : "#0F81C2",
                }}
                className="button-status"
              >
                {t("loan-page")}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={() => setPageType("agreement-page")}
                sx={{
                  bgcolor: pageType === "agreement-page" ? "#38b14a" : "#fff",
                  color: pageType === "agreement-page" ? "#fff" : "#38b14a",
                }}
                className="button-status"
              >
                {t("agreement-page")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: "10px" }}>
        {pageType === "loan-list-page" ? (
          <LoanApproveDetailsList
            loading={loading}
            tableData={tableData}
            setRefetch={setRefetch}
          />
        ) : (
          <LoanRequestDetails tableData={tableData} />
        )}
      </Box>
    </div>
  );
}
