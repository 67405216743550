import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { MdGppBad } from "react-icons/md";
import { MdGppGood } from "react-icons/md";
// Style
import "../../../Style/actionStyle.scss";
// src
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import UpdateLoanlist from "../UpdateLoanlist/UpdateLoanlist";
import UpdateInstallment from "../UpdateInstallment/UpdateInstallment";
import DeleteLoanlist from "../DeleteLoanlist/DeleteLoanlist";

import RejectLoan from "./RejectLoan";
import FirstApproveLoan from "./ApproveLoan";

export default function LoanListNewAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  const role = JSON.parse(window.localStorage.getItem("userLogin"))?.role;

  // console.log("roleUser==>", role);
  //Modal update
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  //Modal First Approve
  const [openFirstApprove, setOpenFirstApprove] = useState(false);
  const handleOpenFirstApprove = () => setOpenFirstApprove(true);
  const handleCloseFirstApprove = () => setOpenFirstApprove(false);

  //Modal reject
  const [openReject, setOpenReject] = useState(false);
  const handleOpenReject = () => setOpenReject(true);
  const handleCloseReject = () => setOpenReject(false);
  // <MenuItem value="Manager">Manager</MenuItem>
  // <MenuItem value="Admin">Admin</MenuItem>
  // <MenuItem value="Teller">Teller</MenuItem>
  // <MenuItem value="CO">CO</MenuItem>

  // console.log(
  //   "CO:::::>",
  //   noneUserAccesse(["CO"]) && editData?.status === "Request"
  // );
  // console.log(
  //   "Manager:::::>",
  //   noneUserAccesse(["Manager"]) && editData?.status === "FirstApprove"
  // );
  return (
    <div>
      {editData?.active === "Voided" ||
      editData?.active === "Completed" ||
      editData?.status === "Reject" ? null : (
        <>
          <Stack direction="row" justifyContent="right">
            {role === "CO" && editData?.status === "Request" && (
              <>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("approve")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenFirstApprove}>
                    <Box className="approve-container">
                      <MdGppGood className="approve-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("reject")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenReject}>
                    <Box className="reject-container">
                      <MdGppBad className="reject-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
              </>
            )}

            {((role === "Manager" && editData?.status === "FirstApprove") ||
              role === "Admin") && (
              <>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("approve")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenFirstApprove}>
                    <Box className="approve-container">
                      <MdGppGood className="approve-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("reject")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenReject}>
                    <Box className="reject-container">
                      <MdGppBad className="reject-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
              </>
            )}

            {editData?.status === "Request" && role !== "Manager" ? (
              <Tooltip
                title={<span style={{ fontSize: "13px" }}>{t("update")}</span>}
                placement="top"
              >
                <IconButton onClick={handleOpenEdit}>
                  <Box className="update-container">
                    <CreateIcon className="update-icon" />
                  </Box>
                </IconButton>
              </Tooltip>
            ) : null}
            {editData?.status === "Request" && role !== "Manager" ? (
              <Tooltip
                title={<span style={{ fontSize: "13px" }}>{t("delete")}</span>}
                placement="top"
              >
                <IconButton onClick={handleOpenDel}>
                  <Box className="delete-container">
                    <DeleteIcon className="delete-icon" />
                  </Box>
                </IconButton>
              </Tooltip>
            ) : null}
          </Stack>
        </>
      )}

      {editData?.type_of_loan === "Installments" ? (
        <UpdateInstallment
          open={openEdit}
          handleClose={handleCloseEdit}
          editData={editData}
          setRefetch={setRefetch}
        />
      ) : (
        <UpdateLoanlist
          open={openEdit}
          handleClose={handleCloseEdit}
          editData={editData}
          setRefetch={setRefetch}
        />
      )}

      <DeleteLoanlist
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />

      <FirstApproveLoan
        open={openFirstApprove}
        handleClose={handleCloseFirstApprove}
        editData={editData}
        setRefetch={setRefetch}
      />

      <RejectLoan
        open={openReject}
        handleClose={handleCloseReject}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
