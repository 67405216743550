import React, { useContext, useState } from "react";
import "./deletecustomers.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField, Divider } from "@mui/material";

import { DELETE_CUSTOMER } from "../../../Schema/Customers";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function DeleteCustomers({
  open,
  handleClose,
  editRow,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [valueVoid, setValueVoid] = React.useState("");
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  //Create user
  const [deleteCustomer, { data, error }] = useMutation(DELETE_CUSTOMER, {
    onCompleted: ({ deleteCustomer }) => {
      if (deleteCustomer?.isSuccess) {
        handleClose();
        setLoading(false);
        setAlert(true, "success", deleteCustomer?.message);
        setRefetch();
      } else {
        // console.log(deleteCustomer);
        handleClose();
        setLoading(false);
        setAlert(true, "error", deleteCustomer?.message);
      }
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });
  //end

  const handleDelete = () => {
    setLoading(true);
    deleteCustomer({
      variables: {
        deleteCustomerId: editRow?._id,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="customer-delete"
      // aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2}>
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn "}
          >
            {t("delete") + " " + t("customer")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography
            variant="subtitle1"
            className={language === "kh" ? "simple-textKh" : "simple-textEn"}
          >
            {t("do-you-want-to-delete-customer")}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Typography
              variant="subtitle1"
              className={language === "kh" ? "simple-textKh" : "simple-textEn"}
            >
              {t("please-input")}
            </Typography>
            <Typography className="body-text" variant="subtitle1">
              CUSTOMER
            </Typography>
            <Typography
              variant="subtitle1"
              className={language === "kh" ? "simple-textKh" : "simple-textEn"}
            >
              {t("to-delete")}
            </Typography>
          </Stack>
          <TextField
            fullWidth
            size="small"
            type="text"
            onChange={(e) => setValueVoid(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="action">
              {valueVoid === "CUSTOMER" ? (
                <Button
                  sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
                  className={language === "kh" ? "btn-voidKh" : "btn-voidEn"}
                  variant="outlined"
                  onClick={handleDelete}
                  fullWidth
                >
                  {t("delete-now")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={
                    language === "kh" ? "btn-deleteKh" : "btn-deleteEn"
                  }
                  fullWidth
                >
                  {t("delete")}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
