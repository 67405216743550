import React, { useState, useEffect } from "react";
import "./loanplans.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
//Components
import AddLoanPlans from "../Component/LoanPlans/AddLoanPlans/AddLoanPlans";
import LoanPlansAction from "../Component/LoanPlans/LoanPlansAction/LoanPlansAction";
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
//Schema
import { GET_LOANPLAN_WITH_PAGINATION } from "../Schema/Loanplan";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";

export default function LoanPlans() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [tableData, setTableData] = useState([]);
  const [typeOfPaymentPeriod, setTypeOfPaymentPeriod] = useState("Month");

  const { refetch } = useQuery(GET_LOANPLAN_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: "",
      pagination: true,
      typeOfPaymentPeriod: typeOfPaymentPeriod,
    },
    onCompleted: ({ getLoanPlanByPagination }) => {
      setTableData(getLoanPlanByPagination);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  //console.log("tableData::=>", tableData);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="loanplan-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/setting">
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                {t(`setting`)}
              </Typography>
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="slash-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("loan plan")}
          </Typography>
        </Stack>
        </Stack>
        

        <Box sx={{ flexGrow: 1 }} />
      </Stack>
      <Box sx={{ marginTop: "15px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={`right`} alignItems={`center`} spacing={2}>
              <Box className="box-marker">
                <Stack direction="column" sx={{ width: "300px" }}>
                  <FormControl size="small" fullWidth className="text-field">
                    <Select
                      size="small"
                      value={typeOfPaymentPeriod}
                      onChange={(e) => setTypeOfPaymentPeriod(e.target.value)}
                    >
                      <MenuItem value={"Month"}>{t("months")}</MenuItem>
                      <MenuItem value={"Week"}>{t("week")}</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Box>

              <Stack direction="column" justifyContent="center" spacing={2}>
                <Button
                  onClick={handleOpen}
                  className={language === "kh" ? "btn-addKh" : "btn-addEn"}
                  startIcon={<AddIcon className="icon-add" />}
                >
                  {t("create")}
                </Button>
              </Stack>

              <AddLoanPlans
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
                dialogTitle={"Create"}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="container">
        <TableContainer sx={{ maxWidth: `${width}px`, whiteSpace: "nowrap" }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row">
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="center"
              >
                {typeOfPaymentPeriod === "Month" ? t("months") : t("week")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="center"
              >
                {t("interest/month")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="center"
              >
                {t("over-due-penalty/month")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="center"
              >
                {t("note")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                align="center"
              ></TableCell>
            </TableHead>

            {loading ? (
              <TableHead className="header-row">
                <TableRow>
                  <TableCell
                    colSpan={5}
                    className={
                      language === "kh" ? "header-titleKh" : "header-titleEn"
                    }
                  >
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <>
                {tableData?.data?.length === 0 ? (
                  <TableHead className="header-row">
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        className={
                          language === "kh"
                            ? "header-titleKh"
                            : "header-titleEn"
                        }
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          height={260}
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <TableBody className="body">
                    {tableData?.data?.map((row, index) => (
                      <TableRow className="body-row" key={index}>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cell-startKh"
                              : "body-cell-startEn"
                          }
                        >
                          {index + tableData?.paginator?.slNo}-
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          align="center"
                        >
                          {row?.month}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          align="center"
                        >
                          {row?.interest}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          align="center"
                        >
                          {row?.over_due_penalty}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          align="center"
                        >
                          {row?.remark}
                        </TableCell>
                        <TableCell className="body-cell-end" align="right">
                          <LoanPlansAction
                            editData={row}
                            setRefetch={refetch}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
