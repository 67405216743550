import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Link,
  Stack,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import "./customerdetail.scss";
import { useQuery } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_CUSTOMER_HISTORY } from "../../../Schema/Customers";
//Component
import LoanInfo from "./LoanInfo";
import CustomerInfo from "./CustomerInfo";
import BusinessInfo from "./BusinessInfo";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import ViewReferenceDocument from "./ViewReferenceDocument/ViewReferenceDocument";
export default function CustomerDetail() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();
  //get Storage Room ID by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tabTaype, setTabType] = useState("Personal-Info");
  const [customerId, setCustomerId] = useState(params.get("id"));

  useEffect(() => {
    setCustomerId(params.get("id"));
  }, [location.search]);

  const { refetch } = useQuery(GET_CUSTOMER_HISTORY, {
    variables: {
      getHistoryByCustomerIdId: customerId,
    },
    onCompleted: ({ getHistoryByCustomerId }) => {
      setTableData(getHistoryByCustomerId);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (tableData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);

  let customerData = tableData?.costomer;

  // console.log("customerData::", customerData);

  return (
    <div className="setup-customerdetails">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        {/* <Stack direction="row" spacing={1}>
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ cursor: "pointer" }}
          >
            <Link
              className={language === "kh" ? "linkKh" : "linkEn"}
              onClick={() => navigate("/customer")}
            >
              {t("customer")}
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              /
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("customer-details")}
            </Typography>
          </Stack>
        </Stack> */}

        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" onClick={() => navigate("/setting")}>
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                {t(`setting`)}
              </Typography>
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="slash-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" onClick={() => navigate("/customer")}>
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                {t("customer")}
              </Typography>
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="slash-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("customer-details")}
            </Typography>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="column" justifyContent="center">
          <Button
            onClick={() =>
              navigate(`/customer/update-customer?id=${customerId}`)
            }
            className={language === "kh" ? "btn-create-Kh" : "btn-create-En"}
          >
            {t("update")}
          </Button>
        </Stack>
      </Stack>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Stack className="profile-container" direction="row" spacing={4}>
            <Avatar
              alt="Remy Sharp"
              className="profile-image"
              src={`${customerData?.image_src}`}
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className="custmer-name">
                  {customerData?.first_name + " " + customerData?.last_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="custmer-info-text">
                  {customerData?.positions}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-text">
                  {t("nickname")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-item">
                  {customerData?.nickname}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-text">
                  {t("phone")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className="custmer-info-item">
                  {customerData?.phone_number}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Grid container className="tabs-container">
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Personal-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Personal-Info")}
          >
            {t("personal-info")}
          </Button>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Business-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Business-Info")}
          >
            {t("business-info")}
          </Button>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Reference-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Reference-Info")}
          >
            {t("reference-info")}
          </Button>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Button
            className={
              tabTaype === "Loan-Info" ? "tabs-btn-active" : "tabs-btn"
            }
            onClick={() => setTabType("Loan-Info")}
          >
            {t("loan-info")}
          </Button>
        </Grid>
      </Grid>

      {tabTaype === "Personal-Info" ? (
        <CustomerInfo customerData={customerData} />
      ) : tabTaype === "Business-Info" ? (
        <BusinessInfo customerData={customerData} />
      ) : tabTaype === "Reference-Info" ? (
        <ViewReferenceDocument customerData={customerData} />
      ) : (
        <LoanInfo tableData={tableData} loading={loading} />
      )}
    </div>
  );
}
