import React, { useState, useContext } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { RiFileEditFill } from "react-icons/ri";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { BiSolidCommentDetail } from "react-icons/bi";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
// Style
import "../../../Style/actionStyle.scss";
// src
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import FinishLoanlist from "../FinishLoanList/FinishLoanlist";
import AdjustmentLoan from "../AdjustmentLoan/AdjustmentLoan";
import SubSpendLoan from "./SupSpendLoan";
import UpdateComment from "./UpdateComment";

export default function LastLoanListNewAction({ editData, setRefetch }) {
  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  const role = JSON.parse(window.localStorage.getItem("userLogin"))?.role;

  //Modal adjustment
  const [openAdjustment, setOpenAdjustment] = useState(false);
  const handleOpenAdjustment = () => setOpenAdjustment(true);
  const handleCloseAdjustment = () => setOpenAdjustment(false);

  //Modal subspend
  const [openSubSpend, setOpenSubSpend] = useState(false);
  const handleOpenSubSpend = () => setOpenSubSpend(true);
  const handleCloseSubSpend = () => setOpenSubSpend(false);

  //Modal finish
  const [openFinish, setOpenFinish] = useState(false);
  const handleOpenFinish = () => setOpenFinish(true);
  const handleCloseFinish = () => setOpenFinish(false);

  //Modal comment
  const [openComment, setOpenComment] = useState(false);
  const handleOpenComment = () => setOpenComment(true);
  const handleCloseComment = () => setOpenComment(false);

  return (
    <div>
      {editData?.active === "Voided" ||
      editData?.active === "Completed" ||
      editData?.status === "Reject" ? null : (
        <>
          {editData?.payment_detail[0]?.paid === true &&
          editData?.adjustment?.amount_adjustment === null ? (
            <>
              
                <Stack direction="row" justifyContent="right">
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>
                        {t("adjustment-loan")}
                      </span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenAdjustment}>
                      <Box className="update-container">
                        <RiFileEditFill className="update-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>
                        {t("finish-loan")}
                      </span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenFinish}>
                      <Box className="approve-container">
                        <AssignmentTurnedInOutlinedIcon className="approve-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>{t("comment")}</span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenComment}>
                      <Box className="cmt-container">
                        <BiSolidCommentDetail className="cmt-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>{t("void")}</span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenSubSpend}>
                      <Box className="delete-container">
                        <RadioButtonCheckedOutlinedIcon className="delete-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                </Stack>
              
            </>
          ) : editData?.payment_detail[0]?.paid === true &&
            editData?.adjustment?.amount_adjustment !== null ? (
            <>
              
                <Stack direction="row" justifyContent="right">
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>
                        {t("adjustment-loan")}
                      </span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenAdjustment}>
                      <Box className="update-container">
                        <RiFileEditFill className="update-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>
                        {t("finish-loan")}
                      </span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenFinish}>
                      <Box className="approve-container">
                        <AssignmentTurnedInOutlinedIcon className="approve-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>{t("comment")}</span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenComment}>
                      <Box className="cmt-container">
                        <BiSolidCommentDetail className="cmt-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>{t("void")}</span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenSubSpend}>
                      <Box className="delete-container">
                        <RadioButtonCheckedOutlinedIcon className="delete-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                </Stack>
              
            </>
          ) : (
            <>
             
                <Stack direction="row" justifyContent="right">
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>{t("comment")}</span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenComment}>
                      <Box className="cmt-container">
                        <BiSolidCommentDetail className="cmt-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span style={{ fontSize: "13px" }}>{t("void")}</span>
                    }
                    placement="top"
                  >
                    <IconButton onClick={handleOpenSubSpend}>
                      <Box className="delete-container">
                        <RadioButtonCheckedOutlinedIcon className="delete-icon" />
                      </Box>
                    </IconButton>
                  </Tooltip>
                </Stack>
              
            </>
          )}
        </>
      )}

      {/* {editData?.active === "Suspended" ||
      editData?.active === "Completed" ||
      editData?.status === "Reject" ? null : (
        <>
          {editData?.payment_detail[0]?.paid === true &&
          editData?.adjustment?.amount_adjustment === null ? (
            <>
              <Stack direction="row" justifyContent="right">
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>
                      {t("adjustment-loan")}
                    </span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenAdjustment}>
                    <Box className="update-container">
                      <RiFileEditFill className="update-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("finish-loan")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenFinish}>
                    <Box className="approve-container">
                      <AssignmentTurnedInOutlinedIcon className="approve-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("comment")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenComment}>
                    <Box className="cmt-container">
                      <BiSolidCommentDetail className="cmt-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("suspend")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenSubSpend}>
                    <Box className="delete-container">
                      <RadioButtonCheckedOutlinedIcon className="delete-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
              </Stack>
            </>
          ) : editData?.payment_detail[0]?.paid === true &&
            editData?.adjustment?.amount_adjustment !== null ? (
            <>
              <Stack direction="row" justifyContent="right">
                <Tooltip
                  placement="top"
                  title={
                    <span style={{ fontSize: "13px" }}>
                      {t("adjustment-loan")}
                    </span>
                  }
                >
                  <IconButton onClick={handleOpenAdjustment}>
                    <Box className="update-container">
                      <RiFileEditFill className="update-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("finish-loan")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenFinish}>
                    <Box className="approve-container">
                      <AssignmentTurnedInOutlinedIcon className="approve-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("comment")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenComment}>
                    <Box className="cmt-container">
                      <BiSolidCommentDetail className="cmt-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title={
                    <span style={{ fontSize: "13px" }}>{t("suspend")}</span>
                  }
                >
                  <IconButton onClick={handleOpenSubSpend}>
                    <Box className="delete-container">
                      <RadioButtonCheckedOutlinedIcon className="delete-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="row" justifyContent="right">
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("comment")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenComment}>
                    <Box className="cmt-container">
                      <BiSolidCommentDetail className="cmt-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <span style={{ fontSize: "13px" }}>{t("suspend")}</span>
                  }
                  placement="top"
                >
                  <IconButton onClick={handleOpenSubSpend}>
                    <Box className="delete-container">
                      <RadioButtonCheckedOutlinedIcon className="delete-icon" />
                    </Box>
                  </IconButton>
                </Tooltip>
              </Stack>
            </>
          )}
        </>
      )} */}

      <SubSpendLoan
        open={openSubSpend}
        handleClose={handleCloseSubSpend}
        editData={editData}
        setRefetch={setRefetch}
      />

      <AdjustmentLoan
        open={openAdjustment}
        handleClose={handleCloseAdjustment}
        editData={editData}
        setRefetch={setRefetch}
      />

      <UpdateComment
        open={openComment}
        handleClose={handleCloseComment}
        editData={editData}
        setRefetch={setRefetch}
      />

      <FinishLoanlist
        open={openFinish}
        handleClose={handleCloseFinish}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
