import React, { useEffect, useState ,useContext} from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Paper,
  TableHead,
  Pagination,
  Grid,
  Badge,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import "./payments.scss";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Components
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
import LastLoanAction from "../Component/LoanList/LoanListAction/LastLoanAction";
//Schema
import { GET_LOAN_WITH_PAGINATION } from "../Schema/Loanlist";
import { AuthContext } from "../context/AuthContext";
import { getFormattedPhoneNum } from "../Function/GetKhmerNumber";
import { translateLauguage } from "../Function/Translate";
import LastLoanListNewAction from "../Component/LoanList/LoanListAction/LastLoanListNewAction";

export default function LoanList() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();

  const { noneUserAccesse } = useContext(AuthContext);

  const [status, setStatus] = useState(
    JSON.parse(window.localStorage.getItem("statusLoanPayment"))
  );
  const [typeLoan, setTypeLoan] = useState(
    JSON.parse(window.localStorage.getItem("typeOfLoan"))
  );
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [tableData, setTableData] = useState([]);

  // console.log("tableData:;", tableData)

  const { data, refetch } = useQuery(GET_LOAN_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      status: "Approve",
      active: status ? status : "",
      typeOfLoan: typeLoan === "All" ? "" : typeLoan,
    },
    onCompleted: ({ getLoanByPagination }) => {
      setTableData(getLoanByPagination);
      setLoading(false);
      // console.log(getLoanByPagination);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [keyword, status]);

  const handleActive = () => {
    setStatus("Active");
    window.localStorage.setItem("statusLoanPayment", JSON.stringify("Active"));
  };

  const handleCompleted = () => {
    setStatus("Completed");
    window.localStorage.setItem(
      "statusLoanPayment",
      JSON.stringify("Completed")
    );
  };

  const handleVoid = () => {
    setStatus("Voided");
    window.localStorage.setItem(
      "statusLoanPayment",
      JSON.stringify("Voided")
    );
  };

  const handleTypeLoan = (event) => {
    setTypeLoan(event.target.value);
    window.localStorage.setItem(
      "typeOfLoan",
      JSON.stringify(event.target.value)
    );
  };

  function currencyFormat(num) {
    return (
      "$" + " " + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="payment-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {/* {t("payments list")} */}
            {t("loan list")}
          </Typography>
        </Stack>
      </Stack>

      <Box className="body-container">
        <Grid item container spacing={3}>
          <Grid item xs={12} sm={4} md={2}>
            <Badge
              // badgeContent={pendingData?.overtimePending}
              color="error"
              sx={{ width: "100%" }}
            >
              <Button
                onClick={handleActive}
                sx={{
                  bgcolor: status === "Active" ? "#0F81C2" : "#fff",
                  color: status === "Active" ? "#fff" : "#0F81C2",
                }}
                className="button-status"
              >
                <Typography
                  className={language === "kh" ? "style-addKh" : "style-addEn"}
                >
                  {t("active")}
                </Typography>
              </Button>
            </Badge>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              onClick={handleCompleted}
              sx={{
                bgcolor: status === "Completed" ? "#38b14a" : "#fff",
                color: status === "Completed" ? "#fff" : "#38b14a",
              }}
              className="button-status"
            >
              <Typography
                className={language === "kh" ? "style-addKh" : "style-addEn"}
              >
                {t("completed")}
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              onClick={handleVoid}
              sx={{
                bgcolor: status === "Voided" ? "red" : "#fff",
                color: status === "Voided" ? "#fff" : "red",
              }}
              className="button-status"
            >
              <Typography
                className={language === "kh" ? "style-addKh" : "style-addEn"}
              >
                {t("void")}
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Box className="box-marker">
              <FormControl fullWidth className="text-field">
                <Select size="small" value={typeLoan} onChange={handleTypeLoan}>
                  <MenuItem value={"All"}>{t("all")}</MenuItem>
                  <MenuItem value={"Loan"}>{t("loan")}</MenuItem>
                  <MenuItem value={"Pawn"}>{t("pawn")}</MenuItem>
                  <MenuItem value={"Installments"}>{t("installment")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Stack direction="row" justifyContent="right">
              <Box className="box-marker">
                <TextField
                  className="text-field"
                  fullWidth
                  id="input-with-sx"
                  placeholder={t("search")}
                  size="small"
                  onChange={(e) => setKeyword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mr: 1 }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="table-container">
        <TableContainer sx={{ maxWidth: `${width}px`, whiteSpace: "nowrap" }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row">
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                width="3%"
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("loan-id")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("borrower")}
              </TableCell>

              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("loan-amount")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("loan plan")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("date-released")}
              </TableCell>

              {status === "Voided" ? (
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-titleEn"
                  }
                >
                  {t("reason")}
                </TableCell>
              ) : null}

              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("phone")}
              </TableCell>

              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              ></TableCell>
            </TableHead>

            {loading ? (
              <TableHead className="header-row">
                <TableRow>
                  <TableCell
                    colSpan={8}
                    className={
                      language === "kh" ? "header-titleKh" : "header-titleEn"
                    }
                  >
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <>
                {tableData?.data?.length === 0 ? (
                  <TableHead className="header-row">
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        className={
                          language === "kh"
                            ? "header-titleKh"
                            : "header-titleEn"
                        }
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          height={260}
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <TableBody component={Paper} className="body">
                    {tableData?.data?.map((row, index) => (
                      <TableRow className="body-row" key={index}>
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cell-startKh"
                              : "body-cell-startEn"
                          }
                          width="3%"
                        >
                          {index + tableData?.paginator?.slNo}-
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            window.localStorage.setItem(
                              "menuType",
                              "loan-list"
                            );
                            navigate(
                              `/loanlist/loanlist-details?id=${row?._id}`
                            );
                          }}
                        >
                          {row?.loan_id}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanlist/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem(
                              "menuType",
                              "loan-list"
                            );
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              alt="Remy Sharp"
                              src={`${row?.customer_id?.image_src}`}
                            />
                            <Stack direction="column" justifyContent="center">
                              <Typography className="body-cellKh">
                                {row?.customer_id?.first_name +
                                  " " +
                                  row?.customer_id?.last_name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanlist/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem(
                              "menuType",
                              "loan-list"
                            );
                          }}
                        >
                          {currencyFormat(row?.loan_amount)}
                        </TableCell>

                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanlist/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem(
                              "menuType",
                              "loan-list"
                            );
                          }}
                        >
                          {`${row?.loan_plan?.month} months [${row?.loan_plan?.interest}%, ${row?.loan_plan?.over_due_penalty}%]`}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanlist/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem(
                              "menuType",
                              "loan-list"
                            );
                          }}
                        >
                          {moment(row?.loan_date).format("DD-MMM-YYYY")}
                        </TableCell>

                        {status === "Voided" ? (
                          <TableCell
                            className={
                              language === "kh" ? "body-cellKh" : "body-cellEn"
                            }
                            onClick={() => {
                              navigate(
                                `/loanlist/loanlist-details?id=${row?._id}`
                              );
                              window.localStorage.setItem(
                                "menuType",
                                "loan-list"
                              );
                            }}
                          >
                            {row?.reason}
                          </TableCell>
                        ) : null}

                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanlist/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem(
                              "menuType",
                              "loan-list"
                            );
                          }}
                        >
                          {getFormattedPhoneNum(row?.customer_id?.phone_number)}
                        </TableCell>
                        <TableCell className="body-cell-end" align="right">
                          {/* <LastLoanAction editData={row} setRefetch={refetch} /> */}
                          {noneUserAccesse(["Teller"]) ? (
                            <LastLoanListNewAction
                              editData={row}
                              setRefetch={refetch}
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
