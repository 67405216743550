import * as React from "react";
import "./loantypeaction.scss";
import { IconButton, Typography, Stack, Box, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
//Components
import AddLoanType from "../AddLoanType/AddLoanType";
import DeleteLoanType from "../DeleteLoanType/DeleteLoanType";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function LoanPlansAction({ editData, setRefetch }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Modal view
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  //Modal delete
  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("editData::=>", editData)

  return (
    <div>
      {/* <IconButton onClick={handleClick}>
        <MoreVertIcon className="three-point" />
      </IconButton>
      <Menu
        id="basic-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="action"
      >
        <MenuItem
          onClick={() => {
            handleOpenEdit();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <BorderColorOutlinedIcon className="icon-menu-update" />
            <Typography className={language === "kh" ? "text-menuKh" : "text-menuEn"}> {t("update")} </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenDel();
            handleClose();
          }}
        >
          <Stack direction="row" spacing={1}>
            <DeleteForeverOutlinedIcon className="icon-menu-delete" />
            <Typography className={language === "kh" ? "text-menuKh" : "text-menuEn"}>{t("delete")} </Typography>
          </Stack>
        </MenuItem>
      </Menu> */}

      <Stack direction="row" justifyContent="right">
        <Tooltip
          title={<span style={{ fontSize: "13px" }}>{t("update")}</span>}
          placement="top"
        >
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <CreateIcon className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        <Tooltip
          title={<span style={{ fontSize: "13px" }}>{t("delete")}</span>}
          placement="top"
        >
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <DeleteIcon className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <AddLoanType
        open={openEdit}
        handleClose={handleCloseEdit}
        editData={editData}
        setRefetch={setRefetch}
        dialogTitle={t("update")}
      />

      <DeleteLoanType
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
