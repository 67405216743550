import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useEffect } from "react";

import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "./Translate";

import {
    Autocomplete,
    TextField,
    Avatar,
    Typography,
    Stack,
    ListItemButton,
    ListItem,
    List,
    ListItemText,
    Checkbox,
    ListItemIcon,
  } from "@mui/material";

  import {GET_SOURCE_OF_MONEY_PAGINATION} from "../Schema/SourceOfMoney"

  export function SelectSourceOfMoney({ className, selectedVal, setSelectVal }) {
    // Change Language
    const { language } = React.useContext(AuthContext);
    const { t } = translateLauguage(language);
  
    const [selectOptions, setSelectOptions] = useState([]);
    const [checkedRefectch, setCheckedRefectch] = useState(false);
  
    const { refetch: refetchAccount } = useQuery(GET_SOURCE_OF_MONEY_PAGINATION, {
      variables: {
        keyword: "",
        isUse: true,
      },
      onCompleted: ({ getSourceOfMoneyWithPagination }) => {
        console.log(" getSourceOfMoneyWithPagination ::", getSourceOfMoneyWithPagination)
        let rows = [];
        if (getSourceOfMoneyWithPagination?.length !== 0) {
          getSourceOfMoneyWithPagination?.data?.map((d) => {
            const allRows = {
              id: d?._id,
              title: d?.title,
            };
            rows.push(allRows);
            setSelectOptions(rows);
          });
        } else {
          setSelectOptions([]);
        }
      },
      onError: (error) => {
        console.error("Error", error?.message);
        setSelectOptions([]);
      },
    });
  
    const handleSelect = (val) => {
      setSelectVal(val);
      setCheckedRefectch(!checkedRefectch);
    };
  
    useEffect(() => {
      refetchAccount();
    }, [checkedRefectch]);
  
    return (
      <Autocomplete
        // disabled={disabled}
        fullWidth
        value={selectedVal}
        options={selectOptions}
        onChange={(_event, val) => handleSelect(val)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option?.title || ""}
        renderInput={(param) => (
          <TextField
            className={className}
            {...param}
            size="small"
            placeholder={t(`label_select_source_money`)}
          />
        )}
      />
    );
  }