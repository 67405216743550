import React, { useState } from "react";
import "./customerdetail.scss";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
//Component
import ViewCustomerDetails from "../ViewCustomerDetails/ViewCustomerDetails";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function LoanInfoRow({ loading, row, index }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const handleCloseView = () => setOpenView(false);

  // console.log("row", row);

  return (
    <>
      <ViewCustomerDetails
        open={openView}
        handleClose={handleCloseView}
        editData={row}
        loading={loading}
      />

      <TableCell
        className="body-cell-start"
        width="3%"
        onClick={handleOpenView}
      >
        {index + 1}-
      </TableCell>
      {row?.adjustment?.adjustment_payment?.length === 0 ? (
        <TableCell className="body-cell" onClick={handleOpenView}>
          {moment(row?.loan_date).utc().format("DD-MMM-YYYY")}
        </TableCell>
      ) : (
        <TableCell className="body-cell" onClick={handleOpenView}>
          {moment(row?.adjustment?.loan_date).utc().format("DD-MMM-YYYY")}
        </TableCell>
      )}

      {row?.adjustment?.adjustment_payment?.length === 0 ? (
        <TableCell className="body-cell" onClick={handleOpenView}>
          $ {row?.loan_amount.toFixed(2)}
        </TableCell>
      ) : (
        <TableCell className="body-cell" onClick={handleOpenView}>
          $ {row?.adjustment?.amount_adjustment.toFixed(2)}
        </TableCell>
      )}

      {row?.adjustment?.adjustment_payment?.length === 0 ? (
        <TableCell className="body-cell" onClick={handleOpenView}>
          {`${row?.loan_plan?.month} months [${row?.loan_plan?.interest}%, ${row?.loan_plan?.over_due_penalty}]`}
        </TableCell>
      ) : (
        <TableCell className="body-cell" onClick={handleOpenView}>
          {`${row?.adjustment?.duration_adjustment} months [${
            row?.adjustment?.interest_adjustment * 100
          }%]`}
        </TableCell>
      )}
      <TableCell className="body-cell" onClick={handleOpenView}>
        {row?.payment_method}
      </TableCell>

      <TableCell className="body-cell" onClick={handleOpenView}>
        {row?.adjustment?.adjustment_payment?.length === 0
          ? "---:---"
          : "Adjustmented"}
      </TableCell>

      <TableCell
        className="body-cell-end"
        onClick={handleOpenView}
        sx={{
          color: row?.active === "Voided" ? "red" : "#000",
        }}
      >
        {row?.active}
      </TableCell>
    </>
  );
}
