import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { GET_CASH_IN_DETAIL } from "../../Schema/Report";
import {
  getKhmerNumber,
  getKhmerMonth,
  currencyFormat,
} from "../../Function/GetKhmerNumber";
import wingdingImage from "../../Assets/tacteing-header.PNG";
import logoCompany from "../../Assets/logo.svg";

export default function CashInDetailReport({ dateSelected, loanType }) {
  const [khmerDateString, setKhmerDateString] = useState("");

  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(GET_CASH_IN_DETAIL, {
    variables: {
      year: moment(dateSelected)?.format("YYYY"),
      loanTypeId: loanType,
    },
    onCompleted: ({ getCashInDetail }) => {
      setTableData(getCashInDetail);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  console.log("tableData", tableData);

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  return (
    <div className="setup-company-container">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="logo-container">
            <img src={logoCompany} className="logo-company" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" justifyContent="center">
            <Box>
              <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
              <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={wingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Box>
          </Stack>
          <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
            របាយការណ៍សាច់ប្រាក់ចូលប្រចាំឆ្នាំ{" "}
            {getKhmerNumber(moment(dateSelected)?.format("YYYY"))}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: "flex", justifyContent: "right", marginTop: "100px" }}
        >
          <Stack direction="row" spacing={1}>
            <Typography className="number-title-customerbyday">លេខ៖</Typography>
            <Typography className="number-item-customerbyday">
              ...........................
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <div className="company-table-container">
        <table className="company-table">
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th rowSpan={2} style={{ width: "5%" }}>
              ល.រ
            </th>
            <th rowSpan={2} style={{ width: "20%" }}>
              លេខកូដកម្ចី
            </th>
            <th rowSpan={2} style={{ width: "30%" }}>
              អតិថិជន
            </th>

            <th style={{ width: "15%" }}>ទឹកប្រាក់បានបង់</th>
            <th style={{ width: "10%" }}>ប្រាក់ដើម</th>
            <th style={{ width: "10%" }}>ការប្រាក់</th>
            <th style={{ width: "10%" }}>ពិន័យ</th>
          </tr>

          <tr style={{ backgroundColor: "#eaeefa", color: "red" }}>
            <th>{currencyFormat(tableData?.total_amount_paid)}</th>
            <th>{currencyFormat(tableData?.total_principal)}</th>
            <th>{currencyFormat(tableData?.total_interest)}</th>
            <th>{currencyFormat(tableData?.total_penalty)}</th>
          </tr>

          {tableData?.details_cash_in?.map((row, index) => (
            <tr key={index}>
              <td>{getKhmerNumber(index + 1)}</td>
              <td>{row?.loan_id}</td>
              <td style={{ textAlign: "left" }}>
                {row?.first_name + " " + row?.last_name}
              </td>
              <td>{currencyFormat(row?.amount_paid)}</td>
              <td>{currencyFormat(row?.principal)}</td>
              <td>{currencyFormat(row?.interest)}</td>
              <td>{currencyFormat(row?.penalty)}</td>
            </tr>
          ))}
        </table>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3.5}>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>
          <div className="loan-footer-name">អ្នកទទួលប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            ........................
          </div>
        </Grid>

        <Grid item xs={2.5} sx={{ marginTop: "40px" }}>
          <div className="loan-footer-name">អ្នកបញ្ចេញប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            ........................
          </div>
        </Grid>

        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <div className="loan-footer-title">{khmerDateString}</div>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>

          <Stack direction="row" spacing={6}>
            <Box>
              <div className="loan-footer-name">អ្នកអនុម័ត</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div>
            </Box>
            <Box>
              <div className="loan-footer-name">អ្នកធ្វើតារាង</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
