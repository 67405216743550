import React, { useState, useContext } from "react";
import "./deleteloanplans.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField, Divider } from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { DELETE_LOANPLAN } from "../../../Schema/Loanplan";
//Components
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function DeleteLoanPlans({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [confirmVal, setConfirmVal] = React.useState("");
  const [loading, setLoading] = useState(false);

  const { setAlert } = useContext(AuthContext);

  const [deleteLoanPlan] = useMutation(DELETE_LOANPLAN, {
    onCompleted: ({ deleteLoanPlan }) => {
      if (deleteLoanPlan?.isSuccess) {
        setAlert(true, "success", deleteLoanPlan?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", deleteLoanPlan?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  const handleDelete = () => {
    setLoading(true);
    deleteLoanPlan({
      variables: {
        deleteLoanPlanId: editData?._id,
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} className="setup-dialog-delete">
      <DialogTitle className="dialog-title">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {t("delete") + " " + t("loan plan")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <CloseIcon className="clear-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography
            variant="subtitle1"
            className={language === "kh" ? "simple-textKh" : "simple-textEn"}
          >
            {t("do-you-want-to-delete-loan-plan")}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Typography
              variant="subtitle1"
              className={language === "kh" ? "simple-textKh" : "simple-textEn"}
            >
              {t("please-input")}
            </Typography>
            <Typography className="body-text" variant="subtitle1">
              LOAN PLAN
            </Typography>
            <Typography
              variant="subtitle1"
              className={language === "kh" ? "simple-textKh" : "simple-textEn"}
            >
              {t("to-delete")}
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="month"
                fullWidth
                size="small"
                type="text"
                onChange={(e) => setConfirmVal(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="action">
              {confirmVal === "LOAN PLAN" ? (
                <Button
                  sx={{ ":hover": { backgroundColor: "red", border: "none" } }}
                  className={language === "kh" ? "btn-voidKh" : "btn-voidEn"}
                  variant="outlined"
                  fullWidth
                  onClick={handleDelete}
                >
                  {loading ? "Loading..." : t("delete-now")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={
                    language === "kh" ? "btn-deleteKh" : "btn-deleteEn"
                  }
                  fullWidth
                >
                  {t("delete")}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
