import React, { useState, useEffect } from "react";
import "./sourceofmoney.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Pagination,
  TableHead,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
// import UsersAction from '../Component/Users/UsersAction/UsersAction';
import { GET_SOURCE_OF_MONEY_PAGINATION } from "../Schema/SourceOfMoney";
import { useQuery } from "@apollo/client";
import monent from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";
import { Link } from "react-router-dom";

import AddSourceMoney from "../Component/SourceOfMoney/AddSourceMoney";
import SourceMoneyAction from "../Component/SourceOfMoney/SourceMoneyAction";

export default function SourceOfMoney() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");

  const { refetch } = useQuery(GET_SOURCE_OF_MONEY_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
    },

    onCompleted: ({ getSourceOfMoneyWithPagination }) => {
      setTableData(getSourceOfMoneyWithPagination);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="source-of-money-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/setting">
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                {t(`setting`)}
              </Typography>
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="slash-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("source_of_money")}
            </Typography>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Box className="box-marker">
          <Stack direction="column" sx={{ width: "300px" }}>
            <TextField
              className="text-field"
              fullWidth
              id="input-with-sx"
              placeholder={t("search")}
              size="small"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 1 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Box>

        <Stack direction="column" justifyContent="center">
          <Button
            onClick={() => handleOpen()}
            className={language === "kh" ? "btn-prinKh" : "btn-prinEn"}
            startIcon={<AddIcon className="icon-add" />}
          >
            {t("add")}
          </Button>
          <AddSourceMoney
            open={open}
            handleClose={handleClose}
            dialogTitle={"Create"}
            setRefetch={refetch}
          />
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer sx={{ maxWidth: `${width}px`, whiteSpace: "nowrap" }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("source_of_money")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("desc")}
              </TableCell>
            </TableHead>
            <TableBody className="body">
              {tableData?.data?.map((row, index) => {
                return (
                  <TableRow className="body-row" key={index}>
                    <TableCell
                      className={
                        language === "kh"
                          ? "body-cell-startKh"
                          : "body-cell-startEn"
                      }
                    >
                      {index + tableData?.paginator?.slNo}-
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                    >
                      {row?.title}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                    >
                      {row?.description}
                    </TableCell>
                    <TableCell className="body-cell-end" align="right">
                      <SourceMoneyAction editData={row} setRefetch={refetch} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
