import React, { useContext } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Pagination,
  TableHead,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// icon
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";
import PaidIcon from "@mui/icons-material/Paid";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";

// src
import "./setting.scss";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";

export default function Setting() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  const navigate = useNavigate();

  // const settingData = [
  //   {
  //     routeTo: "/users",
  //     icon: <AdminPanelSettingsIcon className="setting-icon" />,
  //     title: t("users"),
  //     desc: t("user_desc"),
  //   },
  //   noneUserAccesse(["CO"])
  //     ? {
  //         routeTo: "/customer",
  //         icon: <GroupIcon className="setting-icon" />,
  //         title: t("customer"),
  //         desc: t("customer_desc"),
  //       }
  //     : null,
  //   {
  //     routeTo: "/source-of-money",
  //     icon: <PaidIcon className="setting-icon" />,
  //     title: t("source_of_money"),
  //     desc: t("source_of_money_desc"),
  //   },
  //   {
  //     routeTo: "/loanplans",
  //     icon: <CreditScoreOutlinedIcon className="setting-icon" />,
  //     title: t("loan plan"),
  //     desc: t("loan_plan_desc"),
  //   },
  //   {
  //     routeTo: "/loantype",
  //     icon: <FactCheckOutlinedIcon className="setting-icon" />,
  //     title: t("loan type"),
  //     desc: t("loan_type_desc"),
  //   },
  // ];

  const settingData = !noneUserAccesse(["CO", "Manager"])
    ? [
        {
          routeTo: "/customer",
          icon: <GroupIcon className="setting-icon" />,
          title: t("customer"),
          desc: t("customer_desc"),
        },
        {
          routeTo: "/source-of-money",
          icon: <PaidIcon className="setting-icon" />,
          title: t("source_of_money"),
          desc: t("source_of_money_desc"),
        },
        {
          routeTo: "/loanplans",
          icon: <CreditScoreOutlinedIcon className="setting-icon" />,
          title: t("loan plan"),
          desc: t("loan_plan_desc"),
        },
        // {
        //   routeTo: "/loantype",
        //   icon: <FactCheckOutlinedIcon className="setting-icon" />,
        //   title: t("loan type"),
        //   desc: t("loan_type_desc"),
        // },
      ]
    : [
        {
          routeTo: "/users",
          icon: <AdminPanelSettingsIcon className="setting-icon" />,
          title: t("users"),
          desc: t("user_desc"),
        },
        {
          routeTo: "/customer",
          icon: <GroupIcon className="setting-icon" />,
          title: t("customer"),
          desc: t("customer_desc"),
        },
        {
          routeTo: "/source-of-money",
          icon: <PaidIcon className="setting-icon" />,
          title: t("source_of_money"),
          desc: t("source_of_money_desc"),
        },
        {
          routeTo: "/loanplans",
          icon: <CreditScoreOutlinedIcon className="setting-icon" />,
          title: t("loan plan"),
          desc: t("loan_plan_desc"),
        },
        // {
        //   routeTo: "/loantype",
        //   icon: <FactCheckOutlinedIcon className="setting-icon" />,
        //   title: t("loan type"),
        //   desc: t("loan_type_desc"),
        // },
      ];

  return (
    <div className="setting-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("setting")}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={4}>
          {settingData?.map((item, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Stack
                  direction="row"
                  spacing={2}
                  className="setting-card-container"
                  alignItems="center"
                  onClick={() => navigate(item?.routeTo)}
                >
                  {item?.icon}
                  <Stack direction="column">
                    <Typography
                      className={
                        language === "en" ? "title-txt" : "title-txt-kh"
                      }
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      className={
                        language === "en" ? "subtitle-txt" : "subtitle-txt-kh"
                      }
                    >
                      {item?.desc}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}
