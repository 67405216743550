import React, { useContext, useState } from "react";
import "./customerstatisticforchart.scss";
import { Grid, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_STATICTIS_FOR_REPORT } from "../../../Schema/Dashboard";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
import moment from "moment";

import {
  getKhmerMonth,
  getKhmerNumber,
} from "../../../Function/GetKhmerNumber";

import { useNavigate } from "react-router-dom";

export default function CustomerStatisticForChart({ dateSelected, type }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [active, setActive] = useState([]);
  const [complete, setComplete] = useState([]);

  const [month, setMonth] = useState([]);

  const { data } = useQuery(GET_CUSTOMER_STATICTIS_FOR_REPORT, {
    variables: {
      year: parseInt(moment(dateSelected)?.format("YYYY")),
      loanType: type === "All" ? "" : type,
    },
    onCompleted: ({ getCustomerStatisticForChart }) => {
      setActive(getCustomerStatisticForChart?.map((e) => e?.active));
      setComplete(getCustomerStatisticForChart?.map((e) => e?.completed));
      setMonth(
        getCustomerStatisticForChart?.map((e) => getKhmerMonth(e?.month))
      );
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  const highestActive = Math.max(...active);
  const highestComplete = Math.max(...complete);

  const overallHighest = Math.max(
    highestActive,
    highestComplete,
  );

  const state = {
    series: [
      {
        name: t("active"),
        data: active,
      },
      {
        name: t("completed"),
        data: complete,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        fontFamily: "Siemreap",
        fontSize: "12px",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -25,
        formatter: function (val) {
          return val;
        },
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: month,
      },
      yaxis: {
        max: overallHighest + 4,
        title: {
          text: "(នាក់)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " នាក់";
          },
          style: {
            fontFamily: "Siemreap",
            fontSize: "12px",
          },
        },
      },
    },
  };

  return (
    <Stack
      className="cus-statistic-chart"
      sx={{ cursor: "pointer" }}
      onClick={() => navigate("/payments")}
    >
      <Stack direction="row" justifyContent="center">
        <Typography className={language === "en" ? "title-en" : "title"}>
          {t("customer_statistic")}{" "}
          {language === "en"
            ? moment(dateSelected)?.format("YYYY")
            : getKhmerNumber(moment(dateSelected)?.format("YYYY"))}
        </Typography>
      </Stack>
      <Stack id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={300}
        />
      </Stack>
    </Stack>
  );
}
