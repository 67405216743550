import React, { useEffect } from "react";
import MediaCard from "./MediaCard";
import { Stack } from "@mui/material";
import FileThumnail from "./FileThumnail";

export default function ViewReferenceDocument({ customerData }) {
  function checkExtensionFile(url) {
    const parts = url.split("/");
    const filePart = parts[parts.length - 3];
    filePart.split("/");
    const resultArray = filePart.split(".");
    return resultArray[resultArray.length - 1];
  }

  const showDocument = (e) => {
    const fileType = checkExtensionFile(e?.document);
    if (fileType === "pdf") return <FileThumnail e={e} pdfFile={e?.document} />;
    else return <MediaCard e={e} />;
  };

  const documentArray = customerData?.reference_docs?.map((d) => ({
    document: d?.document_src,
    title: d?.document_name,
    key: d?.key,
  }));

  return (
    <Stack direction="row" display="flex" flexWrap="wrap" sx={{ mt: 2 }}>
      {documentArray?.map((e) => showDocument(e))}
    </Stack>
  );
}
