import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Box,
  Typography,
  Drawer,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
//Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IoMdMenu } from "react-icons/io";
import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import Cambodia from "../../Assets/Cambodia.png";
import UnitState from "../../Assets/United_Kingdom.png";
import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import Profile from "./Profile";
import MenuNavbarMobile from "./MenuNavbarMobile";
import "./topnavbar.scss";

export default function TopNavbarNew({ open, handleDrawerOpen,handleDrawerClose }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  // ================= Change Language =======================
  const { changeLanguage } = React.useContext(AuthContext);

  const [langauge, setLanguage] = useState("en");
  const handleChangeLanguage = (lang) => {
    window.localStorage.setItem("language", lang);
    setLanguage(lang);
    changeLanguage(lang);
  };

  useEffect(() => {
    let getLaung = window.localStorage.getItem("language");
    if (getLaung) {
      setLanguage(getLaung);
      changeLanguage(getLaung);
      return;
    } else {
      setLanguage("en");
      changeLanguage("en");
      window.localStorage.setItem("language", "en");
      return;
    }
  }, []);

  // ==================================== check screen ================================
  const drawerWidth = 280;
  const [state, setState] = useState({ left: false });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // ================== Function menu logout ===================
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ================== Function open logout ===================
  const [oepnLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => {
    setOpenLogout(true);
    handleClose();
  };
  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        window.localStorage.removeItem("users");
        window.localStorage.removeItem("userLogin");
        navigate("/login");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  return (
    <Toolbar className="top-bar">
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%", height: "60px" }}
        justifyContent="space-between"
      >
        <Stack direction="column" justifyContent="center">
          <IconButton
            onClick={toggleDrawer("left", true)}
            edge="start"
            sx={{
              display: open ? "flex" : "none",
              bgcolor: "#2E3192 !important",
            }}
          >
            <IoMdMenu style={{ color: "#fff" }} />
          </IconButton>

          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            <Box
              sx={{ width: drawerWidth }}
              role="presentation"
              onKeyDown={toggleDrawer("left", false)}
            >
              <MenuNavbarMobile toggleDrawer={toggleDrawer("left", false)} handleDrawerClose={handleDrawerClose}/>
            </Box>
          </Drawer>
        </Stack>

        <Stack direction="row" spacing={1.4} justifyContent="right">
          <Stack
            direction="column"
            justifyContent="center"
            spacing={1}
            // display={getUserLogin?.role !== "employee" ? "none" : "flex"}
          >
            {langauge === "en" ? (
              <Stack direction="row">
                <IconButton onClick={() => handleChangeLanguage("kh")}>
                  <Avatar
                    src={UnitState}
                    sx={{ width: "25px", height: "25px" }}
                  />
                </IconButton>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Typography className="laguage-title">
                    {t("english")}
                  </Typography>
                </Stack>
              </Stack>
            ) : null}
            {langauge === "kh" ? (
              <Stack direction="row">
                <IconButton onClick={() => handleChangeLanguage("en")}>
                  <Avatar
                    src={Cambodia}
                    sx={{ width: "25px", height: "25px" }}
                  />
                </IconButton>
                <Stack direction="column" justifyContent="center" height="100%">
                  <Typography className="laguage-title">Khmer</Typography>
                </Stack>
              </Stack>
            ) : null}
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Profile handleSignOut={handleSignOut} />
          </Stack>
        </Stack>
      </Stack>
    </Toolbar>
  );
}
