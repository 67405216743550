import React, { useContext } from "react";
import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import logo from "../../Assets/logo.svg";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import "./menunavbar.scss";
// Icons
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import SettingsIcon from "@mui/icons-material/Settings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: `280px`,
  [theme.breakpoints.up("sm")]: {
    width: `280px`,
    // width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "0px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MenuNavbarMobile({
  open,
  handleDrawerClose,
  toggleDrawer,
}) {
  let location = useLocation();
  const theme = useTheme();

  //handle click list
  const [openDropmenu, setopenDropmenu] = React.useState(true);

  const handleDropmenuClick = () => {
    setopenDropmenu(!openDropmenu);
  };

  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        className={
          theme.palette.mode === "dark" ? "drawer-menu-dark" : "drawer-menu"
        }
      >
        <DrawerHeader sx={{ mt: 3, placeContent: "center", mb: 3 }}>
          <Button className="drawerheader">
            <img src={logo} alt="logo" width="100%" />
          </Button>
        </DrawerHeader>

        <List className="list">
          {/* Item */}
          <ListItem
            className={
              location.pathname === "/dashboard"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <ListItemButton
                className="list-item-button"
                sx={{ px: 2.5 }}
                onClick={() => {
                  toggleDrawer("left", false);
                }}
              >
                <ListItemIcon className="list-item-icon">
                  <DashboardCustomizeOutlinedIcon />
                </ListItemIcon>
                <ListItemText className="list-item-text">
                  <Typography
                    className={language === "kh" ? "menuKh" : "menuEn"}
                  >
                    {t("dashboard")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}

          {/* Item */}
          {/* <ListItem
            className="list-item"
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              className="list-item-button"
              sx={{ px: 2.5 }}
              onClick={handleDropmenuClick}
            >
              <ListItemIcon
                className="list-item-icon"
                sx={{
                  color:
                    location.pathname === "/loanlist" ||
                    location.pathname === "/loanplans" ||
                    location.pathname === "/loantype" ||
                    location.pathname === "/loanlist/loanlist-details" ||
                    location.pathname === "/payments" ||
                    location.pathname === "/business-info"
                      ? "#38b14a"
                      : "#000",
                }}
              >
                <MonetizationOnOutlinedIcon />
              </ListItemIcon>
           

              <ListItemText
                sx={{
                  color:
                    location.pathname === "/loanlist" ||
                    location.pathname === "/loanplans" ||
                    location.pathname === "/loantype" ||
                    location.pathname === "/loanlist/loanlist-details" ||
                    location.pathname === "/payments" ||
                    location.pathname === "/business-info"
                      ? "#38b14a"
                      : "#000",
                }}
              >
                <Typography className={language === "kh" ? "menuKh" : "menuEn"}>
                  {t("loan")}
                </Typography>
              </ListItemText>
              {openDropmenu ? (
                <ExpandLess
                  sx={{
                    color:
                      location.pathname === "/loanlist" ||
                      location.pathname === "/loanplans" ||
                      location.pathname === "/loantype" ||
                      location.pathname === "/loanlist/loanlist-details" ||
                      location.pathname === "/payments" ||
                      location.pathname === "/business-info"
                        ? "#38b14a"
                        : "#000",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    color:
                      location.pathname === "/loanlist" ||
                      location.pathname === "/loanplans" ||
                      location.pathname === "/loantype" ||
                      location.pathname === "/loanlist/loanlist-details" ||
                      location.pathname === "/payments" ||
                      location.pathname === "/business-info"
                        ? "#38b14a"
                        : "#000",
                  }}
                />
              )}
            </ListItemButton>

            <Collapse in={openDropmenu} timeout="auto" unmountOnExit>
              <ListItem
                className={
                  location.pathname === "/loanlist" ||
                  location.pathname === "/loanlist/loanlist-details"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/loanlist" style={{ textDecoration: "none" }}>
                  <ListItemButton className="list-item-button" sx={{ px: 5 }}>
                    <ListItemIcon className="list-item-icon">
                      <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText className="list-item-text">
                      <Typography
                        className={language === "kh" ? "menuKh" : "menuEn"}
                      >
                        {t("loan list")}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem
                className={
                  location.pathname === "/payments"
                    ? "list-item-active"
                    : "list-item"
                }
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/payments" style={{ textDecoration: "none" }}>
                  <ListItemButton className="list-item-button" sx={{ px: 5 }}>
                    <ListItemIcon className="list-item-icon">
                      <LocalAtmOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText className="list-item-text">
                      <Typography
                        className={language === "kh" ? "menuKh" : "menuEn"}
                      >
                        {t("payments list")}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </Link>
              </ListItem>

              {noneUserAccesse(["Teller"]) ? (
                <>
                  <ListItem
                    className={
                      location.pathname === "/loanplans"
                        ? "list-item-active"
                        : "list-item"
                    }
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Link to="/loanplans" style={{ textDecoration: "none" }}>
                      <ListItemButton
                        className="list-item-button"
                        sx={{ px: 5 }}
                      >
                        <ListItemIcon className="list-item-icon">
                          <CreditScoreOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText className="list-item-text">
                          <Typography
                            className={language === "kh" ? "menuKh" : "menuEn"}
                          >
                            {t("loan plan")}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </Link>
                  </ListItem>
                
                  <ListItem
                    className={
                      location.pathname === "/loantype"
                        ? "list-item-active"
                        : "list-item"
                    }
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Link to="/loantype" style={{ textDecoration: "none" }}>
                      <ListItemButton
                        className="list-item-button"
                        sx={{ px: 5 }}
                      >
                        <ListItemIcon className="list-item-icon">
                          <FactCheckOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText className="list-item-text">
                          <Typography
                            className={language === "kh" ? "menuKh" : "menuEn"}
                          >
                            {t("loan type")}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </Link>
                  </ListItem>
           
                </>
              ) : (
                ""
              )}
            </Collapse>


          </ListItem> */}

          <ListItem
            className={
              location.pathname === "/loanlist"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/loanlist" style={{ textDecoration: "none" }}>
              <ListItemButton
                className="list-item-button"
                sx={{ px: 2.5 }}
                onClick={() => {
                  toggleDrawer("left", false);
                }}
              >
                <ListItemIcon className="list-item-icon">
                  <RequestQuoteIcon />
                </ListItemIcon>
                <ListItemText className="list-item-text">
                  <Typography
                    className={language === "kh" ? "menuKh" : "menuEn"}
                  >
                    {t("req-loan")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem
            className={
              location.pathname === "/payments"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/payments" style={{ textDecoration: "none" }}>
              <ListItemButton
                className="list-item-button"
                sx={{ px: 2.5 }}
                onClick={() => {
                  toggleDrawer("left", false);
                }}
              >
                <ListItemIcon className="list-item-icon">
                  <LocalAtmOutlinedIcon />
                </ListItemIcon>
                <ListItemText className="list-item-text">
                  <Typography
                    className={language === "kh" ? "menuKh" : "menuEn"}
                  >
                    {t("loan list")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>

          <ListItem
            className={
              location.pathname === "/equity" ? "list-item-active" : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/equity" style={{ textDecoration: "none" }}>
              <ListItemButton
                className="list-item-button"
                sx={{ px: 2.5 }}
                onClick={() => {
                  toggleDrawer("left", false);
                }}
              >
                <ListItemIcon className="list-item-icon">
                  <PriceChangeIcon />
                </ListItemIcon>
                <ListItemText className="list-item-text">
                  <Typography
                    className={language === "kh" ? "menuKh" : "menuEn"}
                  >
                    {t("equity")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>

          {/* {noneUserAccesse(["Teller"]) ? (
            <ListItem
              className={
                location.pathname === "/customer" ||
                location.pathname === "/customer/create-customer" ||
                location.pathname === "/customer/customer-details" ||
                location.pathname === "/customer/update-customer"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              sx={{ display: "block" }}
            >
              <Link to="/customer" style={{ textDecoration: "none" }}>
                <ListItemButton
                  className="list-item-button"
                  sx={{ px: 2.5 }}
                  onClick={() => setopenDropmenu(false)}
                >
                  <ListItemIcon className="list-item-icon">
                    <GroupsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText className="list-item-text">
                    <Typography
                      className={language === "kh" ? "menuKh" : "menuEn"}
                    >
                      {t("customer")}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          ) : (
            ""
          )} */}

          {/* {noneUserAccesse(["CO", "Teller"]) ? (
            <ListItem
              className={
                location.pathname === "/users"
                  ? "list-item-active"
                  : "list-item"
              }
              disablePadding
              sx={{ display: "block" }}
            >
              <Link to="/users" style={{ textDecoration: "none" }}>
                <ListItemButton
                  className="list-item-button"
                  sx={{ px: 2.5 }}
                  onClick={() => setopenDropmenu(false)}
                >
                  <ListItemIcon className="list-item-icon">
                    <PersonOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText className="list-item-text">
                    <Typography
                      className={language === "kh" ? "menuKh" : "menuEn"}
                    >
                      {t("users")}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          ) : (
            ""
          )} */}

          <ListItem
            className={
              location.pathname === "/report" ? "list-item-active" : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/report" style={{ textDecoration: "none" }}>
              <ListItemButton
                className="list-item-button"
                sx={{ px: 2.5 }}
                onClick={() => {
                  toggleDrawer("left", false);
                }}
              >
                <ListItemIcon className="list-item-icon">
                  <AssessmentOutlinedIcon />
                </ListItemIcon>
                <ListItemText className="list-item-text">
                  <Typography
                    className={language === "kh" ? "menuKh" : "menuEn"}
                  >
                    {t("report")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* Item */}
        </List>
        <Box sx={{ flexGrow: 1 }}></Box>

        <List className="list">
          <ListItem
            className={
              location.pathname === "/users" ||
              location.pathname === "/customer" ||
              location.pathname === "/source-of-money" ||
              location.pathname === "/loanplans" ||
              location.pathname === "/loantype" ||
              location.pathname === "/customer/customer-details" ||
              location.pathname === "/setting"
                ? "list-item-active"
                : "list-item"
            }
            disablePadding
            sx={{ display: "block" }}
          >
            <Link to="/setting" style={{ textDecoration: "none" }}>
              <ListItemButton
                className="list-item-button"
                sx={{ px: 2.5 }}
                onClick={() => {
                  toggleDrawer("left", false);
                }}
              >
                <ListItemIcon className="list-item-icon">
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText className="list-item-text">
                  <Typography
                    className={language === "kh" ? "menuKh" : "menuEn"}
                  >
                    {t("setting")}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
