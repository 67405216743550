import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./layoutnew.scss";
import { styled } from "@mui/material/styles";
import {
  Box,
  Backdrop,
  Drawer,
  Stack,
  Typography,
  Button,
  AppBar,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import { Outlet, useLocation } from "react-router-dom";

import logo from "../Assets/logoLogin.svg";
import MenuNavbar from "../Component/Menu/MenuNavbar";
import TopNavbar from "../Component/Menu/TopNavbar";
import TopNavbarNew from "../Component/Menu/TopNavbarNew";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export default function LayoutNew() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const [openMobile] = useState(false);

  // ===================== Resize width =========================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // ========================================= Sleep Screen ===================================
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [iconReload, setIconReload] = React.useState(false);

  const handleCloseBackdrop = () => {
    setIconReload(true);
    setTimeout(() => {
      setOpenBackdrop(false);
      setIconReload(false);
    }, 2000);
    // window.location.reload(location.pathname);
  };

  // ===============================================================================================

  if (openBackdrop) {
    return (
      <Backdrop
        className="backdrop-style"
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        transitionDuration={{ enter: 500, exit: 1000 }}
        open={openBackdrop}
      >
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ height: "100vh" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="90%"
          >
            <Box display="flex" justifyContent="center" width="100%">
              <img src={logo} alt="logo.jpg" width="45%" />
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography className="system-title">SEK MEAS SYSTEM</Typography>
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="contained"
                endIcon={
                  iconReload ? (
                    <CircularProgress sx={{ color: "white" }} size="25px" />
                  ) : (
                    <ReplayIcon />
                  )
                }
                onClick={handleCloseBackdrop}
              >
                Back to Page
              </Button>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            sx={{ mb: "10px" }}
          >
            <Typography className="power-title">
              Power By: GO GLOBAL TECH
            </Typography>
          </Box>
        </Stack>
      </Backdrop>
    );
  }
  return (
    <div>
      <div>
        {width > 1400 ? (
          <Box sx={{ display: "flex" }}>
            <AppBar sx={{ boxShadow: "none" }} position="fixed" open={open}>
              <TopNavbarNew handleDrawerOpen={handleDrawerOpen} open={open} handleDrawerClose={handleDrawerClose}/>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <MenuNavbar
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
              />
            </Drawer>
            <Main open={open} sx={{ marginTop: "60px" }} className="outlet-container">
              <Outlet />
            </Main>
          </Box>
        ) : (
          <Box sx={{ display: "flex" }}>
            <AppBar sx={{ boxShadow: "none" }} position="fixed" open={open}>
              <TopNavbarNew handleDrawerOpen={handleDrawerOpen} open={open} handleDrawerClose={handleDrawerClose}/>
            </AppBar>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              variant="persistent"
              anchor="left"
              open={openMobile}
            ></Drawer>
            <Main open={openMobile} sx={{ marginTop: "60px" }} className="outlet-container">
              <Outlet />
            </Main>
          </Box>
        )}
      </div>
    </div>
  );
}
