import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Paper,
  TableHead,
  Pagination,
  Grid,
  Badge,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import "./loanlist.scss";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment";
//Components
import AddLoanlist from "../Component/LoanList/AddLoanlist/AddLoanlist";
import AddInstallment from "../Component/LoanList/AddInstallment/AddInstallment";
import FirstLoanAction from "../Component/LoanList/LoanListAction/FirstLoanAction";
import LoanListNewAction from "../Component/LoanList/LoanListAction/LoanListNewAction";
import LastLoanListNewAction from "../Component/LoanList/LoanListAction/LastLoanListNewAction";
import LastLoanAction from "../Component/LoanList/LoanListAction/LastLoanAction";
import EmptyData from "../Component/Include/EmptyData";
import LoadingPage from "../Component/Include/LoadingPage";
//Schema
import {
  GET_LOAN_WITH_PAGINATION,
  GET_LOAN_REQ_NUMBER,
  GET_FIRST_APPROVE_NUMBER,
} from "../Schema/Loanlist";
import { AuthContext } from "../context/AuthContext";
import { getFormattedPhoneNum } from "../Function/GetKhmerNumber";
import { translateLauguage } from "../Function/Translate";

export default function LoanList() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  // console.log(noneUserAccesse(["Teller"]));

  const navigate = useNavigate();

  const [typeOfLoan, setTypeOfLoan] = useState("");
  // console.log("typeOfLoan::", typeOfLoan);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // loan and pawn
  const [openLoan, setOpenLoan] = useState(false);
  const handleOpenLoan = (e) => {
    handleClose();
    setOpenLoan(true);
    setTypeOfLoan(e);
  };
  const handleCloseLoan = () => setOpenLoan(false);

  //installment
  const [openInstallment, setOpenInstallment] = useState(false);
  const handleOpenInstallment = (e) => {
    handleClose();
    setOpenInstallment(true);
    setTypeOfLoan(e);
  };
  const handleCloseInstallment = () => setOpenInstallment(false);

  const [status, setStatus] = useState(
    JSON.parse(window.localStorage.getItem("statusLoanlist")) === null ||
      JSON.parse(window.localStorage.getItem("statusLoanlist")) === undefined
      ? ""
      : JSON.parse(window.localStorage.getItem("statusLoanlist"))
  );
  const [typeLoan, setTypeLoan] = useState(
    JSON.parse(window.localStorage.getItem("typeOfLoan")) === null ||
      JSON.parse(window.localStorage.getItem("typeOfLoan")) === undefined
      ? "All"
      : JSON.parse(window.localStorage.getItem("typeOfLoan"))
  );
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [keyword, setKeyword] = useState("");
  const [tableData, setTableData] = useState([]);

  const { refetch } = useQuery(GET_LOAN_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      status: status ? status : "",
      active: "Active",
      typeOfLoan: typeLoan === "All" ? "" : typeLoan,
    },
    onCompleted: ({ getLoanByPagination }) => {
      setTableData(getLoanByPagination);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  const [loanNumber, setLoanNumber] = useState();
  const { refetch: refetchLoanReq } = useQuery(GET_LOAN_REQ_NUMBER, {
    onCompleted: ({ getNumberRequestLoan }) => {
      setLoanNumber(getNumberRequestLoan);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  const [firstApproveLoanNumber, setFirstApproveLoanNumber] = useState();
  const { refetch: refetchFirstLoanApr } = useQuery(GET_FIRST_APPROVE_NUMBER, {
    onCompleted: ({ getNumberFirstApproveLoan }) => {
      setFirstApproveLoanNumber(getNumberFirstApproveLoan);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [keyword, status, typeLoan]);

  // console.log("tableDataLoanlIstr::=>", tableData);

  const handleRequest = () => {
    setStatus("Request");
    window.localStorage.setItem("statusLoanlist", JSON.stringify("Request"));
  };

  const handleFirstApprove = () => {
    setStatus("FirstApprove");
    window.localStorage.setItem(
      "statusLoanlist",
      JSON.stringify("FirstApprove")
    );
  };

  const handleApprove = () => {
    setStatus("Approve");
    window.localStorage.setItem("statusLoanlist", JSON.stringify("Approve"));
  };

  const handleReject = () => {
    setStatus("Reject");
    window.localStorage.setItem("statusLoanlist", JSON.stringify("Reject"));
  };

  const handleTypeLoan = (event) => {
    setTypeLoan(event.target.value);
    window.localStorage.setItem(
      "typeOfLoan",
      JSON.stringify(event.target.value)
    );
  };

  function currencyFormat(num) {
    return (
      "$" + " " + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }

  console.log("acess==>", noneUserAccesse(["Teller"]));

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="loanlist-page" sx={{ height: "50px" }}>
      <Stack direction="row" spacing={2}>
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("req-loan")}
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row">
          <Stack direction="column" justifyContent="center" spacing={2}>
            <Button
              sx={{
                display: noneUserAccesse(["Teller"]) ? "flex" : "none",
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className={language === "kh" ? "btn-addKh" : "btn-addEn"}
              // startIcon={<AddIcon className="icon-add" />}
              endIcon={
                !open ? (
                  <KeyboardArrowDownOutlinedIcon />
                ) : (
                  <KeyboardArrowUpOutlinedIcon />
                )
              }
            >
              {t("create")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleOpenLoan("Loan")}>
                {t("loan")}
              </MenuItem>
              <MenuItem onClick={() => handleOpenLoan("Pawn")}>
                {t("pawn")}
              </MenuItem>
              <MenuItem onClick={() => handleOpenInstallment("Installments")}>
                {t("installment")}
              </MenuItem>
            </Menu>
          </Stack>

          <AddLoanlist
            open={openLoan}
            handleClose={handleCloseLoan}
            setRefetch={refetch}
            typeOfLoan={typeOfLoan}
          />

          <AddInstallment
            open={openInstallment}
            handleClose={handleCloseInstallment}
            setRefetch={refetch}
            typeOfLoan={typeOfLoan}
          />
        </Stack>
      </Stack>

      <Box className="body-container">
        <Grid item container spacing={3}>
          <Grid item xs={6} md={4} xl={2}>
            <Badge
              badgeContent={loanNumber}
              color="error"
              sx={{ width: "100%" }}
            >
              <Button
                onClick={handleRequest}
                sx={{
                  bgcolor: status === "Request" ? "#38b14a" : "#fff",
                  color: status === "Request" ? "#fff" : "#000",
                }}
                className="button-status"
              >
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("requested")}
                </Typography>
              </Button>
            </Badge>
          </Grid>

          <Grid item xs={6} md={4} xl={2}>
            <Badge
              badgeContent={firstApproveLoanNumber}
              color="error"
              sx={{ width: "100%" }}
            >
              <Button
                onClick={handleFirstApprove}
                sx={{
                  bgcolor: status === "FirstApprove" ? "#38b14a" : "#fff",
                  color: status === "FirstApprove" ? "#fff" : "#000",
                }}
                className="button-status"
              >
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("first-approve")}
                </Typography>
              </Button>
            </Badge>
          </Grid>

          <Grid item xs={6} md={4} xl={2}>
            <Badge
              // badgeContent={pendingData?.overtimePending}
              color="error"
              sx={{ width: "100%" }}
            >
              <Button
                onClick={handleApprove}
                sx={{
                  bgcolor: status === "Approve" ? "#38b14a" : "#fff",
                  color: status === "Approve" ? "#fff" : "#000",
                }}
                className="button-status"
              >
                <Typography className={language === "kh" ? "subKh" : "subEn"}>
                  {t("approved")}
                </Typography>
              </Button>
            </Badge>
          </Grid>

          <Grid item xs={6} md={4} xl={2}>
            <Button
              onClick={handleReject}
              sx={{
                bgcolor: status === "Reject" ? "red" : "#fff",
                color: status === "Reject" ? "#fff" : "#000",
              }}
              className="button-status"
            >
              <Typography className={language === "kh" ? "subKh" : "subEn"}>
                {t("rejected")}
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={6} md={4} xl={2}>
            <Box className="box-marker">
              <FormControl fullWidth className="text-field">
                <Select size="small" value={typeLoan} onChange={handleTypeLoan}>
                  <MenuItem value={"All"}>{t("all")}</MenuItem>
                  <MenuItem value={"Loan"}>{t("loan")}</MenuItem>
                  <MenuItem value={"Pawn"}>{t("pawn")}</MenuItem>
                  <MenuItem value={"Installments"}>{t("installment")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} md={4} xl={2}>
            <Stack direction="row" justifyContent="right">
              <Box className="box-marker">
                <TextField
                  className="text-field"
                  fullWidth
                  id="input-with-sx"
                  placeholder={t("search")}
                  size="small"
                  onChange={(e) => setKeyword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mr: 1 }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="table-container">
        <TableContainer sx={{ maxWidth: `${width}px`, whiteSpace: "nowrap" }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row">
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
                width="3%"
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("loan-id")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("borrower")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("loan-amount")}
              </TableCell>

              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("loan plan")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("loan-date")}
              </TableCell>

              {status === "Approve" ? (
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-titleEn"
                  }
                >
                  {t("adjustment")}
                </TableCell>
              ) : status === "Request" ? null : (
                <TableCell
                  className={
                    language === "kh" ? "header-titleKh" : "header-titleEn"
                  }
                >
                  {status === "FirstApprove" ? t("first-comment") : t("reason")}
                </TableCell>
              )}

              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("phone")}
              </TableCell>

              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              ></TableCell>
            </TableHead>

            {loading ? (
              <TableHead className="header-row">
                <TableRow>
                  <TableCell
                    colSpan={8}
                    className={
                      language === "kh" ? "header-titleKh" : "header-titleEn"
                    }
                  >
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <React.Fragment>
                {tableData?.data?.length === 0 ? (
                  <TableHead className="header-row">
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        className={
                          language === "kh"
                            ? "header-titleKh"
                            : "header-titleEn"
                        }
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          // height={260}
                        >
                          <EmptyData />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <TableBody component={Paper} className="body">
                    {tableData?.data?.map((row, index) => (
                      <TableRow key={index} className="body-row">
                        <TableCell
                          className={
                            language === "kh"
                              ? "body-cell-startKh"
                              : "body-cell-startEn"
                          }
                          width="3%"
                        >
                          {index + tableData?.paginator?.slNo}-
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanRequest/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem("menuType", "loan-req");
                          }}
                        >
                          {row?.loan_id}
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanRequest/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem("menuType", "loan-req");
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <Avatar
                              alt="Remy Sharp"
                              src={`${row?.customer_id?.image_src}`}
                            />
                            <Stack direction="column" justifyContent="center">
                              <Typography className="body-cellKh">
                                {row?.customer_id?.first_name +
                                  " " +
                                  row?.customer_id?.last_name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanRequest/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem("menuType", "loan-req");
                          }}
                        >
                          {currencyFormat(row?.loan_amount)}
                        </TableCell>

                        {row?.adjustment?.amount_adjustment === null ? (
                          <TableCell
                            className={
                              language === "kh" ? "body-cellKh" : "body-cellEn"
                            }
                            onClick={() => {
                              navigate(
                                `/loanRequest/loanlist-details?id=${row?._id}`
                              );
                              window.localStorage.setItem(
                                "menuType",
                                "loan-req"
                              );
                            }}
                          >
                            {`${row?.loan_plan?.month} months [${row?.loan_plan?.interest}%]`}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={
                              language === "kh" ? "body-cellKh" : "body-cellEn"
                            }
                            onClick={() => {
                              navigate(
                                `/loanRequest/loanlist-details?id=${row?._id}`
                              );
                              window.localStorage.setItem(
                                "menuType",
                                "loan-req"
                              );
                            }}
                          >
                            {`${row?.adjustment?.duration_adjustment} months [${
                              row?.adjustment?.interest_adjustment * 100
                            }%]`}
                          </TableCell>
                        )}

                        {row?.adjustment?.amount_adjustment === null ? (
                          <TableCell
                            className={
                              language === "kh" ? "body-cellKh" : "body-cellEn"
                            }
                            onClick={() => {
                              navigate(
                                `/loanRequest/loanlist-details?id=${row?._id}`
                              );
                              window.localStorage.setItem(
                                "menuType",
                                "loan-req"
                              );
                            }}
                          >
                            {moment(row?.loan_date).utc().format("DD-MMM-YYYY")}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={
                              language === "kh" ? "body-cellKh" : "body-cellEn"
                            }
                            onClick={() => {
                              navigate(
                                `/loanRequest/loanlist-details?id=${row?._id}`
                              );
                              window.localStorage.setItem(
                                "menuType",
                                "loan-req"
                              );
                            }}
                          >
                            {moment(row?.adjustment?.loan_date)
                              .utc()
                              .format("DD-MMM-YYYY")}
                          </TableCell>
                        )}

                        {status === "Approve" ? (
                          <TableCell
                            className={
                              language === "kh" ? "body-cellKh" : "body-cellEn"
                            }
                            onClick={() => {
                              navigate(
                                `/loanRequest/loanlist-details?id=${row?._id}`
                              );
                              window.localStorage.setItem(
                                "menuType",
                                "loan-req"
                              );
                            }}
                          >
                            {row?.adjustment?.amount_adjustment === null
                              ? "---:---"
                              : "Adjustmented"}
                          </TableCell>
                        ) : status === "Request" ? null : (
                          <TableCell
                            className={
                              language === "kh" ? "body-cellKh" : "body-cellEn"
                            }
                            onClick={() => {
                              navigate(
                                `/loanRequest/loanlist-details?id=${row?._id}`
                              );
                              window.localStorage.setItem(
                                "menuType",
                                "loan-req"
                              );
                            }}
                          >
                            {status === "FirstApprove"
                              ? `${row?.reason}`
                              : `${row?.purposeRejection}`}
                          </TableCell>
                        )}

                        <TableCell
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                          onClick={() => {
                            navigate(
                              `/loanRequest/loanlist-details?id=${row?._id}`
                            );
                            window.localStorage.setItem("menuType", "loan-req");
                          }}
                        >
                          {getFormattedPhoneNum(row?.customer_id?.phone_number)}
                        </TableCell>

                        {noneUserAccesse(["Teller"]) ? (
                          <TableCell className="body-cell-end" align="right">
                            {row?.status === "Request" ||
                            row?.status === "FirstApprove" ? (
                              // <FirstLoanAction
                              //   editData={row}
                              //   setRefetch={refetch}
                              // />
                              <LoanListNewAction
                                editData={row}
                                setRefetch={refetch}
                              />
                            ) : (
                              <LastLoanListNewAction
                                editData={row}
                                setRefetch={refetch}
                              />
                              // <LastLoanAction
                              //   editData={row}
                              //   setRefetch={refetch}
                              // />
                              // <Typography>LastLoanAction</Typography>
                            )}
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </React.Fragment>
            )}
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
