import React from "react";
import {
  Box,
  Card,
  Stack,
  CardMedia,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
export default function MediaCard({
  e,
  handleRemoveDocument,
  handleChangeTitleDocument,
}) {
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Box
      sx={{
        width: 167,
        height: 195,
        padding: 1,
        marginBottom: 7,
      }}
    >
      <Stack spacing={1}>
        <Card sx={{ width: "100%", position: "relative" }}>
          <IconButton
            onClick={() => handleRemoveDocument(e?.key)}
            sx={{
              top: 0,
              right: 0,
              zIndex: 3,
              padding: 0.5,
              position: "absolute",
            }}
          >
            <DeleteOutlinedIcon fontSize="medium" sx={{ color: "red" }} />
          </IconButton>

          <CardMedia
            image={e?.document}
            title={e?.document}
            sx={{
              height: 195,
              cursor: "pointer",
            }}
            onClick={() => openLinkInNewTab(e?.document)}
          />
        </Card>
        <TextField
          fullWidth
          size="small"
          disabled={!e?.document}
          value={e?.title}
          className="text-field-name"
          placeholder={t("document-name")}
          onChange={({ target }) =>
            handleChangeTitleDocument(e?.key, target.value)
          }
        />
      </Stack>
    </Box>
  );
}
