import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./loanapprovedetails.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Stack,
  Box,
  Button,
  Avatar,
  Grid,
  Typography,
  Skeleton,
  Link,
  Badge,
} from "@mui/material";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";
//components
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import UpdatePaidPayment from "./UpdatePaidPayment";
import UpdatePaidAdjustment from "./UpdatePaidAdjustment";
import PrintLoanApprove from "../PrintLoanApprove/PrintLoanApprove";
import PrintInstallmentsApprove from "../PrintLoanApprove/PrintInstallmentsApprove";

export default function LoanApproveDetailsList({
  loading,
  tableData,
  setRefetch,
}) {
  const navigate = useNavigate();
  const [paymentType, setPaymentType] = useState("Previous_Payment");

  // Change Language
  const { language } = useContext(AuthContext);
  const { t } = translateLauguage(language);
  const { noneUserAccesse } = useContext(AuthContext);

  const [openPrint, setOpenPrint] = useState(false);
  const handleOpenPrint = () => setOpenPrint(true);
  const handleClosePrint = () => setOpenPrint(false);

  //React to print
  const componentRef = useRef();
  const handalePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (tableData?.adjustment?.adjustment_payment?.length === 0) {
      setPaymentType("Previous_Payment");
    } else {
      setPaymentType("Current_Payment");
    }
  }, [tableData?.adjustment?.adjustment_payment]);

  let totalPaymentDetails = tableData?.payment_detail.reduce(function (i, j) {
    return i + j.monthly_payment;
  }, 0);

  let totalPaymentDetailsAdjustment =
    tableData?.adjustment?.adjustment_payment?.reduce(function (i, j) {
      return i + j.monthly_payment;
    }, 0);

    let totalInterest = tableData?.payment_detail.reduce(function (i, j) {
      return i + j.interest;
    }, 0);

    let totalInterestAdjustment = tableData?.adjustment?.adjustment_payment?.reduce(function (i, j) {
      return i + j.interest;
    }, 0);
  // console.log("tableData::", tableData);

  // console.log("paymentType::", paymentType)

  function currencyFormat(num) {
    return (
      "$" + " " + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }

  if (loading) {
    return (
      <div className="loan-approve-details">
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <Button
                className={language === "kh" ? "btn-printKh " : "btn-printEn"}
              >
                Print
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Box className="box-container">
          <Box className="box-container-top">
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2.4}>
                <Box className="box-info">
                  <Skeleton variant="circular" width={120} height={120} />
                </Box>
                <Typography className="body-skeleton">
                  <Skeleton width="50%" />
                </Typography>
                <Typography className="body-skeleton">
                  <Skeleton width="50%" />
                </Typography>
                <Typography className="body-skeleton">
                  <Skeleton width="50%" />
                </Typography>
              </Grid>

              <Grid item xs={12} lg={9.6} className="grid-details-container">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography
                      className="body-skeleton"
                      sx={{ marginTop: "30px" }}
                    >
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography
                      className="body-skeleton"
                      sx={{ marginTop: "30px" }}
                    >
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography
                      className="body-skeleton"
                      sx={{ marginTop: "30px" }}
                    >
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography
                      className="body-skeleton"
                      sx={{ marginTop: "30px" }}
                    >
                      <Skeleton width="50%" />
                    </Typography>
                    <Typography className="body-skeleton">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* <Divider /> */}
          {/* <Box className="box-container-bottom"></Box> */}
        </Box>
      </div>
    );
  }

  return (
    <div className="loan-approve-details">
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" justifyContent="center" spacing={1}></Stack>
            <Box sx={{ flexGrow: 1 }} />
            <Stack direction="column" justifyContent="center" spacing={2}>
              <Button
                className={language === "kh" ? "btn-printKh " : "btn-printEn"}
                startIcon={<LocalPrintshopOutlinedIcon />}
                onClick={handleOpenPrint}
              >
                {t("print")}
              </Button>
              {tableData?.type_of_loan === "Installments" ? (
                <PrintInstallmentsApprove
                  open={openPrint}
                  handleClose={handleClosePrint}
                  tableData={tableData}
                  paymentType={paymentType}
                />
              ) : (
                <PrintLoanApprove
                  open={openPrint}
                  handleClose={handleClosePrint}
                  tableData={tableData}
                  paymentType={paymentType}
                />
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      {tableData?.adjustment?.adjustment_payment?.length === 0 ? null : (
        <Grid item container spacing={3} sx={{ marginTop: "10px" }}>
          <Grid item xs="auto" sm={3}>
            <Badge color="error" sx={{ width: "100%" }}>
              <Button
                onClick={() => setPaymentType("Previous_Payment")}
                sx={{
                  bgcolor:
                    paymentType === "Previous_Payment" ? "#0F81C2" : "#fff",
                  color:
                    paymentType === "Previous_Payment" ? "#fff" : "#0F81C2",
                }}
                className="button-status"
              >
                Previous Payment
              </Button>
            </Badge>
          </Grid>

          <Grid item xs="auto" sm={3}>
            <Button
              onClick={() => setPaymentType("Current_Payment")}
              sx={{
                bgcolor: paymentType === "Current_Payment" ? "#38b14a" : "#fff",
                color: paymentType === "Current_Payment" ? "#fff" : "#38b14a",
              }}
              className="button-status"
            >
              Current Payment
            </Button>
          </Grid>
        </Grid>
      )}

      {tableData?.customer_id === null ? (
        <Stack direction="column" justifyContent="center" height={260}>
          <EmptyData />
        </Stack>
      ) : (
        <React.Fragment>
          <Box className="box-container">
            <Box className="box-container-top">
              <Grid container spacing={1}>
                <Grid item xs={12} lg={2.4}>
                  <Box className="box-info">
                    <Avatar
                      alt="Remy Sharp"
                      className="image"
                      src={`${tableData?.customer_id?.image_src}`}
                    />
                  </Box>
                  <Typography
                    className={
                      language === "kh" ? "body-nameKh " : "body-nameEn"
                    }
                    sx={{ marginTop: "10px" }}
                  >
                    {tableData?.customer_id?.first_name +
                      " " +
                      tableData?.customer_id?.last_name}
                  </Typography>
                  <Typography
                    className={
                      language === "kh" ? "body-phoneKh " : "body-phoneEn"
                    }
                  >
                    {tableData?.customer_id?.phone_number}
                  </Typography>
                  <Typography
                    className={
                      language === "kh" ? "body-phoneKh " : "body-phoneEn"
                    }
                  >
                    {tableData?.customer_id?.address}
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={9.6} className="grid-details-container">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Typography className="details-body">Loan ID</Typography>
                      <Typography className="details-body-bold">
                        {tableData?.loan_id === null
                          ? "---:---"
                          : tableData?.loan_id}
                      </Typography>
                      <Typography
                        className="details-body"
                        sx={{ marginTop: "30px" }}
                      >
                      Loan Amount
                      </Typography>
                      {paymentType === "Previous_Payment" ? (
                        <Typography className="details-body-bold">
                          {currencyFormat(tableData?.loan_amount)}
                        </Typography>
                      ) : (
                        <Typography className="details-body-bold">
                          {/* ${tableData?.adjustment?.amount_adjustment.toFixed(2)} */}

                          {currencyFormat(
                            tableData?.adjustment?.amount_adjustment
                          )}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Typography className="details-body-bold">
                        {paymentType === "Previous_Payment" ? (
                          <Typography className="details-body-bold">
                            {tableData?.loan_plan?.month} months
                          </Typography>
                        ) : (
                          <Typography className="details-body-bold">
                            {tableData?.adjustment?.duration_adjustment} months
                          </Typography>
                        )}
                      </Typography>

                      {paymentType === "Previous_Payment" ? (
                        <Typography className="details-body">
                          {`[Interest`}
                          <span style={{ fontWeight: "bold" }}>
                            {` ${tableData?.loan_plan?.interest}%, `}
                          </span>
                          {`Penalty `}
                          <span style={{ fontWeight: "bold" }}>
                            {tableData?.loan_plan?.over_due_penalty}
                          </span>
                          {`]`}
                        </Typography>
                      ) : (
                        <Typography className="details-body">
                          {`[Interest`}
                          <span style={{ fontWeight: "bold" }}>
                            {` ${
                              tableData?.adjustment?.interest_adjustment * 100
                            }% ]`}
                          </span>
                        </Typography>
                      )}

                      <Typography
                        className="details-body"
                        sx={{ marginTop: "30px" }}
                      >
                        Interest
                      </Typography>

                      <Typography className="details-body-bold">
                        {paymentType === "Previous_Payment"
                          ? currencyFormat(totalInterest)
                          : currencyFormat(totalInterestAdjustment)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Typography className="details-body">
                        Date Released
                      </Typography>
                      {paymentType === "Previous_Payment" ? (
                        <Typography className="details-body-bold">
                          {tableData?.type_of_loan === "Installments"
                            ? moment(tableData?.installments?.release_date)
                                .utc()
                                .format("DD-MMM-YYYY")
                            : moment(tableData?.loan_date)
                                .utc()
                                .format("DD-MMM-YYYY")}
                        </Typography>
                      ) : (
                        <Typography className="details-body-bold">
                          {moment(tableData?.adjustment?.loan_date)
                            .utc()
                            .format("DD-MMM-YYYY")}
                        </Typography>
                      )}
                      <Typography
                        className="details-body"
                        sx={{ marginTop: "30px" }}
                      >
                        Total Amount
                      </Typography>

                      <Typography className="details-body-bold">
                        {paymentType === "Previous_Payment"
                          ? currencyFormat(totalPaymentDetails)
                          : currencyFormat(totalPaymentDetailsAdjustment)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Typography className="details-body">
                        Type of loan
                      </Typography>
                      <Typography className="details-body-primary">
                        {tableData?.type_of_loan === null
                          ? "---:---"
                          : tableData?.type_of_loan}
                      </Typography>
                      <Typography
                        className="details-body"
                        sx={{ marginTop: "30px" }}
                      >
                        Purpose
                      </Typography>
                      <Typography className="details-body-bold">
                        {tableData?.purpose}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <TableContainer className="constainer">
            <Typography
              className={language === "kh" ? "tage-centerKh" : "tage-centerEn"}
            >
              {t("payment-detail")}
            </Typography>

            <Table className="table">
              <TableHead className="table-head">
                <TableRow className="table-row">
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("no")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("payment-date")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("principal")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("interest")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("total-payment")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("rest-of-money")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("received-date")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("paid-type")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  >
                    {t("paid-status")}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "table-cellKh" : "table-cellEn"
                    }
                  ></TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <TableHead className="header-row">
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      className={
                        language === "kh" ? "table-cellKh" : "table-cellEn"
                      }
                    >
                      <LoadingPage />
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : (
                <React.Fragment>
                  {(tableData?.payment_detail?.length === 0 &&
                    tableData?.adjustment?.adjustment_payment?.length === 0) ||
                  tableData?.status === "Reject" ? (
                    <TableHead className="header-row">
                      <TableRow>
                        <TableCell
                          colSpan={9}
                          className={
                            language === "kh" ? "table-cellKh" : "table-cellEn"
                          }
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            height={260}
                          >
                            <EmptyData />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    <React.Fragment>
                      {paymentType === "Previous_Payment" ? (
                        <TableBody className="table-body">
                          {tableData?.payment_detail?.map((row, index) => (
                            <TableRow className="body-row" key={index}>
                              <TableCell className="body-cell-start">
                                {index + 1}-
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {moment(row?.payment_date)
                                  .utc()
                                  .format("DD-MMM-YYYY")}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {currencyFormat(row?.principal)}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {currencyFormat(row?.interest)}
                              </TableCell>

                              <TableCell className="body-cell" align="left">
                                {row?.monthly_payment === null
                                  ? "$0.00"
                                  : currencyFormat(row?.monthly_payment)}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {row?.rest_of_money === null
                                  ? "$0.00"
                                  : currencyFormat(row?.rest_of_money)}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {row?.receipt_date !== null
                                  ? moment(row?.receipt_date)
                                      .utc()
                                      .format("DD-MMM-YYYY")
                                  : "---:---"}
                              </TableCell>

                              <TableCell className="body-cell" align="left">
                                {row?.payment_status}
                              </TableCell>
                              <TableCell className="body-cell" align="left">
                                {row?.paid === true ? "paid" : "pending"}
                              </TableCell>
                              <TableCell
                                className="body-cell-end"
                                align="right"
                              >
                                {noneUserAccesse(["CO"]) ? (
                                  <>
                                    {tableData?.active === "Voided" ||
                                    tableData?.adjustment?.adjustment_payment
                                      ?.length !== 0 ? (
                                      ""
                                    ) : (
                                      <UpdatePaidPayment
                                        tableData={tableData}
                                        editData={row}
                                        setRefetch={setRefetch}
                                      />
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <TableBody className="table-body">
                          {tableData?.adjustment?.adjustment_payment?.map(
                            (row, index) => (
                              <TableRow className="body-row" key={index}>
                                <TableCell className="body-cell-start">
                                  {index + 1}-
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {moment(row?.payment_date)
                                    .utc()
                                    .format("DD-MMM-YYYY")}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {currencyFormat(row?.principal)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {currencyFormat(row?.interest)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {row?.monthly_payment === null
                                    ? "$0.00"
                                    : currencyFormat(row?.monthly_payment)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {row?.rest_of_money === null
                                    ? "$0.00"
                                    : currencyFormat(row?.rest_of_money)}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {row?.receipt_date !== null
                                    ? moment(row?.receipt_date)
                                        .utc()
                                        .format("DD-MMM-YYYY")
                                    : "---:---"}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {row?.payment_status}
                                </TableCell>
                                <TableCell className="body-cell" align="left">
                                  {row?.paid === true ? "paid" : "pending"}
                                </TableCell>
                                <TableCell
                                  className="body-cell-end"
                                  align="right"
                                >
                                  {tableData?.active === "Voided" ? null : (
                                    <UpdatePaidAdjustment
                                      tableData={tableData}
                                      editData={row}
                                      setRefetch={setRefetch}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </div>
  );
}
