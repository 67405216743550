import React, { useState, useEffect } from "react";
import "./equity.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Pagination,
  TableHead,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
// import UsersAction from '../Component/Users/UsersAction/UsersAction';
import { GET_EQUITY_WITH_PAGINATION } from "../Schema/Equity";
import { useQuery } from "@apollo/client";
import monent from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";

import EquityForm from "../Component/Equity/EquityForm";
import EquityAction from "../Component/Equity/EquityAction";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Equity() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpenCreate = () => setOpen(true);
  const handleCloseCreate = () => setOpen(false);

  const [type, setType] = React.useState("All");

  console.log("type", type);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const { refetch } = useQuery(GET_EQUITY_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      // cashInType: type === "All" ? "" : type
      cashInType: type,
    },

    onCompleted: ({ getEquityWithPagination }) => {
      setTableData(getEquityWithPagination);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="equity-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="column" justifyContent="center">
          <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
            {t("equity")}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />

        <Box className="box-marker">
          <Stack direction="row" spacing={2}>
            <Stack direction="column" sx={{ width: "300px" }}>
              <TextField
                className="text-field"
                fullWidth
                id="input-with-sx"
                placeholder={t("search")}
                size="small"
                onChange={(e) => setKeyword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack direction="column" sx={{ width: "300px" }}>
              <FormControl fullWidth size="small" className="text-field">
                <Select value={type} onChange={handleChange}>
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Equity">Equity</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Box>

        <Stack direction="column" justifyContent="center">
          <Button
            onClick={() => handleOpenCreate()}
            className={language === "kh" ? "btn-prinKh" : "btn-prinEn"}
            startIcon={<AddIcon className="icon-add" />}
          >
            {t("add")}
          </Button>
        </Stack>
        <EquityForm
          open={open}
          handleClose={handleCloseCreate}
          setRefetch={refetch}
          dialogTitle="Create"
        />
      </Stack>

      <Box className="container">
        <TableContainer sx={{ maxWidth: `${width}px`, whiteSpace: "nowrap" }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("amount")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("source_of_money")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("cash_in_type")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("date")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-titleKh" : "header-titleEn"
                }
              >
                {t("desc")}
              </TableCell>
            </TableHead>
            <TableBody className="body">
              {tableData?.data?.map((row, index) => {
                return (
                  <TableRow
                    className="body-row"
                    key={index}
                  >
                    <TableCell
                      className={
                        language === "kh"
                          ? "body-cell-startKh"
                          : "body-cell-startEn"
                      }
                      onClick={() =>
                        navigate(`/customer/customer-details?id=${row?._id}`)
                      }
                    >
                      {index + tableData?.paginator?.slNo}-
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      // onClick={() =>
                      //   navigate(`/customer/customer-details?id=${row?._id}`)
                      // }
                    >
                      {row?.amount}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      // onClick={() =>
                      //   navigate(`/customer/customer-details?id=${row?._id}`)
                      // }
                    >
                      {row?.source_of_money_id?.title}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      // onClick={() =>
                      //   navigate(`/customer/customer-details?id=${row?._id}`)
                      // }
                    >
                      {row?.cash_in_type}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      // onClick={() =>
                      //   navigate(`/customer/customer-details?id=${row?._id}`)
                      // }
                    >
                      {monent(row?.date).format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell
                      className={
                        language === "kh" ? "body-cellKh" : "body-cellEn"
                      }
                      // onClick={() =>
                      //   navigate(`/customer/customer-details?id=${row?._id}`)
                      // }
                    >
                      {row?.description}
                    </TableCell>
                    <TableCell className="body-cell-end" align="right">
                      <EquityAction editRow={row} setRefetch={refetch} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
