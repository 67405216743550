import * as React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import { AuthContext } from "../../../../context/AuthContext";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import { translateLauguage } from "../../../../Function/Translate";

export default function FileThumnail({ e, pdfFile }) {
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [thumnail, setThumnail] = React.useState("");

  async function generateThumbnails(pdf) {
    try {
      let thumbnails = await generatePdfThumbnails(pdf, 200);
      setThumnail(thumbnails[0].thumbnail);
    } catch (err) {
      console.error(err, "err document");
    }
  }

  React.useEffect(() => {
    if (pdfFile) {
      generateThumbnails(pdfFile);
    }
  }, [pdfFile]);

  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  const LongTextComponent = ({ text }) => {
    return (
      <Tooltip title={text}>
        <div
          style={{
            width: "100%",
            height: "3em", // Ensures the height aligns with the truncated lines
            overflow: "hidden", // Ensures content beyond the height is clipped
            display: "-webkit-box", // Enables the use of -webkit-line-clamp
            WebkitBoxOrient: "vertical", // Defines the box orientation
            WebkitLineClamp: 2, // Limits to 2 lines
            lineHeight: "1.5em", // Ensures proper line spacing
            textOverflow: "ellipsis", // Adds the ellipsis for overflowed text
            whiteSpace: "normal", // Ensures text wrapping
            color: "gray",
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          {text}
        </div>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        margin: 1,
        width: 200,
        height: 260,
        cursor: "pointer",
        position: "relative",
        borderRadius: "12px",
        boxShadow: "0px 0.5px 3px 1px rgba(0,0,0,0.1)",
      }}
    >
      <Stack spacing={1}>
        <img
          onClick={() => openLinkInNewTab(pdfFile)}
          src={thumnail}
          alt="document"
          style={{
            zIndex: 1,
            height: 260,
            width: "100%",
            borderRadius: "12px",
          }}
        />
        <LongTextComponent text={e?.title} />
      </Stack>
    </Box>
  );
}
