import React, { useState, useEffect, useContext } from "react";
import "./addsourcemoney.scss";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box, TextField, Divider } from "@mui/material";
import { useMutation } from "@apollo/client";
//Schema
import { CREATE_SOURCE_OF_MONEY,UPDATE_SOURCE_OF_MONEY } from "../../Schema/SourceOfMoney";
//Components
import { AuthContext } from "../../context/AuthContext";
import { translateLauguage } from "../../Function/Translate";

export default function AddSourceMoney({
  open,
  handleClose,
  editData,
  dialogTitle,
  setRefetch,
}) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [loading, setLoading] = useState(false);

  const { setAlert } = useContext(AuthContext);

  const [createSourceOfMoney] = useMutation(CREATE_SOURCE_OF_MONEY, {
    onCompleted: ({ createSourceOfMoney }) => {
      if (createSourceOfMoney?.isSuccess) {
        setAlert(true, "success", createSourceOfMoney?.message);
        setRefetch();
        handleClose();
        setLoading(false);
        resetForm();
      } else {
        setAlert(true, "error", createSourceOfMoney?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  const [updateSourceOfMoney] = useMutation(UPDATE_SOURCE_OF_MONEY, {
    onCompleted: ({ updateSourceOfMoney }) => {
      if (updateSourceOfMoney?.isSuccess) {
        setAlert(true, "success", updateSourceOfMoney?.message);
        setRefetch();
        handleClose();
        setLoading(false);
      } else {
        setAlert(true, "error", updateSourceOfMoney?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      // console.log(error);
      setAlert(true, "error", error?.message);
      setLoading(false);
    },
  });

  //fromik
  const SourceMoney = Yup.object().shape({
    title: Yup.string().required("require!"),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },

    validationSchema: SourceMoney,
    onSubmit: async (values) => {
      // console.log(values);
      setLoading(true);
      
      if (dialogTitle === "Create") {
        createSourceOfMoney({
          variables: {
            input: {
              ...values,
            },
          },
        });
      } else {
        updateSourceOfMoney({
          variables: {
            id: editData?._id,
            input: {
              ...values,
            },
          },
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue,resetForm } =
    formik;

  useEffect(() => {
    if (editData) {
      setFieldValue("title", editData?.title);
      setFieldValue("description", editData?.description);
    }
  }, [editData]);


  return (
    <Dialog
      open={open}
      className="source-of-money"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack direction="row">
          <Typography
            className={language === "kh" ? "dialog-titleKh" : "dialog-titleEn"}
          >
            {dialogTitle === "Create"
              ? t("create_source_of_money")
              : t("update_source_of_money")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <ClearIcon className="close-icon" />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>

      <DialogContent>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("source_of_money")}
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={t("source_of_money")}
                    {...getFieldProps("title")}
                    error={Boolean(
                      touched.title && errors.title
                    )}
                    helperText={touched.title && errors.title}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Typography className={language === "kh" ? "headKh" : "headEn"}>
                  {t("desc")}
                </Typography>
                <Stack>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="desc"
                    {...getFieldProps("description")}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Box className="action">
          <Grid container>
            <Grid item xs={12}>
              {loading ? (
                <Button
                  className={language === "kh" ? "btn-Kh" : "btn-En"}
                  fullWidth
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  className={language === "kh" ? "btn-Kh" : "btn-En"}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {dialogTitle}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
