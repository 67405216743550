import React, { useEffect, useState } from "react";
import { Grid, Stack, Box } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { getFormattedPhoneNum } from "../../Function/GetKhmerNumber";
import { GET_DAILY_CUSTOMER } from "../../Schema/Report";
import {
  getKhmerNumber,
  getKhmerMonth,
  currencyFormat,
  getKhmerDay,
} from "../../Function/GetKhmerNumber";
import wingdingImage from "../../Assets/tacteing-header.PNG";
import logoCompany from "../../Assets/logo.svg";

export default function DailyCustomerPaymentReport({ dateSelected }) {
  const [khmerDateString, setKhmerDateString] = useState("");
  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(GET_DAILY_CUSTOMER, {
    variables: {
      paymentStatus: "",
      date: moment(dateSelected).utc().format("YYYY-MM-DD"),
    },
    onCompleted: ({ getReportCostomerPayment }) => {
      setTableData(getReportCostomerPayment);
      // console.log("getReportCostomerPayment::=>", getReportCostomerPayment);
    },
    onError: (error) => {
      console.log("error::", error.message);
    },
  });

  const loanData = tableData?.filter((data) => data?.type_of_loan === "Loan");
  const pawnData = tableData?.filter((data) => data?.type_of_loan === "Pawn");
  const installmentData = tableData?.filter(
    (data) => data?.type_of_loan === "Installments"
  );

  // console.log("loanData::", loanData, pawnData, installmentData)

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(
      khMoment(dateSelected)?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b")
    );
  }, [dateSelected]);

  return (
    <div className="setup-company-container">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="logo-container">
            <img src={logoCompany} className="logo-company" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" justifyContent="center">
            <Box>
              <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
              <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={wingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Box>
          </Stack>
          <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
            របាយការណ៍អតិថិជនត្រូវបង់ប្រាក់
          </Box>
          <Box className="loan-footer-title" sx={{ marginTop: "10px" }}>
            {`ថ្ងៃ${getKhmerDay(
              moment(dateSelected).utc().format("dddd")
            )} ទី${getKhmerNumber(
              moment(dateSelected).utc().format("DD")
            )} ខែ${getKhmerMonth(
              moment(dateSelected).utc().format("M")
            )} ឆ្នាំ${getKhmerNumber(
              moment(dateSelected).utc().format("YYYY")
            )}`}
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <div className="company-table-container">
        <table className="company-table">
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th></th>
            <th>ឈ្មោះ</th>
            <th>លេខកូដ</th>
            <th>ការប្រាក់</th>
            <th>ប្រាក់ត្រូវបង់</th>
            <th>ប្រាក់សរុប</th>
            <th>ចំ.ត្រូវបង់</th>
            <th>ថ្ងៃត្រូវបង់</th>
            <th>យឺតជាថ្ងៃ</th>
            <th>លេខទូរស័ព្ទ</th>
          </tr>

          {loanData?.length === 0 ? null : (
            <tr style={{ backgroundColor: "#eaeefa" }}>
              <th className="first-col" colSpan={9}>
                កម្ចី
              </th>
              <th></th>
            </tr>
          )}

          {loanData?.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "left" }}>{row?.name}</td>
              <td>{row?.code}</td>
              <td>{row?.interest ? currencyFormat(row?.interest) : ""}</td>
              <td>{row?.payment ? currencyFormat(row?.payment) : ""}</td>
              <td>
                {row?.total_payment ? currencyFormat(row?.total_payment) : ""}
              </td>
              <td>{row?.late_month}</td>
              <td>
                {row?.payment_date
                  ? moment(row?.payment_date).utc().format("DD-MMM-YYYY")
                  : ""}
              </td>
              <td>{row?.late_day}</td>
              <td>{getFormattedPhoneNum(row?.phone)}</td>
            </tr>
          ))}

          {pawnData?.length === 0 ? null : (
            <tr style={{ backgroundColor: "#eaeefa" }}>
              <th className="first-col" colSpan={9}>
                បញ្ចាំ
              </th>
              <th></th>
            </tr>
          )}

          {pawnData?.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "left" }}>{row?.name}</td>
              <td>{row?.code}</td>
              <td>{currencyFormat(row?.interest)}</td>
              <td>{currencyFormat(row?.payment)}</td>
              <td>{currencyFormat(row?.total_payment)}</td>
              <td>{row?.late_month}</td>
              <td>{moment(row?.payment_date).utc().format("DD-MMM-YYYY")}</td>
              <td>{row?.late_day}</td>
              <td>{getFormattedPhoneNum(row?.phone)}</td>
            </tr>
          ))}

          {installmentData?.length === 0 ? null : (
            <tr style={{ backgroundColor: "#eaeefa" }}>
              <th className="first-col" colSpan={9}>
                បង់រំលោះ
              </th>
              <th></th>
            </tr>
          )}

          {installmentData?.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "left" }}>{row?.name}</td>
              <td>{row?.code}</td>
              <td>{currencyFormat(row?.interest)}</td>
              <td>{currencyFormat(row?.payment)}</td>
              <td>{currencyFormat(row?.total_payment)}</td>
              <td>{row?.late_month}</td>
              <td>{moment(row?.payment_date).utc().format("DD-MMM-YYYY")}</td>
              <td>{row?.late_day}</td>
              <td>{getFormattedPhoneNum(row?.phone)}</td>
            </tr>
          ))}
        </table>
      </div>

      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6}>
          <div className="loan-footer-title">បានត្រួតពិនិត្យ និងឯកភាព</div>
          <div className="loan-footer-name">ជ.អ្នកគ្រប់គ្រង</div>
          <div className="loan-footer-name">អ្នកគ្រប់គ្រងសាច់ប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            {/* ខូវ ស៊ូខេង */}
            ........................
          </div>
        </Grid>

        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <div className="loan-footer-title">{khmerDateString}</div>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment(dateSelected).format("DD")
          )} ខែ${getKhmerMonth(
            moment(dateSelected).format("M")
          )} ឆ្នាំ${getKhmerNumber(moment(dateSelected).format("YYYY"))}`}</div>
          <div className="loan-footer-name">អ្នកធ្វើតារាង</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            {/* អុន ដានី */}
            ........................
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
