import { gql } from "@apollo/client";

export const CREATE_EQUITY = gql`
  mutation CreateEquity($input: EquityInput) {
    createEquity(input: $input) {
      isSuccess
      message
    }
  }
`;

export const GET_EQUITY_WITH_PAGINATION = gql`
  query GetEquityWithPagination(
    $page: Int
    $limit: Int
    $keyword: String
    $pagination: Boolean
    $cashInType: String
  ) {
    getEquityWithPagination(
      page: $page
      limit: $limit
      keyword: $keyword
      pagination: $pagination
      cash_in_type: $cashInType
    ) {
      data {
        _id
        cash_in_type
        amount
        date
        description
        source_of_money_id {
          _id
          description
          title
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_EQUITY = gql`
  mutation UpdateEquity($id: ID, $input: EquityInput) {
    updateEquity(_id: $id, input: $input) {
      isSuccess
      message
    }
  }
`;

export const DELETE_EQUITY = gql`
  mutation DeleteEquity($id: ID) {
    deleteEquity(_id: $id) {
      isSuccess
      message
    }
  }
`;
