import React, { useState, useEffect, useContext } from "react";
import "./loanlistdetails.scss";
import {
  Stack,
  Box,
  Button,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EmptyData from "../../Include/EmptyData";
import LoadingPage from "../../Include/LoadingPage";
import { GET_LOAN_BY_ID } from "../../../Schema/Loanlist";
import { AuthContext } from "../../../context/AuthContext";
import CustomerDetail from "../CostomerDetails/CustomerDetail";
import { translateLauguage } from "../../../Function/Translate";
import LoanRequestDetails from "../LoanRequestDetails/LoanRequestDetails";
import LoanApproveDetails from "../LoanApproveDetails/LoanApproveDetails";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";

export default function LoanlistDetails() {
  const navigate = useNavigate();
  //get Storage Room ID by Url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loanListId, setLoanListId] = useState(params.get("id"));

  useEffect(() => {
    setLoanListId(params.get("id"));
  }, [location.search]);

  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState();

  const { data, refetch } = useQuery(GET_LOAN_BY_ID, {
    variables: {
      getLoanByIdId: loanListId,
    },
    onCompleted: ({ getLoanById }) => {
      setTableData(getLoanById);
      setLoading(false);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (tableData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tableData]);

  return (
    <div className="loanlist-details">
      {loading ? (
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              {/* <Box className="slash" /> */}
              <IconButton onClick={() => navigate("/loanlist")}>
                <ReplyAllIcon style={{ color: "#38b14a" }} />
              </IconButton>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Link
                  className="link-under"
                  onClick={() => navigate("/loanlist")}
                >
                  <Typography className="color">Loanlist</Typography>
                </Link>
                <Typography className="color"> / Loan Details </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <Stack direction="column" justifyContent="center" spacing={2}>
                <Button
                  className="btn-print"
                  startIcon={<LocalPrintshopOutlinedIcon />}
                >
                  Print
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" height={800}>
              <LoadingPage />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          {tableData?.status === "Request" ||
          tableData?.status === "FirstApprove" ? (
            <CustomerDetail tableData={tableData} />
          ) : tableData?.status === "Approve" ? (
            <LoanApproveDetails tableData={tableData} setRefetch={refetch} />
          ) : (
            <Stack direction="column" justifyContent="center" height={300}>
              <EmptyData />
            </Stack>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
