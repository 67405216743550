import React from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Chart } from "react-google-charts";
import { AuthContext } from "../../../context/AuthContext";
import { translateLauguage } from "../../../Function/Translate";

export default function CashOutPercentage({ editData }) {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);

  const installment = editData?.total_installments_amount;
  const loan = editData?.total_bussiness_loan_amount;
  const pawn = editData?.total_pawn_amount;

  const installmentPercentage = (installment / editData?.total_amount) * 100;
  const loanPercentage = (loan / editData?.total_amount) * 100;
  const pawnPercentage = (pawn / editData?.total_amount) * 100;

  const data = [
    ["Task", "Hours per Day"],
    [t("installment"), installmentPercentage],
    [t("loan"), loanPercentage],
    [t("pawn"), pawnPercentage],
  ];

  const options = {
    pieHole: 0.4,
    is3D: true,
    fontName: "Siemreap",
    pieStartAngle: 100,
    // backgroundColor: {
    //   fill: "#f9f9f9",
    // },
    sliceVisibilityThreshold: 0.02,
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color: "#233238",
        fontSize: 12,
      },
    },
    pieSliceText: "percentage",
    pieSliceTextStyle: {
      fontSize: 12,
      color: "#233238",
    },
    tooltip: {
      trigger: "focus",
      textStyle: {
        fontSize: 12,
        color: "#233238",
      },
    },

    colors: ["#8AD1C2", "#9F8AD1", "#D18A99", "#BCD18A", "#D1C28A"],
  };

  return (
    <>
      <Box>
        <Stack className="cash-out-by-month-chart">
          <Stack direction="row" justifyContent="center">
            <Typography className="title">ភាគរយបញ្ចេញកម្ចី</Typography>
          </Stack>
          <Box sx={{ width: "360px !important" }}>
            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              //   width={"100%"}
              height={"310px"}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
}
