import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { GET_CASH_OUT_DETAIL_BY_MONTH } from "../../../Schema/Report";
import {
  getKhmerNumber,
  getKhmerMonth,
  currencyFormat,
} from "../../../Function/GetKhmerNumber";
import wingdingImage from "../../../Assets/tacteing-header.PNG";
import logoCompany from "../../../Assets/logo.svg";

import CashOutByMonthChart from "./CashOutByMonthChart";
import CashOutByType from "./CashOutByType";
import CashOutPercentage from "./CashOutPercentage";

export default function CashOutReportByMonth({ dateSelected, componentRef }) {
  const [khmerDateString, setKhmerDateString] = useState("");

  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(GET_CASH_OUT_DETAIL_BY_MONTH, {
    variables: {
      year: moment(dateSelected)?.format("YYYY"),
      month: moment(dateSelected)?.format("MM"),
    },
    onCompleted: ({ getCashOutDetailByMonth }) => {
      setTableData(getCashOutDetailByMonth);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log("tableData", tableData);

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  return (
    <div className="setup-company-container">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Box className="logo-container">
            <img src={logoCompany} className="logo-company" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="center">
            <Box>
              <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
              <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={wingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Box>
          </Stack>
          <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
            របាយការណ៍សាច់ប្រាក់ចេញប្រចាំខែ{" "}
            {getKhmerMonth(moment(dateSelected).format("M"))}
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", justifyContent: "right", marginTop: "100px" }}
        >
          {/* <Stack direction="row" spacing={1}>
            <Typography className="number-title-customerbyday">លេខ៖</Typography>
            <Typography className="number-item-customerbyday">
              ...........................
            </Typography>
          </Stack> */}
        </Grid>
      </Grid>
      <div className="company-table-container">
        <table className="company-table">
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th rowSpan={2} style={{ width: "5%" }}>
              ល.រ
            </th>
            <th rowSpan={2} style={{ width: "25%" }}>
              កាលបរិច្ឆេទ
            </th>
            <th rowSpan={2} style={{ width: "25%" }}>
              បញ្ចេញសរុប
            </th>
            <th colSpan={3}>ប្រភេទបញ្ចេញកម្ចី</th>
          </tr>
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th style={{ width: "15%" }}>បង់រំលស់</th>
            <th style={{ width: "15%" }}>កម្ចី</th>
            <th style={{ width: "15%" }}>បញ្ចាំ</th>
          </tr>

          {tableData?.details_cash_out?.map((row, index) => (
            <tr key={index}>
              <td>{getKhmerNumber(index + 1)}</td>
              <td>{row?.loan_date}</td>
              <td style={{ color: "red" }}>
                {currencyFormat(row?.total_amount)}
              </td>
              <td>
                {currencyFormat(
                  row?.amount_and_type
                    ?.filter((e) => e?.type_of_loan === "Installments")
                    ?.reduce((sum, item) => sum + item.loan_amount, 0)
                )}
              </td>
              <td>
                {currencyFormat(
                  row?.amount_and_type
                    ?.filter((e) => e?.type_of_loan === "Loan")
                    ?.reduce((sum, item) => sum + item.loan_amount, 0)
                )}
              </td>

              <td>
                {currencyFormat(
                  row?.amount_and_type
                    ?.filter((e) => e?.type_of_loan === "Pawn")
                    ?.reduce((sum, item) => sum + item.loan_amount, 0)
                )}
              </td>
            </tr>
          ))}
          <tr>
            <th colSpan={2} style={{ backgroundColor: "#eaeefa" }}>
              សរុប
            </th>
            <th style={{ backgroundColor: "#fff2d0", color: "blue" }}>
              {currencyFormat(tableData?.total_amount)}
            </th>
            <th style={{ backgroundColor: "#fff2d0", color: "blue" }}>
              {currencyFormat(tableData?.total_installments_amount)}
            </th>
            <th style={{ backgroundColor: "#fff2d0", color: "blue" }}>
              {currencyFormat(tableData?.total_bussiness_loan_amount)}
            </th>
            <th style={{ backgroundColor: "#fff2d0", color: "blue" }}>
              {currencyFormat(tableData?.total_pawn_amount)}
            </th>
          </tr>
        </table>
      </div>
      {/* <div style={{marginTop: "30px"}}> */}
      <Grid container sx={{ paddingTop: "50px" }}>
        <Grid item xs={12}>
          <CashOutByMonthChart editData={tableData} />
        </Grid>
        <Grid item xs={6}>
          <CashOutByType editData={tableData} />
        </Grid>
        <Grid item xs={6}>
          <CashOutPercentage editData={tableData} />
        </Grid>
      </Grid>
      {/* </div> */}
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ marginTop: "35px" }}>
          <div className="loan-footer-title">បានឃើញ និងត្រួតពិនិត្យ</div>
          <div className="loan-footer-name">អគ្គនាយក</div>
          <div
            className="loan-footer-name"
            style={{ marginTop: "60px", marginLeft: "55px" }}
          >
            ឡុក ឡងឌី
          </div>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={6} sx={{ marginTop: "55px" }}>
          <div className="loan-footer-title">{khmerDateString}</div>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>

          <Stack direction="row" spacing={6} sx={{ marginLeft: "75px" }}>
            <Box>
              {/* <div className="loan-footer-name">អ្នកអនុម័ត</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div> */}
            </Box>
            <Box>
              <div className="loan-footer-name">អ្នករៀបចំ</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
