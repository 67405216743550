import React, { useState, useEffect } from "react";
import "./users.scss";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Pagination,
  TableHead,
} from "@mui/material";
import { Link } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import AddUsers from "../Component/Users/AddUsers/AddUsers";
import UsersAction from "../Component/Users/UsersAction/UsersAction";
import { GET_USER_WITH_PAGINATION } from "../Schema/Users";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../context/AuthContext";
import { translateLauguage } from "../Function/Translate";

export default function Users() {
  // Change Language
  const { language } = React.useContext(AuthContext);
  const { t } = translateLauguage(language);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);

  const { data, refetch } = useQuery(GET_USER_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: "",
      pagination: false,
    },
    onCompleted: ({ getUserByPagination }) => {
      setTableData(getUserByPagination);
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="users-page">
      <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
        <Box className="slash" />
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="page-link-title" to="/setting">
              <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
                {t(`setting`)}
              </Typography>
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="slash-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className={language === "kh" ? "titleKh" : "titleEn"}>
              {t("users")}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" justifyContent="center">
          <Stack direction="column" justifyContent="center" spacing={2}>
            <Button
              onClick={handleOpen}
              className={language === "kh" ? "btn-prinKh" : "btn-prinEn"}
              startIcon={<AddIcon className="icon-add" />}
            >
              {t("add")}
            </Button>
          </Stack>

          <AddUsers
            open={open}
            handleClose={handleClose}
            Title={t("create")}
            t={t}
            language={language}
            setRefetch={refetch}
          />
        </Stack>
      </Stack>

      <Box className="container">
        <TableContainer sx={{ maxWidth: `${width}px`, whiteSpace: "nowrap" }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="table-row">
              <TableCell
                className={
                  language === "kh"
                    ? "header-title-start-kh"
                    : "header-title-start-en"
                }
              >
                {t("no")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-title-kh" : "header-title-en"
                }
              >
                {t("username")}{" "}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-title-kh" : "header-title-en"
                }
              >
                {t("email")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-title-kh" : "header-title-en"
                }
              >
                {t("role")}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-title-end" : "header-title-end"
                }
              >
                {/* {t("phone")} */}
              </TableCell>
              <TableCell
                className={
                  language === "kh" ? "header-title-end" : "header-title-end"
                }
              >
                {/* {t("phone")} */}
              </TableCell>
            </TableHead>
            <TableBody className="body">
              {tableData?.data?.map((row, index) => (
                <TableRow className="body-row" key={index}>
                  <TableCell
                    className={
                      language === "kh"
                        ? "body-cell-startKh"
                        : "body-cell-startEn"
                    }
                  >
                    {index + tableData?.paginator?.slNo}-
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "body-cellKh" : "body-cellEn"
                    }
                  >
                    <Stack direction="row" spacing={2}>
                      <Avatar
                        sx={{ width: 40, height: 40 }}
                        alt={row?.firsName + " " + row?.lastName}
                        src={`${row?.image_src}`}
                      />
                      <Stack
                        direction="column"
                        justifyContent="center"
                        spacing={2}
                      >
                        <Typography
                          className={
                            language === "kh" ? "body-cellKh" : "body-cellEn"
                          }
                        >
                          {row?.firsName + " " + row?.lastName}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "body-cellKh" : "body-cellEn"
                    }
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "body-cellKh" : "body-cellEn"
                    }
                  >
                    {row?.role}
                  </TableCell>
                  <TableCell
                    className={
                      language === "kh" ? "body-cellKh" : "body-cellEn"
                    }
                  >
                    {row?.phone_number}
                  </TableCell>
                  <TableCell className="body-cell-end" align="right">
                    <UsersAction editRow={row} setRefetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" justifyContent="right" sx={{ mt: 2 }}>
          <Pagination
            hideNextButton={false}
            hidePrevButton={false}
            count={tableData?.paginator?.totalPages}
            color="primary"
            variant="outlined"
            onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
          />
        </Stack>
      </Box>
    </div>
  );
}
